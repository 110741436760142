export class AnswerDefinitionBase {

}

export class QuestionDefinitionBase<A extends AnswerDefinitionBase> {
    instructions: string;
    answer: A;
}

export class Question<A extends AnswerDefinitionBase, Q extends QuestionDefinitionBase<A>> {
    id: number;
    path: string;
    orderNumber: number;
    lastUpdate: number;
    definition: Q;
}

export class PlaceholderAnswer {
    name: string;
    val: string[];
    alt: string[];
}

export class CorrectionAnswer extends AnswerDefinitionBase {
    '@type' = 'cor';
    correct: string;
}

export class DictationAnswer extends AnswerDefinitionBase {
    '@type' = 'dict';
    answer: string;
    passScore: number;
}

export class FillMultiAnswer extends AnswerDefinitionBase {
    '@type' = 'ef';
    answer: PlaceholderAnswer[];
}

export class ImagesAnswer extends AnswerDefinitionBase {
    '@type' = 'pct';
    answers: PlaceholderAnswer[];
}

export class JumbleAnswer extends AnswerDefinitionBase {
    '@type' = 'jmb';
    answer: PlaceholderAnswer[];
}

export class QaAnswer extends AnswerDefinitionBase {
    '@type' = 'qa';
}

export class Correction extends QuestionDefinitionBase<CorrectionAnswer> {
    '@type' = 'cor';
    incorrect: string;
    img: string;
}

export class Dictation extends QuestionDefinitionBase<DictationAnswer> {
    '@type' = 'dict';
    src: string;
    passScore: number;
}

export class FillMulti extends QuestionDefinitionBase<FillMultiAnswer> {
    '@type' = 'ef';
    question: string;
    img: string;
}

export class Images extends QuestionDefinitionBase<ImagesAnswer> {
    '@type' = 'pct';
    words: string[];
    images: string[];
}

export class Jumble extends QuestionDefinitionBase<JumbleAnswer> {
    '@type' = 'jmb';
    question: string;
    answers: string[];
    img: string;
}

export class Qa extends QuestionDefinitionBase<QaAnswer> {
    '@type' = 'qa';
    question: string;
    questionAnswer: string;
    img: string;
    src: string;
    female: boolean;
    prompt: string[];
}


