import { MobileNativeApi } from './MobileNativeApi';
import { CspaRestService, CspaRestServiceNew } from '../../services/rest/cspa-rest.service';
import {Observable, of, forkJoin, EMPTY} from 'rxjs';
import { ExerciseSet, Chapter } from 'src/app/model/struct';
import { Question, AnswerDefinitionBase } from 'src/app/model/questions';
import { ExerciseSession, ItemAvailability, ExerciseSessionQuestion } from 'src/app/model/personal';
import { tap, map, flatMap, switchMap } from 'rxjs/operators';
import { CasaExerciseSession, CasaQuestionScoreSubmit } from 'src/app/model/oldModel';
import { OldCasaQuestionsScoringService } from './OldCspaQuestionScoring';
import { LocalStorageService } from 'ngx-store';
import { CasaToCspaSessionModelMapper } from './CasaToCspaSessionModelMapper';
import { environment } from 'src/environments/environment';

export class MobileToNewApiBridge implements CspaRestService {

    scoring = new OldCasaQuestionsScoringService();
    sessionMapper = new CasaToCspaSessionModelMapper();
    cached: ExerciseSession = null;
    CURRENT_SESSION_ID_KEY = 'current_session_id';
    CURRENT_SESSION_UUID_KEY = 'current_session_uuid';

    constructor(private mobileNativeApi: MobileNativeApi,
                private localStorage: LocalStorageService,
                private alternative: CspaRestService) {}

    isNativeImplementation(): Boolean {
      return true;
    }

    /** not used in mobile context */
    listExerciseSets(): Observable<ExerciseSet[]> {
        if (this.alternative) {
            return this.alternative.listExerciseSets();
        }
        throw new Error('Mehod not supported');
    }
    /** not used in mobile context */
    listChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
        if (this.alternative) {
            return this.alternative.listChapters(path, updatedAfter);
        }
        throw new Error('Method not supported');
    }
    /** not used in mobile context */
    listQuestions(pathPreffixes: string[], updatedAfter?: number): Observable<Question<any, any>> {
        if (this.alternative) {
            return this.alternative.listQuestions(pathPreffixes, updatedAfter);
        }
        throw new Error('Method not supported');
    }
    /** not used in mobile context */
    startExerciseSession(exercisePath: string): Observable<ExerciseSession> {
        throw new Error('Method not supported');
    }
    /** not used in mobile context */
    public listAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
        if (this.alternative) {
            return this.alternative.listAvailabilities(path, depth);
        }
        throw new Error('Method not supported');
    }
    /** not used in mobile context */
    public submitSessions(sessions: ExerciseSession[]): Observable<void> {
        throw new Error('Method not supported');
    }

    // implementation
    startExerciseSessionById(exerciseId: number): Observable<ExerciseSession> {
        return this.mobileNativeApi.createSession(exerciseId)
            .pipe(
                map( mobileSession => this.mapMobileSession(mobileSession)),
                flatMap( cspaSession => this.fillSessionNames(cspaSession)),
                tap( cspaSession => this.storeInCache(cspaSession))
            );
    }

    // implementation
    getExerciseSession(uuid: string): Observable<ExerciseSession> {
        // check if session uuid to id persisted mapping exists
        // only the last session id mapping exists - in other cases exception excepted
        const sessionId: number = this.findSessionId(uuid);
        return this.findSessionById(sessionId);
    }

    findSessionById(sessionId: number): Observable<ExerciseSession> {
        // try to get session from cache - ensure about the id
        const currentSession: ExerciseSession = this.getChachedSession();
        if (currentSession && currentSession.id === sessionId) {
            return of(currentSession);
        }
        // try to load the session from mobile and store in cache
        return this.mobileNativeApi.getSessionData(sessionId)
            .pipe(
                map ( mobileSession => this.mapMobileSession(mobileSession)),
                flatMap( cspaSession => this.fillSessionNames(cspaSession)),
                tap ( cspaSession => this.storeInCache(cspaSession))
            );
    }

    fillSessionNames(cspaSession: ExerciseSession): Observable<ExerciseSession> {
        return this.mobileNativeApi.getSessionQuestionDefinition(cspaSession.id, cspaSession.questions[0].question.id).pipe(
            map(
                qDef => {
                    cspaSession.chapterName = qDef.chapterName;
                    cspaSession.exerciseName = qDef.exerciseName;
                    cspaSession.sectionName = 'unknown section';
                    return cspaSession;
                }
            )
        );
    }

    // implementation
    recreateExerciseSession(uuid: string): Observable<ExerciseSession> {
        // search for the session
        return this.getExerciseSession(uuid).pipe(
            // finish up questions not finished
            flatMap( session => this.finishUpSession(session)),
            // create new session basing on the old
            flatMap( session => this.mobileNativeApi.recreateSession(session.id)),
            // do same mapping as it was on the create session - store id and the instance
            map ( mobileSession => this.mapMobileSession(mobileSession)),
            flatMap( cspaSession => this.fillSessionNames(cspaSession)),
            tap ( cspaSession => this.storeInCache(cspaSession))
        );
    }

    // implementation
    public finishSession(uuid: string): Observable<ExerciseSession> {
        return this.getExerciseSession(uuid).pipe(
            flatMap( session => this.finishUpSession(session))
        );
    }

    public postSessionQuestionAnswer<A extends AnswerDefinitionBase>(
        uuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>)
    : Observable<ExerciseSession> {
        // assign the score to the received session question
        sessionQuestion.score = this.scoring.score(sessionQuestion).score;
        sessionQuestion.answered = true;
        // get current session with consistency check
        return this.getExerciseSession(uuid).pipe(
            // append the sessionQuestion with the scoring
            tap( session => session.questions[questionNb] = sessionQuestion ),
            tap( session => {
                session.score = session.questions
                    .filter( q => q.score != null)
                    .map( q => q.score)
                    .reduce( (sum, current) => sum + current, 0.0) / session.questions.length;
            }),
            // chain score submit with mapping back to the session (expected as a method result)
            switchMap(
                session => this.mobileNativeApi
                .submitSessionScore(session.id,
                        sessionQuestion.question.id,
                        new CasaQuestionScoreSubmit(sessionQuestion.score, null, null)).pipe(
                    map( _ => session)
                )
            )
        );
    }

    // go for each question and finish with the score 0
    finishUpSession(session: ExerciseSession): Observable<ExerciseSession> {
        // collect all required submits
        const submitTasks = session.questions.filter( q => !q.answer || !q.answered || q.score === 0.0)
        .map( q => {
            q.score = 0.0;
            q.answered = true;
            return this.mobileNativeApi.submitSessionScore(session.id, q.question.id, new CasaQuestionScoreSubmit(q.score, null, null));
        });

        if (submitTasks.length === 0) {
            return of(session);
        }

        // return the existing session after submit
        return forkJoin(submitTasks).pipe(
            map( _ => session)
        );
    }

    getChachedSession(): ExerciseSession {
        return this.cached;
    }

    findSessionId(uuid: string): number {
        const currentSessionId = this.localStorage.get(this.CURRENT_SESSION_ID_KEY) as number;
        const currentSessionUuid = this.localStorage.get(this.CURRENT_SESSION_UUID_KEY);

        if (currentSessionId && currentSessionUuid && currentSessionUuid === uuid) {
            return currentSessionId;
        }
        throw new Error(`the session uuid = ${uuid} wasn't the last session identifier`);
    }

    mapMobileSession(session: CasaExerciseSession): ExerciseSession {
        return this.sessionMapper.map(session);
    }

    storeInCache(cspaSession: ExerciseSession): void {
        this.localStorage.set(this.CURRENT_SESSION_ID_KEY, cspaSession.id);
        this.localStorage.set(this.CURRENT_SESSION_UUID_KEY, cspaSession.deviceUUID);
        this.cached = cspaSession;
    }

    log(text: string) {
        if (environment.debug) {
          console.log(text);
        }
    }

    questionSessionFinished() {
        this.log('sending close questions event to the native api');
        this.mobileNativeApi.closeQuestions().subscribe();
    }

    closeSession(sessionUuid: string): Observable<void> {
      return EMPTY;
    }

}
