<div class="d-none d-md-block w-100" style="height: 100px;"></div>
<div *ngIf="!hasImage()" class="d-md-none w-100" style="height: 100px;"></div>
<div class="row" >
    <div *ngIf="hasImage()" class="col-12 col-md-4">
        <div class="card question-image p-2 mb-2">
            <img [src]="getImageSrc()" class="card-img-top">
        </div>
    </div>
    <div class="col">
        <div class="d-none d-md-block w-100" style="height: 50px;"></div>
        <div class="text-center display-5">
            <span *ngFor="let token of tokens">
                <span *ngIf="!token.editable" [innerHtml]="token.content"></span>
                <span *ngIf="token.editable" style="position: relative;">
                    <span class="help" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
                    unselectable="on"
                    onselectstart="return false;"
                    onmousedown="return false;" *ngIf="token.editable && token.help" >{{token.help}}</span>
                    <input [ngStyle]="getInputStyle(token)" class="token-input" [(ngModel)]="token.content" #inputToken>
                </span>
            </span>
        </div>
    </div>
</div>
