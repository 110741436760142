import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {Params} from "@angular/router";
import {LoggerService} from "./logger.service";

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
  static supportedLangs = [
    'en',
    'pl' , //Polish
    'br_pt', //Brazilian Portuguese
    'ca', //Catalan
    'de', //German
    'es', //Spanish
    'hr', //Croatian
    'it', //Italian
    'jp', //Japanese
    'ru', //Russian
  ];

  constructor(
    private translate: TranslateService,
    private logger: LoggerService) {
        translate.setDefaultLang('en');
        translate.use(this.determineBrowserLanguage());
    }

  private determineBrowserLanguage(): string {
      let browserLang = this.translate.getBrowserLang();
      console.log(browserLang);
      // treat portuguese exceptionally - use brazilian dialect
      if (browserLang === 'pt') browserLang = 'br_pt';
      if (browserLang === 'ja') browserLang = 'jp';
      // for non supported languages use english
      if (TranslationService.supportedLangs.indexOf(browserLang) < 0) browserLang = 'en';
      // prefix with dash according to file names
      return browserLang;
  }

    switchLang(lang: string) {
        this.translate.use(lang);
    }
    get(key: string, params?: any): Observable<any> {
        return this.translate.get(key, params)
    }

    extractLang(params: Params) {
        let lang = params['lang'];
        if (!lang) return;
        lang = `_${lang.toLocaleLowerCase()}`;
        if (TranslationService.supportedLangs.indexOf(lang) >=0) {
          this.logger.log(`switching lang to ${lang}`);
          this.translate.use(lang);
        }
    }
}
