import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Exercise } from 'src/app/model/struct';
import { ItemAvailability } from 'src/app/model/personal';

@Component({
  selector: 'app-exercise-box',
  templateUrl: './exercise-box.component.html',
  styleUrls: ['./exercise-box.component.scss']
})
export class ExerciseBoxComponent implements OnInit {

  @Input()
  exercise: Exercise;
  @Input()
  availability: ItemAvailability;
  @Output()
  exerciseClick  = new EventEmitter<Exercise>();

  constructor() { }

  isReady() {
    return this.exercise != null;
  }

  getName() {
    return this.exercise.shortName;
  }

  getStatusClass() {
    if (!this.availability) {
      return 'locked';
    }
    if (!this.availability.assigned || !this.availability.available) {
      return 'locked';
    }
    if (this.availability.score === 0) {
      return 'ready';
    } else if (this.availability.score < 0.5) {
      return 'bad';
    } else if (this.availability.score < 0.95) {
      return 'enough';
    } else {
      return 'perfect';
    }
  }

  public onClick() {
    this.exerciseClick.emit(this.exercise);
  }

  ngOnInit() {
  }

}
