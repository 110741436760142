import {Injectable, Injector, NgZone} from '@angular/core';
import { CspaRestService, CspaRestServiceNew } from './rest/cspa-rest.service';
import { MobileNativeMockService } from '../mobile/services/MobileNativeMockService';
import { MobileToNewApiBridge } from '../mobile/services/MobileToNewApiBridge';
import { LocalStorageService } from 'ngx-store';
import { CasaStageBasedMobileApi } from '../mobile/services/CasaStageBasedMobileNativeApiImpl';
import { environment } from 'src/environments/environment';
import { SupportedPlatforms } from 'src/environments/platforms';
import { MobileNativeApi, FullMobileNativeApi,
    MobileNativeAudioRecorderAdapter } from '../mobile/services/MobileNativeApi';
import { AndroidNativeApi } from '../mobile/services/android/AndroidNativeApi';
import { IosNativeApi } from '../mobile/services/ios/IosNativeApi';
import { Observable, of, empty, from } from 'rxjs';
import { Recorder, EmptyRecorder } from './Recorder';
import {MobileBridgeServices, NewMobileBridgeService} from "../mobile/services/new-mobile-bridge.service";
import {WebMobileKindEmulatedService} from "../mobile/services/web-mobile-kind-emulated.service";
import {IosNewNativeApi} from "../mobile/services/ios/ios-new-native-api";
import {LoggerService} from "./logger.service";
import {AndroidRequestBasedNativeApi} from "../mobile/services/android/AndroidRequestBasedNativeApi";

@Injectable({
    providedIn: 'root'
})
export class ApiServiceProvider {
    private androidNative: FullMobileNativeApi;
    private iOSNative: FullMobileNativeApi;
    private casaNative: MobileNativeApi;
    private mockNative: MobileNativeMockService;
    private androidNativeBridge: MobileToNewApiBridge;
    private iOSNativeBridge: MobileToNewApiBridge;
    private mockNativeBridge: MobileToNewApiBridge;
    private casaBasedBridge: MobileToNewApiBridge;
    private regularCspaRest: CspaRestServiceNew;
    private androidNativeAudio: MobileNativeAudioRecorderAdapter;
    private iOsNativeAudio: MobileNativeAudioRecorderAdapter;
    private webMobileEmulated: NewMobileBridgeService;
    private iosNativeBridge: NewMobileBridgeService;
    private iosNewNativeApi: IosNewNativeApi;
    private iosNewNativeAudio: MobileNativeAudioRecorderAdapter;
    private androidNewNativeBridge : NewMobileBridgeService;
    private androidNewNativeApi: AndroidRequestBasedNativeApi;
  private androidNewNativeAudio: MobileNativeAudioRecorderAdapter;

    constructor(private localStorage: LocalStorageService,
                private injector: Injector, private logger: LoggerService) {}

    public getAlternativeAudioApi(): Observable<Recorder> {

        if (environment.platform === SupportedPlatforms.androidLegacy) {
            return this.getAndroidNativeAudio();
        } else if (environment.platform === SupportedPlatforms.iOsLegacy) {
            return this.getIosNativeAudio();
        } else if (environment.platform === SupportedPlatforms.ios) {
            return this.getIosNewNativeAudio();
        } else if (environment.platform === SupportedPlatforms.android) {
          return this.getAndroidNewNativeAudio();
        }
        return empty();
    }

    log(text: any) {
        if (environment.debug) {
          console.log(text);
        }
    }

    getIosNativeAudio(): Observable<Recorder> {
        if (this.iOsNativeAudio) {
            this.log('api provider - returning cached ios recorder instance');
            return of(this.iOsNativeAudio);
        }

        return from(new Promise<Recorder>( (resolve, reject) => {
            this.getIosNative().initAudio(state => {
                this.log('api provider - got ios audio with state:');
                this.log(state);
                if (state) {
                    this.log('api provider - returning ios native audio recorder to the observable');
                    this.iOsNativeAudio = new MobileNativeAudioRecorderAdapter(this.getIosNative());
                    resolve(this.iOsNativeAudio);
                } else {
                    this.log('api provider - didn\'t found the ios audio recorder, closing obervable with empty result');
                    resolve(new EmptyRecorder());
                }
            });
        }));
    }

  private getIosNewNativeAudio(): Observable<Recorder> {
    if (this.iosNewNativeAudio) {
      return of(this.iosNewNativeAudio);
    }

    return new Observable<Recorder>(observer => {
      this.getIosNewNativeApi().initAudio( state => {
        if (state) {
          this.log('api provider - returning ios new audio recorder');
          this.iosNewNativeAudio = new MobileNativeAudioRecorderAdapter(this.getIosNewNativeApi());
          observer.next(this.iosNewNativeAudio);
          observer.complete();
        } else {
          this.log('api provider - didn\'t found the ios audio recorder, closing obervable with empty result');
          observer.next(new EmptyRecorder());
          observer.complete();
        }
      });
    });
  }

  private getNewAndroidBridge() {
    if (!this.androidNewNativeBridge) {
      this.androidNewNativeBridge = new NewMobileBridgeService(this.getAndroidNewNativeApi(), this.logger);
    }
    return this.androidNewNativeBridge;
  }

  private getAndroidNewNativeApi() {
    if (!this.androidNewNativeApi) {
      this.androidNewNativeApi = this.injector.get<AndroidRequestBasedNativeApi>(AndroidRequestBasedNativeApi);
    }
    return this.androidNewNativeApi;
  }

  private getAndroidNewNativeAudio(): Observable<Recorder> {
    if (this.androidNewNativeAudio) {
      return of(this.androidNewNativeAudio);
    }

    return new Observable<Recorder>(observer => {
      this.getAndroidNewNativeApi().initAudio( state => {
        if (state) {
          this.log('api provider - returning ios new audio recorder');
          this.androidNewNativeAudio = new MobileNativeAudioRecorderAdapter(this.getAndroidNewNativeApi());
          observer.next(this.androidNewNativeAudio);
          observer.complete();
        } else {
          this.log('api provider - didn\'t found the ios audio recorder, closing obervable with empty result');
          observer.next(new EmptyRecorder());
          observer.complete();
        }
      });
    });
  }

    getAndroidNativeAudio(): Observable<Recorder> {
        if (this.androidNativeAudio) {
            return of(this.androidNativeAudio);
        }

        return new Observable<Recorder>(observer => {
            this.getAndroidNative().initAudio(state => {
                if (state) {
                    this.androidNativeAudio = new MobileNativeAudioRecorderAdapter(this.getAndroidNative());
                    observer.next(this.androidNativeAudio);
                    observer.complete();
                } else {
                    observer.complete();
                }
            });
            return {unsubscribe: () => {}};
        });
    }

    public getRestServiceImplementation(): CspaRestService {
        if (environment.platform === SupportedPlatforms.androidLegacy) {
            return this.getAndroidNativeBridge();
        } else if (environment.platform === SupportedPlatforms.iOsLegacy) {
            return this.getIosNativeBridge();
        } else if (environment.platform === SupportedPlatforms.mock) {
            return this.getMockNativeBridge();
        } else if (environment.platform === SupportedPlatforms.web) {
            return this.getRegularCspaRes();
        } else if (environment.platform === SupportedPlatforms.webCasa) {
            return this.getCasaBasedBridge();
        } else if (environment.platform === SupportedPlatforms.webMobileEmulated) {
            return this.getWebMobileEmulatedBridge();
        } else if (environment.platform === SupportedPlatforms.ios) {
          return this.getNewIosBridge();
        } else if (environment.platform === SupportedPlatforms.android) {
          return this.getNewAndroidBridge();
        }
        throw new Error(`unsupported platform ${environment.platform}`);
    }

    private getRegularCspaRes() {
        if (!this.regularCspaRest) {
            this.regularCspaRest = this.injector.get<CspaRestServiceNew>(CspaRestServiceNew);
        }
        return this.regularCspaRest;
    }

    private getCasaNative() {
        if (!this.casaNative) {
            this.casaNative = this.injector.get<CasaStageBasedMobileApi>(CasaStageBasedMobileApi);
        }
        return this.casaNative;
    }

    private getAndroidNative() {
        if (!this.androidNative) {
            this.androidNative = this.injector.get<AndroidNativeApi>(AndroidNativeApi);
        }
        return this.androidNative;
    }

    private getIosNative() {
        if (!this.iOSNative) {
            this.iOSNative = this.injector.get<IosNativeApi>(IosNativeApi);
        }
        return this.iOSNative;
    }

    private getMockNative() {
        if (!this.mockNative) {
            this.mockNative = this.injector.get<MobileNativeMockService>(MobileNativeMockService);
        }
        return this.mockNative;
    }

    private getAndroidNativeBridge() {
        if (!this.androidNativeBridge) {
            this.androidNativeBridge = new MobileToNewApiBridge(this.getAndroidNative(), this.localStorage, null);
        }
        return this.androidNativeBridge;
    }

    private getIosNativeBridge() {
        if (!this.iOSNativeBridge) {
            this.iOSNativeBridge = new MobileToNewApiBridge(this.getIosNative(), this.localStorage, null);
        }
        return this.iOSNativeBridge;
    }

    private getMockNativeBridge() {
        if (!this.mockNativeBridge) {
            this.mockNativeBridge = new MobileToNewApiBridge(this.getMockNative(), this.localStorage, this.getRegularCspaRes());
        }
        return this.mockNativeBridge;
    }

    private getCasaBasedBridge() {
        if (!this.casaBasedBridge) {
            this.casaBasedBridge = new MobileToNewApiBridge(this.getCasaNative(), this.localStorage, this.getRegularCspaRes());
        }
        return this.casaBasedBridge;
    }

  private getWebMobileEmulatedBridge() {
    if (this.webMobileEmulated) return this.webMobileEmulated;
    this.webMobileEmulated = new NewMobileBridgeService(new WebMobileKindEmulatedService(this.getRegularCspaRes(), this.logger), this.logger)
    return this.webMobileEmulated;
  }

  getMobileRestServiceImplementation(): MobileBridgeServices {
     return this.getRestServiceImplementation() as unknown as MobileBridgeServices;
  }


  private getNewIosBridge() {
    if (!this.iosNativeBridge) {
      this.iosNativeBridge = new NewMobileBridgeService(this.getIosNewNativeApi(), this.logger);
    }
    return this.iosNativeBridge;
  }

  private getIosNewNativeApi() {
    if (!this.iosNewNativeApi) {
      this.iosNewNativeApi = this.injector.get<IosNewNativeApi>(IosNewNativeApi);
    }
    return this.iosNewNativeApi;
  }


}
