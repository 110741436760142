import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-store';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {  flatMap } from 'rxjs/operators';
import { Router, RouterModule } from '@angular/router';

/**
 * Auth response class
 */
class AuthResponse {
  // tslint:disable-next-line:variable-name
  access_token: string;
  // tslint:disable-next-line:variable-name
  expiries_in: number;
  // tslint:disable-next-line:variable-name
  refresh_token: string;
  // tslint:disable-next-line:variable-name
  token_type: string;
}

/**
 * Token data but without token content - for
 * security reason if app wants to know about authorization details
 * but is not engouth trusted to show the token content
 */
export class AuthorizationData {
  email: string;
  accountId: number;
  personId: number;
  expiryDate: number;
}

/**
 * extendend token data - containing the token content
 */
export class AuthorizationTokenData extends AuthorizationData {
  token: string;
}

/**
 * token store container interface for persiting the data
 */
export interface AuthStore {
  readCurrentAuthorizationData(): Observable<AuthorizationTokenData>;
  storeAuthorizationToken(token: string): Observable<AuthorizationTokenData>;
  clear();
}

/**
 * authorization service interface
 */
export interface Auth {
  /**
   * allow to read auth data i.e. to display them in the app
   */
  readCurrentAuthData(): Observable<AuthorizationTokenData>;
  /**
   * start the login. Exit the app and return with given state after the auth.
   * @param returnState return state
   */
  login(returnState: string): void;
  /**
   * Authorize with the authorization code. It may be used by login authorization to finish
   * it with the code returned by the auth server or just during the redirect - for other
   * application intgration (like by iframe to avoid double authorization)
   * @param authCode authorization code
   * @param state return state
   */
  loginWithAuthCode(authCode: string, state: string): void;

  loginWithPlainToken(token: string, state: string);
}


/**
 * token store using local storage
 */
@Injectable({
  providedIn: 'root'
})
export class AuthStoreImpl implements AuthStore {

  private static tokenEntryLocation = 'cspaAuthTokenData';
  constructor(private localStorage: LocalStorageService) {
  }
  readCurrentAuthorizationData(): Observable<AuthorizationTokenData> {
    return of(this.localStorage.get(AuthStoreImpl.tokenEntryLocation) as AuthorizationTokenData);
  }

  clear() {
    this.localStorage.remove(AuthStoreImpl.tokenEntryLocation);
  }

  storeAuthorizationToken(token: string): Observable<AuthorizationTokenData> {
    this.localStorage.remove(AuthStoreImpl.tokenEntryLocation);
    const tokenData = this.parseToken(token);
    this.localStorage.set(AuthStoreImpl.tokenEntryLocation, tokenData);
    return of(tokenData);
  }

  parseToken(token: string): AuthorizationTokenData {
    const splitted = token.split('.');
    const data = JSON.parse(atob(splitted[1]));
    const tokenData = new AuthorizationTokenData();
    tokenData.accountId = data.userEmail;
    tokenData.personId = data.personId;
    tokenData.accountId = data.userId;
    tokenData.expiryDate = new Date(data.exp * 1000).getTime();
    tokenData.token = token;
    return tokenData;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements Auth {

  constructor(private authStore: AuthStoreImpl,
              private http: HttpClient,
              private router: Router) { }

  readCurrentAuthData(): Observable<AuthorizationTokenData> {
    return this.authStore.readCurrentAuthorizationData();
  }

  logout() {
    this.authStore.clear();
    this.router.navigate(['/']);
  }

  login(returnState: string): void {
    const oauth = environment.authEndpoint
      + '/oauth/school/1/login?' + this.constructOauth(returnState);
    window.location.href = oauth;
  }

  loginWithAuthCode(authCode: string, state: string): void {
    this.askForAccessToken(authCode)
    .pipe(
      flatMap(tokenResponse => this.authStore.storeAuthorizationToken(tokenResponse.access_token))
    ).subscribe( _ => this.openState(state));
  }

  loginWithPlainToken(token: string, state: string) {
    this.authStore.clear();
    this.authStore.storeAuthorizationToken(token)
    .subscribe( _ => this.openState(state));
  }

  openState(state: string): void {
    this.router.navigateByUrl(state);
  }

  askForAccessToken(code: string): Observable<AuthResponse> {
    const codeRequest = {
      code,
      client_id: environment.authClientId,
      redirect_uri: environment.serverBase + '/oauth'
      };

    return this.http.post<AuthResponse>(environment.authEndpoint + '/oauth/token', codeRequest);
  }

  private constructOauth(stateUrl: string) {
    const stateStr = encodeURIComponent(btoa(stateUrl));
    const clientId = environment.authClientId;
    const redirectUrl = encodeURIComponent(environment.serverBase + '/oauth');

    const queryPart = 'response_type=code&chooseProfile=0&client_id='
    + clientId
    + '&state=' + stateStr
    + '&redirect_uri=' + redirectUrl;
    return queryPart;
  }

}
