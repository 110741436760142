import { FullMobileNativeApi } from '../MobileNativeApi';
import { Observable, Subscription, Subscriber, from } from 'rxjs';
import { CasaExerciseSession, CasaSessionQuestionDefinition, CasaQuestionScoreSubmit } from 'src/app/model/oldModel';
import { Injectable } from '@angular/core';

declare const mobileMediaRecorder: any;
declare const mobileCspaBridge: any;

@Injectable({
    providedIn: 'root'
})
export class AndroidNativeApi implements FullMobileNativeApi {

    initAudio(callback: (state: boolean) => void) {
        if (mobileMediaRecorder) {
            try {
                callback(mobileMediaRecorder.init());
            } catch (e) {
                callback(false);
            }
        }
    }

    clear() {
        return from(new Promise<void>( (resolve, reject) => {
            mobileMediaRecorder.clear();
            resolve(null);
        }));
    }

    record() {
        mobileMediaRecorder.record();
    }
    stop() {
        return from(new Promise<void>( (resolve, reject) => {
            mobileMediaRecorder.stop();
            resolve(null);
        }));
    }
    play2() {
        mobileMediaRecorder.play();
    }
    terminate() {
        mobileMediaRecorder.stop();
    }

    verifyExistance(observer: Subscriber<FullMobileNativeApi>): boolean {
        if (mobileCspaBridge) {
            observer.next(mobileCspaBridge);
            observer.complete();
            return true;
        }
        return false;
    }

    createSession(exerciseId: number): Observable<CasaExerciseSession> {
        return new Observable<CasaExerciseSession>( observer => {
            observer.next(JSON.parse(mobileCspaBridge.createSession(JSON.stringify({exerciseId}))));
            observer.complete();
            return {
                unsubscribe: () => {}
            };
        });
    }

    recreateSession(sessionId: number): Observable<CasaExerciseSession> {
        return new Observable<CasaExerciseSession>( observer => {
            observer.next(JSON.parse(mobileCspaBridge.recreateSession(JSON.stringify({sessionId}))));
            observer.complete();
            return {
                unsubscribe: () => {}
            };
        });
    }

    getSessionData(sessionId: number): Observable<CasaExerciseSession> {
        return new Observable<CasaExerciseSession>( observer => {
            observer.next(JSON.parse(mobileCspaBridge.getSessionData(JSON.stringify({sessionId}))));
            observer.complete();
            return {
                unsubscribe: () => {}
            };
        });
    }

    getSessionQuestionDefinition(sessionId: number, questionId: number): Observable<CasaSessionQuestionDefinition> {
        return new Observable<CasaSessionQuestionDefinition>( observer => {
            observer.next(JSON.parse(mobileCspaBridge.getSessionQuestionDefinition(JSON.stringify({sessionId, questionId}))));
            observer.complete();
            return {
                unsubscribe: () => {}
            };
        });
    }

    submitSessionScore(sessionId: number, questionId: number, submit: CasaQuestionScoreSubmit): Observable<boolean> {
        return new Observable<boolean>( observer => {
            observer.next(
                JSON.parse(mobileCspaBridge.submitSessionScore(
                    JSON.stringify({sessionId, questionId}), JSON.stringify(submit))));
            observer.complete();
            return {
                unsubscribe: () => {}
            };
        });
    }

    recalculateScore(): Observable<void> {
        return new Observable<void>( observer => {
            observer.next(mobileCspaBridge.recalculateScore());
            observer.complete();
            return {
                unsubscribe: () => {}
            };
        });
    }

    closeQuestions(): Observable<boolean> {
        return new Observable<boolean>( observer => {
            mobileCspaBridge.closeSession();
            observer.next(true);
            observer.complete();
            return {
                unsubscribe: () => {}
            };
        });
    }
}
