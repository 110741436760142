/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jumble.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../directives/kids-rotation.directive";
import * as i3 from "../../../services/theme.service";
import * as i4 from "@angular/common";
import * as i5 from "./jumble.component";
var styles_JumbleComponent = [i0.styles];
var RenderType_JumbleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JumbleComponent, data: {} });
export { RenderType_JumbleComponent as RenderType_JumbleComponent };
function View_JumbleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "d-md-none w-100"], ["style", "height: 100px;"]], null, null, null, null, null))], null, null); }
function View_JumbleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-12 col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["appKidsRotation", "5"], ["class", "card question-image p-2"]], null, null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "card-img-top"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "5"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getImageSrc(); _ck(_v, 3, 0, currVal_1); }); }
function View_JumbleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "cursor-pointer badge badge-pill badge-warning mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unassignAnswer(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.answer.content; _ck(_v, 1, 0, currVal_0); }); }
function View_JumbleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["appKidsRotation", ""], ["class", "badge badge-pill badge-secondary"]], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵted(-1, null, ["..."]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_JumbleComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["appKidsRotation", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null)], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.content; _ck(_v, 0, 0, currVal_0); }); }
function View_JumbleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumbleComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumbleComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumbleComponent_6)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.isPlaceholder() && _v.context.$implicit.answer); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.isPlaceholder() && !_v.context.$implicit.answer); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.$implicit.isPlaceholder(); _ck(_v, 6, 0, currVal_2); }, null); }
function View_JumbleComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["appKidsRotation", ""], ["class", "cursor-pointer badge badge-pill badge-warning mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignAnswer(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.content; _ck(_v, 2, 0, currVal_1); }); }
export function View_JumbleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "d-none d-md-block w-100"], ["style", "height: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumbleComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumbleComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "d-none d-md-block w-100"], ["style", "height: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "text-center display-5 mt-4 mt-md-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumbleComponent_3)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JumbleComponent_7)), i1.ɵdid(14, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasImage(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.hasImage(); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.questionTokens; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.questionAnswers; _ck(_v, 14, 0, currVal_3); }, null); }
export function View_JumbleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-jumble", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_JumbleComponent_0, RenderType_JumbleComponent)), i1.ɵdid(1, 245760, null, 0, i5.JumbleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JumbleComponentNgFactory = i1.ɵccf("app-jumble", i5.JumbleComponent, View_JumbleComponent_Host_0, {}, { finish: "finish" }, []);
export { JumbleComponentNgFactory as JumbleComponentNgFactory };
