import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
export class ThemeService {
    constructor(document) {
        this.exerciseSet = new ReplaySubject(1);
        this.chapter = new ReplaySubject(1);
        this.exerciseSet.subscribe(es => {
            if (this.mExerciseSet) {
                document.body.classList.remove(this.mExerciseSet);
            }
            if (es) {
                document.body.classList.add(es);
            }
            this.mExerciseSet = es;
        });
        this.chapter.subscribe(ch => {
            const chName = 'ch_' + ch;
            if (this.mChapter) {
                document.body.classList.remove(this.mChapter);
            }
            if (chName) {
                document.body.classList.add(chName);
            }
            this.mChapter = chName;
        });
    }
    isFooterDark() {
        return true;
    }
    clear() {
        this.exerciseSet.next('');
        this.chapter.next('');
    }
}
ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.DOCUMENT)); }, token: ThemeService, providedIn: "root" });
