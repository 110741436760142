import { HttpClient } from '@angular/common/http';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from "../environments/environment";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
Sentry.init({
    dsn: "https://e0441a451462499fa0bad9341d2cd1fe@o334018.ingest.sentry.io/5598636",
    environment: environment.envName,
    autoSessionTracking: true,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ["localhost", "https://cspa-dev.callanonline.com", "https://api.cspa.callanonline.com"],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ]
});
// AoT, loader factory for ngx/translations
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, './assets/i18n/_', '.json');
}
const ɵ0 = (createTranslateLoader), ɵ1 = Sentry.createErrorHandler({
    showDialog: false,
}), ɵ2 = () => () => { };
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
