/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./holding-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./holding-modal.component";
var styles_HoldingModalComponent = [i0.styles];
var RenderType_HoldingModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HoldingModalComponent, data: {} });
export { RenderType_HoldingModalComponent as RenderType_HoldingModalComponent };
export function View_HoldingModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { display: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "modal-dialog"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.preventPropagation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 15, "div", [["class", "modal-content border-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 14, "div", [["class", "modal-body text-center p-4 p-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We're moving to callan.app"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You'll be redirected in"])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "p", [["class", "my-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "h2"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "text-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" sec."])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "btn btn-outline-light rounded-pill py-2 px-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, ":svg:svg", [["enable-background", "new 0 0 16 16"], ["fill", "#000000"], ["height", "16px"], ["viewBox", "3 2 20 22"], ["width", "16px"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, ":svg:path", [["d", "M22,12c0-5.52-4.48-10-10-10C6.48,2,2,6.48,2,12s4.48,10,10,10C17.52,22,22,17.52,22,12z M4,12c0-4.42,3.58-8,8-8 c4.42,0,8,3.58,8,8s-3.58,8-8,8C7.58,20,4,16.42,4,12z M16,12l-4,4l-1.41-1.41L12.17,13H8v-2h4.17l-1.59-1.59L12,8L16,12z"], ["fill", "#FFFFFF"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Go there now "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.visible ? "block" : "none")); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.timer; _ck(_v, 13, 0, currVal_1); }); }
export function View_HoldingModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-holding-modal", [], null, null, null, View_HoldingModalComponent_0, RenderType_HoldingModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoldingModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HoldingModalComponentNgFactory = i1.ɵccf("app-holding-modal", i3.HoldingModalComponent, View_HoldingModalComponent_Host_0, {}, {}, []);
export { HoldingModalComponentNgFactory as HoldingModalComponentNgFactory };
