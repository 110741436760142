import { Injectable, Inject } from '@angular/core';
import { Subject, zip, ReplaySubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { combineAll, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  exerciseSet = new ReplaySubject<string>(1);
  chapter = new ReplaySubject<string>(1);
  private mExerciseSet: string;
  private mChapter: string;

  isFooterDark() {
    return true;
  }

  clear() {
    this.exerciseSet.next('');
    this.chapter.next('');
  }


  constructor(@Inject(DOCUMENT) document: Document) {
    this.exerciseSet.subscribe( es => {
      if (this.mExerciseSet) {
        document.body.classList.remove(this.mExerciseSet);
      }
      if (es) {
        document.body.classList.add(es);
      }
      this.mExerciseSet = es;
    });

    this.chapter.subscribe( ch => {
      const chName = 'ch_' + ch;
      if (this.mChapter) {
        document.body.classList.remove(this.mChapter);
      }
      if (chName) {
        document.body.classList.add(chName);
      }
      this.mChapter = chName;
    });
   }

}
