<div >
    <div class="row justify-content-center mt-5">
        <div class="col-12 col-sm-8 col-md-8 col-lg-6 mb-4">
            <div *ngIf="session" class="card shadow-lg h-100 text-dark"  appKidsRotation="5">
              <div *ngIf="isShowFireworks()" class="fireworks"></div>
              <div class="card-body">
                <!-- <h5 class="card-title">You finished !!!</h5>
                <h6 class="card-subtitle mb-2 text-muted">Overal score: {{getScore()}}%</h6> -->
                <p class="card-text">{{'pages.session-summary.you-finished' | translate}} <strong>{{getSessionName()}}</strong>.<br>
                    <span *ngIf="getResultState() === resultState.bad" [translate]="'pages.session-summary.results.bad'">OK, try again. You must get a high score to move to the next exercise.</span>
                    <span *ngIf="getResultState() === resultState.poor" [translate]="'pages.session-summary.results.poor'">OK, not bad. Try again to get a higher score.</span>
                    <span *ngIf="getResultState() === resultState.good" [translate]="'pages.session-summary.results.good'">Excellent! The next exercise is ready for you!</span>

                </p>
                <div class="my-2">
                  <div *ngFor="let question of session.questions" [ngClass]="getQuestionScoreClass(question)" class="exercise-entry-block" appKidsRotation>{{question.question.orderNumber}}</div>
                </div>
                <div class="text-right mt-2">
                  <a *ngIf="mayCorrect()" class="btn btn-secondary mr-1 text-light" (click)="recreateSession()" appKidsRotation [translate]="'common.action.try-again'">Try again</a>
                  <a (click)="finishSession()" tabindex="" class="btn btn-primary text-light" appKidsRotation [translate]="'common.action.finish'">Finish</a>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
