import {Component, NgZone, OnInit} from '@angular/core';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, filter, tap } from 'rxjs/operators';
import { zip, Observable } from 'rxjs';
import { ExerciseSet, Chapter, Section, Exercise } from 'src/app/model/struct';
import { ItemAvailability, ChapterAvailability } from 'src/app/model/personal';
import { FooterBarComponent } from '../footer-bar/footer-bar.component';
import { ThemeService } from 'src/app/services/theme.service';
import { ApiServiceProvider} from 'src/app/services/rest-provider.service';
import {logger} from "codelyzer/util/logger";
import {LoggerService} from "../../services/logger.service";

@Component({
  selector: 'app-exercise-set-host',
  templateUrl: './exercise-set-host.component.html',
  styleUrls: ['./exercise-set-host.component.scss']
})
export class ExerciseSetHostComponent implements OnInit {

  setPath: string;
  setDefinition: ExerciseSet;
  chaptersByPath: Map<string, Chapter>;
  availabilityByPath: Map<string, ItemAvailability>;
  availableChapters: Chapter[];
  selectedChapter: Chapter;
  prevChapter: Chapter;
  selectedIndex = 0;
  nextChapter: Chapter;
  private rest: CspaRestService;

  constructor(private restProvider: ApiServiceProvider,
              route: ActivatedRoute,
              private router: Router,
              private theme: ThemeService, private logger: LoggerService) {
    this.rest = restProvider.getRestServiceImplementation();
    route.paramMap
    .pipe(
      tap( params => this.setPath = params.get('setPath')),
      tap( params => theme.exerciseSet.next(params.get('setPath'))),
      switchMap( params => this.loadExerciseSetData(this.setPath)),
    ).subscribe(
      _ => this.selectChapter(this.findTheLatestChapter())
    );
  }

  selectChapter(path: string) {
    this.logger.log(`selecting the chapter ${path}`);
    this.prevChapter = this.nextChapter = this.selectedChapter = null;
    // iterate to find the path and fill previous and next elements also
    for (const chapter of this.availableChapters) {
      if (chapter.path === path) {
        this.selectedChapter = chapter;
        continue;
      }
      if (this.selectedChapter) {
        this.nextChapter = chapter;
        break;
      }
      this.prevChapter = chapter;
    }
    this.selectedChapter = this.chaptersByPath.get(path);
    this.selectedIndex = this.availableChapters.indexOf(this.selectedChapter);

    this.logger.log(`chapter selected ${this.selectedChapter}, with index ${this.selectedIndex}`);
  }

  getChapterSelectionClass(chapter: Chapter) {
    if (chapter === this.selectedChapter) {
      return 'selected';
    } else if (chapter === this.prevChapter) {
      return 'prev';
    } else if (chapter === this.nextChapter) {
      return 'next';
    }
    if (this.availableChapters.indexOf(chapter) < this.selectedIndex) {
      return 'left';
    } else {
      return 'right';
    }
  }

  findTheLatestChapter(): string {
    this.logger.log(`searching for the latest chapter.`);
    const res = this.availableChapters.map( ch => this.availabilityByPath.get(ch.path))
    .filter( av => av != null)
    .sort( (l, r) => {
        const lCasted = l as ChapterAvailability;
        const rCasted = r as ChapterAvailability;
        const lSubmit = lCasted.lastSubmit ? lCasted.lastSubmit : 0;
        const rSubmit = rCasted.lastSubmit ? rCasted.lastSubmit : 0;
        return rSubmit - lSubmit;
      }
    )[0].path;
    this.logger.log('found the latest chapter: ${res}');
    return res;
  }

  /* events handling */
  onChapterClick(chapter: Chapter) {
    this.selectChapter(chapter.path);
  }

  public closeExerciseSet() {
    this.theme.clear();
    if (!this.rest.isNativeImplementation()) {
      this.router.navigate(['exercises']);
    }
    else {
      this.restProvider.getMobileRestServiceImplementation().close();
    }

  }

  getChapterName() {
    if (!this.selectedChapter) {
      return '';
    }
    return this.selectedChapter.shortName;
  }


  onFooterInteract(event: string) {
    if (event === FooterBarComponent.LEFT && this.prevChapter) {
      this.selectChapter(this.prevChapter.path);
    } else if (event === FooterBarComponent.RIGHT && this.nextChapter) {
      this.selectChapter(this.nextChapter.path);
    }
  }

  /* view accessors */

  hasNext() {
    return !!this.nextChapter;
  }

  hasPrev() {
    return !!this.prevChapter;
  }

  getSelectedChapter() {
    return this.selectedChapter;
  }

  getAvailabilities() {
    return this.availabilityByPath;
  }

  getAvailabileChapters() {
    if (!this.availableChapters) {}
    return this.availableChapters;
  }

  getChapterAvailability(chapter: Chapter) {
    return this.availabilityByPath.get(chapter.path);
  }

  public getTopBarTitle() {
    if (!this.setDefinition || !this.selectChapter) {
      return '';
    }
    return this.setDefinition.name;
  }

  /* data loading and preparation */
  loadExerciseSetData(path: string): Observable<any> {
    this.logger.log(`starting exercise host. Loading data for ${path}.`)
    return zip(
      this.rest.listExerciseSets(),
      this.rest.listChapters(path + '_'),
      this.rest.listAvailabilities(path + '_', 3)
    ).pipe(
      tap(([setsDefinition, chaptersDefinition, availabilities]: [ExerciseSet[], Chapter[], ItemAvailability[]])  => {
        this.logger.log(`data loaded: sets size: ${setsDefinition.length}, chapters: ${chaptersDefinition.length}, availabilities: ${availabilities.length}`);
        this.prepareSetDefinition(setsDefinition);
        this.prepareChaptersDefinitionsMap(chaptersDefinition);
        const avByPath = this.prepareAvailabilitiesByPath(availabilities);
        this.extractAvailableChapters(chaptersDefinition, avByPath);
      }
    ));
  }

  extractAvailableChapters(chaptersDefinition: Chapter[], avByPath: Map<string, ItemAvailability>) {
    this.logger.log(`extracting available chapters`);
    this.availableChapters = chaptersDefinition
      .sort((l, r) => l.orderNumber - r.orderNumber)
      .filter( c => {
        const chapterAv = avByPath.get(c.path);
        return chapterAv && chapterAv.assigned && chapterAv.available;
      });
    this.logger.log(`got ${this.availableChapters.length} available chapters.`);
  }

  prepareAvailabilitiesByPath(availablities: ItemAvailability[]) {
    this.logger.log(`preparing availability map`);
    this.availabilityByPath = new Map(availablities.map(av => [av.path, av]));
    this.logger.log(`availability map prepared with size ${this.availabilityByPath.size}`);
    return this.availabilityByPath;

  }

  prepareChaptersDefinitionsMap(chaptersDefinition: Chapter[]) {
    this.logger.log(`preparing chapters definition map`);
    this.chaptersByPath = new Map(
      chaptersDefinition.map(ch => [ch.path, ch])
    );
    this.logger.log(`got chapters definition map with size ${this.chaptersByPath.size}`);
  }

  prepareSetDefinition(setsDefinition: ExerciseSet[]) {
    this.logger.log(`preparing set definition for currnent path`);
    this.setDefinition = setsDefinition.find( s => s.path === this.setPath);
    this.logger.log(`got set definition ${this.setDefinition.name}`);
  }

  ngOnInit() {
  }
}
