import { Type } from '@angular/core';
import { QuestionAnswerComponent } from '../components/questions/question-answer/question-answer.component';
import { Qa, QaAnswer, DictationAnswer, Dictation, FillMultiAnswer, FillMulti, CorrectionAnswer, Correction, JumbleAnswer, Jumble, ImagesAnswer, Images } from '../model/questions';
import { DictationComponent } from '../components/questions/dictation/dictation.component';
import { AppBaseException } from '../errors';
import { FillMultiComponent } from '../components/questions/fill-multi/fill-multi.component';
import { CorrectionComponent } from '../components/questions/correction/correction.component';
import { JumbleComponent } from '../components/questions/jumble/jumble.component';
import { PicturesComponent } from '../components/questions/pictures/pictures.component';
import * as i0 from "@angular/core";
class CD {
    constructor(answerType, questionType, component, questionTypeMeta) {
        this.answerType = answerType;
        this.questionType = questionType;
        this.component = component;
        this.questionTypeMeta = questionTypeMeta;
    }
}
export class QuestionComponentProviderService {
    constructor() {
        this.questionsComponents = [
            new CD(QaAnswer, Qa, QuestionAnswerComponent, 'qa'),
            new CD(DictationAnswer, Dictation, DictationComponent, 'dict'),
            new CD(FillMultiAnswer, FillMulti, FillMultiComponent, 'ef'),
            new CD(CorrectionAnswer, Correction, CorrectionComponent, 'cor'),
            new CD(JumbleAnswer, Jumble, JumbleComponent, 'jmb'),
            new CD(ImagesAnswer, Images, PicturesComponent, 'pct')
        ];
    }
    // questionsComponents: QuestionComponentBase<any, any>[] = [QuestionAnswerComponent];
    recognizeComponent(sessionQuestion) {
        for (const questionComponent of this.questionsComponents) {
            if ((sessionQuestion.question.definition instanceof questionComponent.questionType)
                || sessionQuestion.question.definition['@type'] === questionComponent.questionTypeMeta) {
                return questionComponent.component;
            }
        }
        throw new AppBaseException('didn\'t found a correct component for the session question');
    }
}
QuestionComponentProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionComponentProviderService_Factory() { return new QuestionComponentProviderService(); }, token: QuestionComponentProviderService, providedIn: "root" });
