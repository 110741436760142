export class AnswerDefinitionBase {
}
export class QuestionDefinitionBase {
}
export class Question {
}
export class PlaceholderAnswer {
}
export class CorrectionAnswer extends AnswerDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'cor';
    }
}
export class DictationAnswer extends AnswerDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'dict';
    }
}
export class FillMultiAnswer extends AnswerDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'ef';
    }
}
export class ImagesAnswer extends AnswerDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'pct';
    }
}
export class JumbleAnswer extends AnswerDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'jmb';
    }
}
export class QaAnswer extends AnswerDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'qa';
    }
}
export class Correction extends QuestionDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'cor';
    }
}
export class Dictation extends QuestionDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'dict';
    }
}
export class FillMulti extends QuestionDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'ef';
    }
}
export class Images extends QuestionDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'pct';
    }
}
export class Jumble extends QuestionDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'jmb';
    }
}
export class Qa extends QuestionDefinitionBase {
    constructor() {
        super(...arguments);
        this['@type'] = 'qa';
    }
}
