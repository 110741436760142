import { CasaExerciseSession, CasaSessionQuestion } from 'src/app/model/oldModel';
import { ExerciseSession, ExerciseSessionQuestion } from 'src/app/model/personal';
import { CorrectionAnswer, Correction, JumbleAnswer, Jumble,
    FillMultiAnswer, FillMulti, ImagesAnswer, Images, QaAnswer, Qa,
    DictationAnswer, Dictation, Question, PlaceholderAnswer } from 'src/app/model/questions';
import { BlockComparator, StringTokenizer } from 'src/app/utils/text-comparing';


export class CasaToCspaSessionModelMapper {
    map(src: CasaExerciseSession): ExerciseSession {
        if (!src) {
            return null;
        }
        const dst = new ExerciseSession();
        dst.id = src.id;
        dst.score = src.score;
        dst.baseQuestionNumber = src.baseSessionQuestionNumber;
        dst.startDate = src.startDate ? new Date(src.startDate).toUTCString() : null;
        dst.deviceUUID = src.deviceUUID;
        dst.lastUpdateDate = src.lastUdpateDate ;
        dst.questions = src.questions.map( q => this.mapQuestion(q));
        for (let i = 0 ; i < dst.questions.length ; i++) {
            dst.questions[i].question.orderNumber = i + 1;
        }
        return dst;
    }

    // extra-corr extra-jumb extra-fill-multi extra-picts question-answer dictation
    mapQuestion(q: CasaSessionQuestion): ExerciseSessionQuestion<any, any> {
        const res = new ExerciseSessionQuestion();
        res.answered = q.score > 0;
        res.correct = q.score > 0.5;
        res.score = q.score;
        this.mapQuestionContent(res, q);

        return res;
    }

    mapQuestionContent(res: ExerciseSessionQuestion<any, any>, q: CasaSessionQuestion) {
        if (q.def.type === 'extra-corr') {
            this.mapCorrectionQuestion(res as ExerciseSessionQuestion<CorrectionAnswer, Correction>, q);
        } else if (q.def.type === 'extra-jumb') {
            this.mapJumbleQuestion(res as ExerciseSessionQuestion<JumbleAnswer, Jumble>, q);
        } else if (q.def.type === 'extra-fill-multi' ) {
            this.mapFillQuestion(res as ExerciseSessionQuestion<FillMultiAnswer, FillMulti>, q);
        } else if (q.def.type === 'extra-fill-text') {
            this.mapFillTextQuestion(res as ExerciseSessionQuestion<FillMultiAnswer, FillMulti>, q);
        } else if (q.def.type === 'extra-picts') {
            this.mapPictures(res as ExerciseSessionQuestion<ImagesAnswer, Images>, q);
        } else if (q.def.type === 'question-answer') {
            this.mapQa(res as ExerciseSessionQuestion<QaAnswer, Qa>, q);
        } else if (q.def.type === 'dictaction') {
            this.mapDictation(res as ExerciseSessionQuestion<DictationAnswer, Dictation>, q);
        } else if (q.def.type === 'extra-fill-label') {
            this.mapFillLabel(res as ExerciseSessionQuestion<JumbleAnswer, Jumble>, q);
        } else {
            throw Error(`not supported question type ${q.def.type}`);
        }
    }

    extractResourceRelative(urlParam: string) {
        const match = urlParam.match(/^(https?:\/\/[^\/]+)?/);
        if (match && match[1]) {
            return urlParam.substring(match[1].length, urlParam.length);
        }
        return urlParam;
    }

    mapDictation(target: ExerciseSessionQuestion<DictationAnswer, Dictation>, q: CasaSessionQuestion) {
        const question = new Question<DictationAnswer, Dictation>();
        target.question = question;
        question.id = q.def.id;
        const def = new Dictation();
        question.definition = def;
        def.instructions = q.def.instructions;
        def.src = q.def.resourceBase;
        def.answer = new DictationAnswer();
        def.answer.answer = q.def.definition.answer;
        const relativeResource  = this.extractResourceRelative(q.def.resourceBase);
        def.src = relativeResource.substring(0, relativeResource.length - 4);
    }

    mapQa(target: ExerciseSessionQuestion<QaAnswer, Qa>, q: CasaSessionQuestion) {
        const question = new Question<QaAnswer, Qa>();
        target.question = question;
        question.id = q.def.id;
        const def = new Qa();
        question.definition = def;
        def.instructions = q.def.instructions;
        const resourceBase = this.extractResourceRelative(q.def.resourceBase);
        if (q.def.hasImage || q.def.definition.hasImage) {
            // handle old hack for the image in qa
            if (q.def.definition.correctAnswer) {
                def.img = this.extractResourceRelative(q.def.definition.correctAnswer);
            } else {
                // old way image
                def.img = resourceBase + '.image.jpg';
            }
        }
        def.female = !q.def.definition.askingSexMale;
        def.src = resourceBase;
        def.question = q.def.definition.question;
        def.questionAnswer = q.def.definition.answer;
        let prompt = [];
        // do the hack for rest of the prompt in backward compatibility way
        if ( q.def.definition.answers ) {
            prompt = prompt.concat(q.def.definition.answers);
        }
        if (q.def.definition.correction) {
            prompt.push(q.def.definition.correction);
        }
        def.prompt = prompt;
    }

    mapPictures(target: ExerciseSessionQuestion<ImagesAnswer, Images>, q: CasaSessionQuestion) {
        const question = new Question<ImagesAnswer, Images>();
        target.question = question;
        question.id = q.def.id;
        const def = new Images();
        question.definition = def;
        def.instructions = q.def.instructions;
        def.words = q.def.definition.answers;
        // do the images in the old way
        const resourceBase = this.extractResourceRelative(q.def.resourceBase);
        def.images = def.words.map( word => resourceBase + '/' + word + '.png');
        // optionally do the hack for images
        if (q.def.definition.affirm) {
            def.images = JSON.parse(q.def.definition.affirm);
        }
        const answer = new ImagesAnswer();
        answer.answers = [];
        for (let i = 0 ; i < def.words.length ; i++ ) {
            const placeholder = new PlaceholderAnswer();
            placeholder.name = def.words[i];
            placeholder.val = [def.images[i]];
            answer.answers.push(placeholder);
        }
        question.definition.answer = answer;
    }

    mapFillTextQuestion(target: ExerciseSessionQuestion<FillMultiAnswer, FillMulti>, q: CasaSessionQuestion) {
        const question = new Question<FillMultiAnswer, FillMulti>();
        target.question = question;
        question.id = q.def.id;
        const def = new FillMulti();
        question.definition = def;
        def.instructions = q.def.instructions;
        if ((q.def.hasImage || q.def.definition.hasImage) && q.def.resourceBase) {
            def.img = this.extractResourceRelative(q.def.resourceBase);
        }
        def.question = q.def.definition.question.replace('$$$', '{0}');
        def.answer = new FillMultiAnswer();
        const placehloder = new PlaceholderAnswer();
        placehloder.name = '0';
        placehloder.val = [q.def.definition.answer];
        def.answer.answer = [placehloder];
    }

    mapFillQuestion(target: ExerciseSessionQuestion<FillMultiAnswer, FillMulti>, q: CasaSessionQuestion) {
        const question = new Question<FillMultiAnswer, FillMulti>();
        target.question = question;
        question.id = q.def.id;
        const def = new FillMulti();
        question.definition = def;
        def.instructions = q.def.instructions;
        if ((q.def.hasImage || q.def.definition.hasImage) && q.def.resourceBase) {
            def.img = this.extractResourceRelative(q.def.resourceBase);
        }

        // tokenize
        const tokenized = this.tokenizeQuestionWithAnswersProvided(q.def.definition.question, q.def.definition.answer, q.def.definition.answers);
        for (const answer of q.def.definition.answers) {
            // additional value
            if ( answer.charAt(0) === '(' && answer.charAt(answer.length - 1) === ')' && answer.indexOf('::') > 0) {
                const valuePair  = answer.substring(1, answer.length - 1).split('::');
                const answerToken = tokenized.answers.find( a => a.name === valuePair[0]);
                if (answerToken) {
                    answerToken.val.push(valuePair[1]);
                }
            }

            // alternatives
            if ( answer.charAt(0) === '[' && answer.charAt(answer.length - 1) === ']' && answer.indexOf('::') > 0) {
                const valuePair  = answer.substring(1, answer.length - 1).split('::');
                const answerToken = tokenized.answers.find( a => a.name === valuePair[0]);
                if (answerToken) {
                    if (!answerToken.alt) {
                        answerToken.alt = [];
                    }
                    answerToken.alt.push(valuePair[1]);
                }
            }
        }

        def.question = tokenized.tokenizedQuestion;
        def.answer = new FillMultiAnswer();
        def.answer.answer = tokenized.answers;
    }

    mapFillLabel(target: ExerciseSessionQuestion<JumbleAnswer, Jumble>, q: CasaSessionQuestion) {
        const question = new Question<JumbleAnswer, Jumble>();
        target.question = question;
        question.id = q.def.id;
        const def = new Jumble();
        question.definition = def;
        def.instructions = q.def.instructions;
        if ((q.def.hasImage || q.def.definition.hasImage) && q.def.resourceBase) {
            def.img = this.extractResourceRelative(q.def.resourceBase);
        }
        def.question = q.def.definition.question.replace('$$$', '{0}');
        const placeholder = new PlaceholderAnswer();
        placeholder.name = '0';
        placeholder.val = [q.def.definition.correctAnswer];
        placeholder.alt = [...q.def.definition.answers];
        placeholder.alt.splice(placeholder.alt.indexOf(placeholder.val[0]), 1);
        def.answer = new JumbleAnswer();
        def.answer.answer = [placeholder];
    }


    mapJumbleQuestion(target: ExerciseSessionQuestion<JumbleAnswer, Jumble>, q: CasaSessionQuestion) {
        const question = new Question<JumbleAnswer, Jumble>();
        target.question = question;
        question.id = q.def.id;
        const def = new Jumble();
        question.definition = def;
        def.instructions = q.def.instructions;
        if ((q.def.hasImage || q.def.definition.hasImage) && q.def.resourceBase) {
            def.img = this.extractResourceRelative(q.def.resourceBase);
        }

        // tokenize
        //const tokenized = this.tokeniseQuestion(q.def.definition.question, q.def.definition.answer);
      const tokenized = this.tokenizeQuestionWithAnswersProvided(q.def.definition.question, q.def.definition.answer, q.def.definition.answers);

        def.answers = q.def.definition.answers;
        def.question = tokenized.tokenizedQuestion;
        def.answer = new JumbleAnswer();
        def.answer.answer = tokenized.answers;

        // do the hack
        if (q.def.definition.affirm) {
            const additionalValues: PlaceholderAnswer[] = JSON.parse(q.def.definition.affirm);
            for (const placehoder of additionalValues) {
                const targetAnswer = def.answer.answer.find( a => a.name === placehoder.name);
                if (targetAnswer) {
                    targetAnswer.val = placehoder.val;
                    targetAnswer.alt = placehoder.alt;
                }
            }
        }
    }

    mapCorrectionQuestion(target: ExerciseSessionQuestion<CorrectionAnswer, Correction>, q: CasaSessionQuestion) {
        const question = new Question<CorrectionAnswer, Correction>();
        target.question = question;
        question.id = q.def.id;
        const def = new Correction();
        question.definition = def;
        def.instructions = q.def.instructions;
        if (q.def.resourceBase) {
            def.img = this.extractResourceRelative(q.def.resourceBase);
        }
        def.incorrect = q.def.definition.question;
        def.answer = new CorrectionAnswer();
        def.answer.correct = q.def.definition.answer;
    }

    private giveTryRecursive(question: string, answer: string, availableAnswers: string[]): string[] {
      const placeHolderPosition = question.indexOf("$$$");
      if (placeHolderPosition < 0) throw new Error("Invalid data format, missing question placeholder");
      for (let tryNumber = 0 ; tryNumber < availableAnswers.length ; tryNumber++) {
        const trySelectedAnswer = availableAnswers[tryNumber];
        const questionTransformed = question.replace("$$$", trySelectedAnswer);
        // this was the last placeholder. If return the answer if correct or give another try
        if (questionTransformed.indexOf("$$$") < 0) {
          if (questionTransformed === answer) {
            return [trySelectedAnswer];
          } else {
            continue;
          }
        }

        // it wasn't the last placeholder. Call recursive to resolve all.
        const newAnswerSet = Array.from(availableAnswers);
        newAnswerSet.splice(tryNumber, 1);
        const recursiveTryResult = this.giveTryRecursive(questionTransformed, answer, newAnswerSet);

        // this try was failed, give another
        if (!recursiveTryResult) continue;

        return [trySelectedAnswer].concat(recursiveTryResult);
      }

      // no more tries, all was failed
      return null;
    }

    private tokenizeQuestionWithAnswersProvided(question: string, answer: string, availableAnswers: string[]) {
      const resultMatches = this.giveTryRecursive(question, answer, availableAnswers);
      const answers : PlaceholderAnswer[] = [];

      if (!resultMatches) throw new Error("error processing the question placeholders. No answer matched");
      let tokenizedQuestion = question;
      for (let placeholderNumber = 0 ; placeholderNumber < resultMatches.length ; placeholderNumber++) {
        tokenizedQuestion = tokenizedQuestion.replace("$$$", `{${placeholderNumber}}`);
        const answerPlaceholder = new PlaceholderAnswer();
        answerPlaceholder.name = placeholderNumber.toString();
        answerPlaceholder.val = [resultMatches[placeholderNumber]];
        answers.push(answerPlaceholder);
      }

      return {
        tokenizedQuestion,
        answers
      };
    }

    private tokeniseQuestion( question: string, answer: string) {

        const qTokens = BlockComparator.tokenize(question, false, StringTokenizer.specialCharacters.filter( s => s.symbol === '\'' || s.symbol == '-'));
        const aTokens = BlockComparator.tokenize(answer, false, StringTokenizer.specialCharacters.filter( s => s.symbol === '\'' || s.symbol == '-'));

        const compareResults = BlockComparator.compare(aTokens, qTokens);
        let placeholderName = 0;
        let tokenizedQuestion = '';
        const answers: PlaceholderAnswer[] = [];
        let lastAnswer: PlaceholderAnswer;

        for (const node of compareResults) {
            if ( node.added == null && node.removed == null ) {
                continue;
            }
            if ( node.added.content === '$$$' ) {
                if (lastAnswer) {
                    lastAnswer.val[0] = lastAnswer.val[0].trim();
                }
                tokenizedQuestion += '{' + placeholderName + '}' + (node.added.space ? ' ' : '');
                const placehloder = new PlaceholderAnswer();
                placehloder.name = placeholderName.toString();
                placehloder.val = [node.removed.content + (node.removed.space ? ' ' : '')];
                answers.push(placehloder);
                lastAnswer = placehloder;
                placeholderName++;
            } else if (!node.removed) {
                if (lastAnswer) {
                    lastAnswer.val[0] += node.added.content + (node.added.space ? ' ' : '');
                }
                continue;
            } else {
                tokenizedQuestion += node.added.content + (node.added.space ? ' ' : '');
            }
        }
        if (lastAnswer) {
            lastAnswer.val[0] = lastAnswer.val[0].trim();
        }

        return {
            tokenizedQuestion,
            answers
        };
    }



}
