<div class="card-deck mt-2">
    <div class="card d-none d-sm-block">
      <video class="card-img-top" controls #videoPlayer>
          <!-- TODO: prevent loading if not required -->
          <source [src]="getVideoSource()" type="video/mp4">
      </video>
    </div>
    <div class="card answer-card" >
      <div class="card-body position-relative">
        <div class="answer-container text-dark">
          <textarea autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" class="answer form-control h-100" [(ngModel)]="answer.answer" placeholder="{{'component.questions.dict.form.write-here' | translate}}"></textarea>
        </div>

      </div>
      <div class="card-footer text-center">
        <div class="row justify-content-end">
          <div class="col d-sm-none">
            <audio controls #audioPlayer>
              <source [src]="getAudioSource()" type="audio/mp3">
            </audio>
          </div>
          <div class="col-auto">
            <div class="d-block d-sm-none mt-2"></div>
            <button (click)="doFinish()" class="btn btn-primary" [translate]="'component.questions.dict.action.check'">Check</button>
          </div>
        </div>
      </div>
    </div>
  </div>

<app-modal #summaryModal>
    <div class="modal-content">
        <div class="modal-header text-dark">
            <h5 class="modal-title">{{'component.questions.dict.summary' | translate }} ({{getScore()}}%)</h5>
            <button type="button" class="close" (click)="backToCorrection()"  aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body text-dark">
          <span [ngClass]="item.kind" *ngFor="let item of summary">{{item.content}}</span>
        </div>
        <div class="modal-footer text-light">
            <a class="btn btn-secondary" (click)="backToCorrection()" [translate]="'component.questions.dict.action.correct'">Correct mistakes</a>
            <a class="btn btn-primary" (click)="submitAnswer()" [translate]="'component.questions.dict.action.continue'">Continue</a>
        </div>
    </div>
</app-modal>
