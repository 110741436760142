/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./close.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./close.component";
var styles_CloseComponent = [i0.styles];
var RenderType_CloseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CloseComponent, data: {} });
export { RenderType_CloseComponent as RenderType_CloseComponent };
export function View_CloseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container text-center pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["The exercise is finished. You may close this window."]))], function (_ck, _v) { var currVal_0 = "pages.close"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CloseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-close", [], null, null, null, View_CloseComponent_0, RenderType_CloseComponent)), i1.ɵdid(1, 114688, null, 0, i3.CloseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CloseComponentNgFactory = i1.ɵccf("app-close", i3.CloseComponent, View_CloseComponent_Host_0, {}, {}, []);
export { CloseComponentNgFactory as CloseComponentNgFactory };
