import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, AfterViewChecked, HostListener } from '@angular/core';
import { QuestionComponentBase, AnswerWrapper } from '../question-component-base';
import { FillMultiAnswer, FillMulti, PlaceholderAnswer } from 'src/app/model/questions';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PatternParser } from 'src/app/utils/placeholder-parser';

class AnswerToken {
  public help: string;
  constructor(
    public editable: boolean,
    public content: string,
    public placeholderName: string) {}
}

@Component({
  selector: 'app-fill-multi',
  templateUrl: './fill-multi.component.html',
  styleUrls: ['./fill-multi.component.scss']
})
export class FillMultiComponent extends QuestionComponentBase<FillMultiAnswer, FillMulti> implements OnInit, OnDestroy, AfterViewChecked {

  questionSubscription: Subscription;
  tokens: AnswerToken[];
  answer: FillMultiAnswer;
  hadInitialFocus = false;
  @ViewChildren('inputToken') inputs: QueryList<ElementRef>;

  getAnswerForSubmit(): Observable<AnswerWrapper<FillMultiAnswer>> {
    this.populateTokensOnAnswer();
    return of(new AnswerWrapper(this.answer));
  }

  setupAnswer(answer: FillMultiAnswer) {
    if (!answer) {
      this.answer = new FillMultiAnswer();
      this.answer.answer = [];
    } else {
      this.answer = answer;
    }
    this.populateAnswerOnTokens();
  }

  public getInputStyle(token: AnswerToken) {
    if (!token || !token.content) {
      return {width: '100px'};
    }
    let width = Math.max(100, (40 + token.content.length * 20));
    if (width > 300) {
      width = 300;
    }
    return {width: width + 'px'};
  }

  getMiddleDescription() {
    return 'help';
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.type === 'keydown' && event.key === 'Enter') {
      let focus = false;
      for (const input of this.inputs.toArray()) {
        if (input.nativeElement === document.activeElement) {
          focus = true;
          continue;
        }
        if (focus) {
          input.nativeElement.focus();
        }
      }
      this.doFinish();
    }
  }

  onMiddleClick() {
    const tokensMayBeHelped = this.tokens
    .filter(token => token.editable)
    .filter(token => !token.help || token.help.indexOf('_') >= 0)
    .map( token => {
      return {token, answer: this.question.question.definition.answer.answer.find( ph => token.placeholderName === ph.name)};
    })
    .filter( e => e.answer != null)
    .filter( e => {
      const answerTrimmed = e.token.content ? e.token.content.trim() : '';
      return e.answer.val.indexOf(answerTrimmed) < 0 && ( !e.answer.alt || e.answer.alt.indexOf(answerTrimmed) < 0);
    });

    if (tokensMayBeHelped.length === 0) {
      return;
    }

    const tokenEntryToHelp = tokensMayBeHelped[Math.round(Math.random() * (tokensMayBeHelped.length - 1))];
    const helpToken = tokenEntryToHelp.token;
    const answer = tokenEntryToHelp.answer.val[0];
    let help = helpToken.help;
    if (!help) {
      help = '_'.repeat(answer.length);
    }
    const availablePositions = [];
    for (let i = 0; i < help.length; i++) {
      if (help.charAt(i) === '_') {
        availablePositions.push(i);
      }
    }
    const helpPosition = availablePositions[Math.round(Math.random() * (availablePositions.length - 1))];
    help = help.substring(0, helpPosition) + answer.charAt(helpPosition) + help.substring(helpPosition + 1, help.length);
    helpToken.help = help;
  }

  populateAnswerOnTokens() {
    if (!this.tokens || !this.answer) {
      return;
    }
    this.tokens.filter(token => token.editable).forEach(token => token.content = '');
    this.answer.answer
      .filter( entry => entry.name && entry.val && entry.val.length === 1)
      .forEach( entry => {
        const tokenFound = this.tokens.find( token => token.editable && token.placeholderName === entry.name);
        if (tokenFound) {
          tokenFound.content = entry.val[0];
        }
      });
  }

  populateTokensOnAnswer() {
    if (!this.tokens || !this.answer) {
      return;
    }
    this.answer.answer = [];
    this.answer.answer = this.tokens
      .filter( token => token.editable)
      .map( token => {
        const res = new PlaceholderAnswer();
        res.name = token.placeholderName;
        res.val = [token.content];
        return res;
      });
  }

  hasImage() {
    return this.question && this.question.question.definition.img;
  }

  getImageSrc() {
    return this.question ? environment.awsBase + this.question.question.definition.img : null;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.questionSubscription = this.questionSubject.subscribe(
      _ => this.prepareQuestionData()
    );
  }
  prepareQuestionData(): void {
    if (!this.question) {
      return;
    }
    this.tokens = PatternParser.parse(this.question.question.definition.question)
      .map( pi => new AnswerToken(pi.isPlaceholder,
        pi.isPlaceholder ? '' : pi.content,
        pi.isPlaceholder ? pi.content : null));
    this.populateAnswerOnTokens();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.questionSubscription) {
      this.questionSubscription.unsubscribe();
      this.questionSubscription = null;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.hadInitialFocus) {
      this.inputs.toArray()[0].nativeElement.focus();
      this.hadInitialFocus = true;
    }
  }
}
