<div class="mt-2">
    <div class="d-none d-md-block w-100" style="height: 100px;"></div>
    <div *ngIf="!hasImage()" class="d-md-none w-100" style="height: 100px;"></div>
    <div class="row">
    <div *ngIf="hasImage()" class="col-12 col-md-4">
        <div class="card question-image p-2"  appKidsRotation="5">
        <img [src]="getImageSrc()" class="card-img-top" >
        </div>
    </div>
    <div class="col">
        <div class="d-none d-md-block w-100" style="height: 50px;"></div>
        <div class="text-center display-5 mt-4 mt-md-0">
            <span *ngFor="let token of questionTokens">
                <span (click)="unassignAnswer(token)" class="cursor-pointer badge badge-pill badge-warning mr-2" 
                    *ngIf="token.isPlaceholder() && token.answer">{{token.answer.content}}</span>
                <span class="badge badge-pill badge-secondary" 
                    *ngIf="token.isPlaceholder() && !token.answer"  appKidsRotation>...</span>
                <span *ngIf="!token.isPlaceholder()"  appKidsRotation [innerHtml]="token.content">
                </span>
            </span>
        <div class="mt-2">
            <span (click)="assignAnswer(token)" *ngFor="let token of questionAnswers" 
            class="cursor-pointer badge badge-pill badge-warning mr-2"  appKidsRotation>{{token.content}}</span>
        </div>
        </div>
    </div>
    </div>
</div>
