import { Directive, ElementRef, OnDestroy, Input } from '@angular/core';
import { ThemeService } from '../services/theme.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appKidsRotation]'
})
export class KidsRotationDirective implements OnDestroy {

  subscription: Subscription;
  mRange: number;
  exerciseSet: string;

  @Input('appKidsRotation')
  set range(r: string) {
    this.mRange = Number(r);
    this.makeRotation();
  }

  constructor(private el: ElementRef, theme: ThemeService) {
    this.subscription  = theme.exerciseSet.subscribe( exerciseSet => {
      this.exerciseSet = exerciseSet;
      this.makeRotation();
    });
  }

  makeRotation() {
    if (!this.exerciseSet) {
      return;
    }
    let val = 0;
    const max = this.mRange ? this.mRange : 10.0;
    if (this.exerciseSet === 'encfk') {
      val = Math.random() * max - max / 2.0;
    }
    this.el.nativeElement.style.transform = `rotate(${val}deg)`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
