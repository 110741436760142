import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "ngx-store";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
/**
 * Auth response class
 */
class AuthResponse {
}
/**
 * Token data but without token content - for
 * security reason if app wants to know about authorization details
 * but is not engouth trusted to show the token content
 */
export class AuthorizationData {
}
/**
 * extendend token data - containing the token content
 */
export class AuthorizationTokenData extends AuthorizationData {
}
/**
 * token store using local storage
 */
export class AuthStoreImpl {
    constructor(localStorage) {
        this.localStorage = localStorage;
    }
    readCurrentAuthorizationData() {
        return of(this.localStorage.get(AuthStoreImpl.tokenEntryLocation));
    }
    clear() {
        this.localStorage.remove(AuthStoreImpl.tokenEntryLocation);
    }
    storeAuthorizationToken(token) {
        this.localStorage.remove(AuthStoreImpl.tokenEntryLocation);
        const tokenData = this.parseToken(token);
        this.localStorage.set(AuthStoreImpl.tokenEntryLocation, tokenData);
        return of(tokenData);
    }
    parseToken(token) {
        const splitted = token.split('.');
        const data = JSON.parse(atob(splitted[1]));
        const tokenData = new AuthorizationTokenData();
        tokenData.accountId = data.userEmail;
        tokenData.personId = data.personId;
        tokenData.accountId = data.userId;
        tokenData.expiryDate = new Date(data.exp * 1000).getTime();
        tokenData.token = token;
        return tokenData;
    }
}
AuthStoreImpl.tokenEntryLocation = 'cspaAuthTokenData';
AuthStoreImpl.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthStoreImpl_Factory() { return new AuthStoreImpl(i0.ɵɵinject(i1.LocalStorageService)); }, token: AuthStoreImpl, providedIn: "root" });
export class AuthService {
    constructor(authStore, http, router) {
        this.authStore = authStore;
        this.http = http;
        this.router = router;
    }
    readCurrentAuthData() {
        return this.authStore.readCurrentAuthorizationData();
    }
    logout() {
        this.authStore.clear();
        this.router.navigate(['/']);
    }
    login(returnState) {
        const oauth = environment.authEndpoint
            + '/oauth/school/1/login?' + this.constructOauth(returnState);
        window.location.href = oauth;
    }
    loginWithAuthCode(authCode, state) {
        this.askForAccessToken(authCode)
            .pipe(flatMap(tokenResponse => this.authStore.storeAuthorizationToken(tokenResponse.access_token))).subscribe(_ => this.openState(state));
    }
    loginWithPlainToken(token, state) {
        this.authStore.clear();
        this.authStore.storeAuthorizationToken(token)
            .subscribe(_ => this.openState(state));
    }
    openState(state) {
        this.router.navigateByUrl(state);
    }
    askForAccessToken(code) {
        const codeRequest = {
            code,
            client_id: environment.authClientId,
            redirect_uri: environment.serverBase + '/oauth'
        };
        return this.http.post(environment.authEndpoint + '/oauth/token', codeRequest);
    }
    constructOauth(stateUrl) {
        const stateStr = encodeURIComponent(btoa(stateUrl));
        const clientId = environment.authClientId;
        const redirectUrl = encodeURIComponent(environment.serverBase + '/oauth');
        const queryPart = 'response_type=code&chooseProfile=0&client_id='
            + clientId
            + '&state=' + stateStr
            + '&redirect_uri=' + redirectUrl;
        return queryPart;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(AuthStoreImpl), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Router)); }, token: AuthService, providedIn: "root" });
