
<div class="container pt-2">
    <h3 class="">{{'pages.root.welcome-to' | translate}} <strong>C</strong>allan <strong>S</strong>tudent <strong>P</strong>ractice <strong>A</strong>rea – the <strong>CSPA</strong></h3>
    <p class="mt-4">
        {{'pages.root.intro' | translate}}
        <br>
        <small>{{'pages.root.expl-real-teachers' | translate}}</small>
    </p>
    <div class="text-center my-5">
        <a class="btn btn-lg btn-primary" routerLink="/exercises" [translate]="'pages.root.action.enter'">Enter here</a>
    </div>
    <div class="row my-5 justify-content-center">
        <div class="col-sm-4 col-lg-3 h-100">

            <div class="back-shadow-wrapper screenshot mx-auto d-block my-4">
                <div class="back-shadow"></div>
                <img class="img-fluid" src="/assets/img/mobile_qa.png">
            </div>
            <h5 class="my-2 mb-4" [translate]="'pages.root.section.qa.title'">Question and Answer</h5>

            <ul class="features">
                <li [translate]="'pages.root.section.qa.action.listen'">Listen to questions
                    from a teacher.</li>
                <li [translate]="'pages.root.section.qa.action.answer'">Answer the questions
                    and record your voice.</li>
                <li [translate]="'pages.root.section.qa.action.compare'">Compare your answers
                    with recordings of
                    native speakers.</li>
                <li [translate]="'pages.root.section.qa.action.improve'">Practise again and
                    again.</li>
            </ul>
            <p><em [translate]="'pages.root.available-for'">available for:</em></p>
            <ul class="checklist">
                <li>General English</li>
                <li>Callan Español</li>
                <li>Callan for Kids</li>
                <li>Callan for Business</li>
            </ul>
        </div>

        <div class="col-sm-4 col-lg-3 h-100">

            <div class="back-shadow-wrapper screenshot mx-auto d-block my-4">
                <div class="back-shadow"></div>
                <img class="img-fluid" src="/assets/img/mobile_dict.png">
            </div>
            <h5 class="my-2 mb-4" [translate]="'pages.root.section.dict.title'">Dictation</h5>
            <ul class="features">
                <li [translate]="'pages.root.section.dict.action.listen'">Listen to teachers and
                    write dictations, just
                    like you do in class.</li>
                <li [translate]="'pages.root.section.dict.action.learn'">See all your mistakes
                    and correct them.</li>
            </ul>
            <p><em [translate]="'pages.root.available-for'">available for:</em></p>
            <ul class="checklist">
                <li>General English</li>
                <li>Callan Español</li>
                <li>Callan for Business</li>
            </ul>
        </div>

        <div class="col-sm-4 col-lg-3 h-100">

            <div class="back-shadow-wrapper screenshot mx-auto d-block my-4">
                <div class="back-shadow"></div>
                <img class="img-fluid" src="/assets/img/mobile_ex.png">
            </div>
            <h5 class="my-2 mb-4" [translate]="'pages.root.section.extra.title'">Extra Practice</h5>

            <ul class="features">
                <li [translate]="'pages.root.section.extra.action.practice'">Practise grammar and
                    vocabulary with quick
                    and fun exercises.</li>
                <li [translate]="'pages.root.section.extra.action.correct'">Do them again –
                    correct your mistakes.</li>
            </ul>
            <p><em [translate]="'pages.root.available-for'">available for:</em></p>
            <ul class="checklist">
                <li>General English</li>
                <li>Callan Español</li>
                <li>Callan for Kids</li>
                <li>Callan for Business</li>
            </ul>
        </div>

    </div>
    <h3 [translate]="'pages.root.app.title'">Download the Callan Method App</h3>
    <div class="row justify-content-center">
        <div class="col-sm-4 col-lg-3 ">
            <div class="back-shadow-wrapper screenshot mx-auto d-block my-4">
                <div class="back-shadow"></div>
                <img class="img-fluid" src="/assets/img/mobile_app.png">
            </div>
        </div>
        <div class="col-sm-8 col-lg-6 pt-4">
            <p [translate]="'pages.root.app.intro'">The Callan Method App gives you the CSPA, your eBooks
                and your audiobooks – all you need, and all in one place!</p>
            <p [translate]="'pages.root.app.platforms'">You can get the app on Android and iOS.</p>
            <p [translate]="'pages.root.app.download'">Download the one you want here:</p>

            <div class="row justify-content-center">
                <div class="col-6 mb-2">
                    <a href="https://itunes.apple.com/gb/app/callan-method-app/id1099785360">
                        <img class="img-fluid shadow-intensive d-block mx-auto" src="/assets/img/appstore.jpg" style="border-radius: 12px;">
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="https://play.google.com/store/apps/details?id=pl.arceo.callan.callandigitalbooks">
                        <img class="img-fluid shadow-intensive d-block mx-auto" src="/assets/img/gplay.jpg" style="border-radius: 12px;">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <h3 class="mt-5" [translate]="'pages.root.questions.title'">Questions? Feedback? Need Help?</h3>
    <div class="text-center my-5 pb-5">
        <a href="mailto: help@callan.co.uk?Subject=Cspa contact us" class="btn btn-lg btn-danger"
           [translate]="'pages.root.questions.contact-us'"
        >Contact us by email</a>
    </div>
    <!-- <div>
        <h3>Questions? Feedback? Need Help?</h3>
        <p>Contact us now:</p>
        <form class="my-5">
            <div class="form-row  justify-content-center">
                <div class="col-sm-6 col-lg-4 mb-3">
                    <input class="form-control" type="text" placeholder="Name">
                </div>
                <div class="col-sm-6 col-lg-4 mb-3">
                    <input class="form-control" type="email" placeholder="Email">
                </div>
            </div>
            <div class="form-row justify-content-center">
                <div class="col-sm-12 col-lg-8  mb-3 ">
                    <input class="form-control" type="text" placeholder="Subject">
                </div>
            </div>
            <div class="form-row justify-content-center">
                <div class="col-sm-12 col-lg-8  mb-3">
                    <textarea class="form-control" placeholder="Message"></textarea>
                </div>
            </div>
            <div class="form-row justify-content-center">
                <div class="col-sm-12 col-lg-8 mb-3 text-right">
                    <button class="btn btn-primary" type="submit">Send Email</button>
                </div>
            </div>
        </form>
    </div> -->

</div>
<app-holding-modal></app-holding-modal>
