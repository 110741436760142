import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Person, AuthorizationProfile, OAuthCode } from '../model/casa';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface CasaRestService {
  loadSelfPerson(): Observable<Person>;
  listProfiles(): Observable<AuthorizationProfile[]>;
  authInPay(): Observable<OAuthCode>;
  authInCol(profile: AuthorizationProfile): Observable<OAuthCode>;
}

@Injectable({
  providedIn: 'root'
})
export class CasaRestServiceMain implements CasaRestService {

  constructor(private http: HttpClient) { }

  public loadSelfPerson(): Observable<Person> {
    return this.http.get<Person>(environment.authEndpoint + '/api/person/self');
  }

  public listProfiles(): Observable<AuthorizationProfile[]> {
    return this.http.get<AuthorizationProfile[]>(environment.authEndpoint + '/api/person/self/authorizationProfile');
  }

  public authInPay(): Observable<OAuthCode> {
    return this.http.post<OAuthCode>(environment.authEndpoint + '/api/people/self/snipcart/generateOAuthCode', {});
  }

  public authInCol(profile: AuthorizationProfile): Observable<OAuthCode> {
    return this.http.post<OAuthCode>(environment.authEndpoint + '/api/people/self/col/generate-oauth-code', profile);
  }
}
