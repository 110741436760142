import { MobileNativeApi } from './MobileNativeApi';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CasaExerciseSession, CasaSessionQuestionDefinition, CasaQuestionScoreSubmit } from 'src/app/model/oldModel';
import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CasaStageBasedMobileApi implements MobileNativeApi {

    constructor(private http: HttpClient) {}
    private prepareUrl(suffix: string) {
        return environment.casaTestingEndpoint + suffix;
    }

    createSession(exerciseId: number): Observable<CasaExerciseSession> {
        return this.http.post<CasaExerciseSession>(this.prepareUrl(`/session/create/${exerciseId}`), {});
    }

    recreateSession(sessionId: number): Observable<CasaExerciseSession> {
        return this.http.post<CasaExerciseSession>(this.prepareUrl(`/session/${sessionId}/recreate`), {});
    }

    getSessionData(sessionId: number): Observable<CasaExerciseSession> {
        return this.http.get<CasaExerciseSession>(this.prepareUrl(`/session/${sessionId}`));
    }

    getSessionQuestionDefinition(sessionId: number, questionId: number): Observable<CasaSessionQuestionDefinition> {
        return this.http.get<CasaSessionQuestionDefinition>(this.prepareUrl(`/session/${sessionId}/question/${questionId}`));
    }

    submitSessionScore(sessionId: number, questionId: number, submit: CasaQuestionScoreSubmit): Observable<boolean> {
        return this.http.post<boolean>(this.prepareUrl(`/session/${sessionId}/question/${questionId}`), submit);
    }

    recalculateScore(): Observable<void> {
        return this.http.patch<void>(this.prepareUrl(`/sef/recalculateScore`), {});
    }
    closeQuestions(): Observable<boolean> {
        return of(true);
    }
}
