import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CspaRestServiceNew {
    constructor(http) {
        this.http = http;
        this.apiEndpoint = environment.apiEndpoint;
    }
    buildPath(path) {
        return this.apiEndpoint + '/api' + path;
    }
    listExerciseSets() {
        return this.http.get(this.buildPath('/sets'));
    }
    questionSessionFinished() {
        // do nothing - does not have any impact
    }
    /** not used in standard context */
    startExerciseSessionById(exerciseId) {
        throw new Error('Method not supported');
    }
    listChapters(path, updatedAfter) {
        let params = new HttpParams();
        if (updatedAfter != null) {
            params = params.append('updatedAfter', updatedAfter.toString());
        }
        return this.http.get(this.buildPath('/sets/' + path), { params });
    }
    listQuestions(pathPrefixes, updatedAfter) {
        let params = new HttpParams();
        if (updatedAfter != null) {
            params = params.append('updatedAfter', updatedAfter.toString());
        }
        for (const pathPrefix of pathPrefixes) {
            params = params.append('pathPreffix', pathPrefix);
        }
        return this.http.get(this.buildPath('/questions'), { params });
    }
    startExerciseSession(exercisePath) {
        return this.http.post(this.buildPath('/sessions/create'), exercisePath);
    }
    getExerciseSession(uuid) {
        return this.http.get(this.buildPath('/sessions/' + uuid));
    }
    recreateExerciseSession(uuid) {
        return this.http.post(this.buildPath('/sessions/' + uuid + '/recreate'), {});
    }
    postSessionQuestionAnswer(uuid, questionNb, sessionQuestion) {
        return this.http.post(this.buildPath('/sessions/' + uuid + '/questions/' + questionNb), sessionQuestion);
    }
    submitSessions(sessions) {
        return this.http.post(this.buildPath('/sessions'), sessions);
    }
    finishSession(uuid) {
        return this.http.post(this.buildPath(`/sessions/${uuid}/finish`), {});
    }
    listAvailabilities(path, depth) {
        const params = new HttpParams().append('path', path).append('depth', depth.toString());
        return this.http.get(this.buildPath('/availability'), { params });
    }
    closeSession(sessionUuid) {
        return this.http.post(this.buildPath(`/sessions/${sessionUuid}/close`), {});
    }
    findSessionById(sessionId) {
        throw new Error('Method not supported');
    }
    isNativeImplementation() {
        return false;
    }
}
CspaRestServiceNew.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CspaRestServiceNew_Factory() { return new CspaRestServiceNew(i0.ɵɵinject(i1.HttpClient)); }, token: CspaRestServiceNew, providedIn: "root" });
