import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { trigger, transition, style, animate, state } from '@angular/animations';

export class PictureCardItem {
  public selected = false;
  public reversed = false;
  constructor(public word: string, public image: string) {}
}

@Component({
  selector: 'app-picture-card',
  templateUrl: './picture-card.component.html',
  styleUrls: ['./picture-card.component.scss'],
  animations: [
    trigger('inverse', [
      state('aversed', style({
        transform: 'rotateY(0deg)'
      })),
      state('inversed', style({
        transform: 'rotateY(-180deg)'
      })),
    ])
  ]
})
export class PictureCardComponent implements OnInit {

  @Input()
  data: PictureCardItem;
  @Output()
  cardClick = new EventEmitter<PictureCardItem>();

  constructor() { }

  isPicture() {
    return this.data && this.data.image;
  }

  isSelected() {
    return this.data && this.data.selected;
  }

  isWord() {
    return this.data && this.data.word;
  }

  getImageSrc() {
    return this.data ? environment.awsBase + this.data.image : null;
  }

  isReversed() {
    return this.data && this.data.reversed;
  }

  getReverseSrc() {
    return '/assets/img/reverse.png';
  }

  getWord() {
    return this.data ? this.data.word : null;
  }

  onClick() {
    this.cardClick.emit(this.data);
  }

  ngOnInit() {
  }

}
