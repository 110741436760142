import { from } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
class TemporaryBridge {
    constructor() {
        this.queue = [];
    }
    log(text) {
        if (environment.debug) {
            console.log(text);
        }
    }
    callHandler(name, params, f) {
        this.log(`ios native - adding the call ${name} to the temporary queue`);
        this.queue.push({ name, params, f });
    }
    registerHandlerHandler(name, f) { }
}
export class IosNativeApi {
    constructor() {
        // queue of callbacks to resolve
        this.bridge = new TemporaryBridge();
        // wait after initialization, then replace old bridge with the new one - execute queued tasks if necesarry
        this.setupWebViewJavascriptBridge(bridge => {
            /*bridge.registerHandlerHandler('footerFinishClicked', (data, callback) => {
                callback();
            });
            bridge.registerHandlerHandler('footerHelpClicked', (data, callback) => {
                callback();
            });*/
            bridge.callHandler('initCspa', {}, () => { });
            this.log('replacing temporary bridge with the one provided. Executing enqueued tasks.');
            for (const call of this.bridge.queue) {
                this.log(`executing ${call.name} enqueued using provided bridge`);
                bridge.callHandler(call.name, call.params, call.f);
            }
            this.bridge = bridge;
        });
    }
    // setup iOS bridge
    setupWebViewJavascriptBridge(callback) {
        // got bridge ready, return it
        if (window.WebViewJavascriptBridge) {
            return callback(window.WebViewJavascriptBridge);
        }
        // callbacks executed during initialization
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }
        // prepare callbacks for initialization
        window.WVJBCallbacks = [callback];
        const WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(_ => { document.documentElement.removeChild(WVJBIframe); }, 0);
    }
    log(text) {
        if (environment.debug) {
            console.log(text);
        }
    }
    // ##### audio implementation ####
    clear() {
        this.log('ios native - calling clear');
        return from(new Promise((resolve, reject) => {
            this.bridge.callHandler('clear', {}, () => {
                resolve(null);
            });
        }));
    }
    record() {
        this.log('ios native - calling record');
        this.bridge.callHandler('record', {}, () => { });
    }
    stop() {
        this.log('ios native - calling stop');
        return from(new Promise((resolve, reject) => {
            this.bridge.callHandler('stop', {}, () => {
                resolve(null);
            });
        }));
    }
    play2() {
        this.log('ios native - calling play');
        this.bridge.callHandler('play', {}, () => { });
    }
    terminate() {
        this.log('ios native - calling stop in terminate');
        this.bridge.callHandler('stop', {}, () => { });
    }
    initAudio(callback) {
        this.log('ios native - initializing audio');
        this.bridge.callHandler('initAudio', {}, audioStatus => {
            this.log('ios native - audio initialized - invoking callback');
            callback(audioStatus);
        });
    }
    // #### api implementation #####
    recreateSession(sessionId) {
        return from(new Promise((resolve, reject) => {
            this.bridge.callHandler('recreateSession', { args: { sessionId } }, res => {
                resolve(JSON.parse(res));
            });
        }));
    }
    getSessionData(sessionId) {
        return from(new Promise((resolve, reject) => {
            this.bridge.callHandler('getSessionData', { args: { sessionId } }, res => {
                resolve(JSON.parse(res));
            });
        }));
    }
    getSessionQuestionDefinition(sessionId, questionId) {
        return from(new Promise((resolve, reject) => {
            this.bridge.callHandler('getSessionQuestionDefinition', { args: { sessionId, questionId } }, res => {
                resolve(JSON.parse(res));
            });
        }));
    }
    submitSessionScore(sessionId, questionId, submit) {
        return from(new Promise((resolve, reject) => {
            this.log(`ios native - submiting session score for session ${sessionId} question ${questionId} with score ${submit.score}`);
            this.bridge.callHandler('submitSessionScore', { args: { sessionId, questionId }, data: submit }, res => {
                resolve(JSON.parse(res));
            });
        }));
    }
    recalculateScore() {
        return from(new Promise((resolve, reject) => {
            this.bridge.callHandler('recalculateScore', {}, res => {
                resolve();
            });
        }));
    }
    closeQuestions() {
        return from(new Promise((resolve, reject) => {
            this.bridge.callHandler('closeQuestions', {}, res => {
                resolve(JSON.parse(res));
            });
        }));
    }
    createSession(exerciseId) {
        this.log(`ios native - create session for exercise ${exerciseId}`);
        const promise = new Promise((resolve, reject) => {
            this.bridge.callHandler('createSession', { args: { exerciseId } }, res => {
                resolve(JSON.parse(res));
            });
        });
        return from(promise);
    }
}
IosNativeApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IosNativeApi_Factory() { return new IosNativeApi(); }, token: IosNativeApi, providedIn: "root" });
