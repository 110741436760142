import {NewMobileNativeApi} from "./MobileNativeApi";
import {CspaRestService} from "../../services/rest/cspa-rest.service";
import {EMPTY, Observable, of} from "rxjs";
import {ExerciseSession, ExerciseSessionQuestion, ItemAvailability} from "../../model/personal";
import {Chapter, ExerciseSet} from "../../model/struct";
import {AnswerDefinitionBase, Question} from "../../model/questions";
import {map, tap} from "rxjs/operators";
import {log} from "util";
import {NgZone} from "@angular/core";
import {LoggerService} from "../../services/logger.service";


export class WebMobileKindEmulatedService implements NewMobileNativeApi {
  storedSessions: ExerciseSession[] = [];
  storedExerciseSets: ExerciseSet[] = null;
  storedChapters: Map<string, Chapter[]> = new Map();
  chaptersUpdateTime: Map<string, number> = new Map();
  storedAvailabilities: Map<string, ItemAvailability[]> = new Map();
  availabilitiesUpdateTime : Map<string, number> = new Map();
  storedQuestions: Map<string, Question<any, any>[]> = new Map();
  questionsUpdateTime: Map<string, number> = new Map();
  private currentSession: ExerciseSession = null;

  sendStoredSessions(): Observable<any> {
    this.log(`web is storing sessions`)
    if (this.storedSessions.length == 0) {
      return EMPTY;
    }
    return this.regularCspaRest.submitSessions(this.storedSessions).pipe(
      tap( _ => this.storedSessions = [])
    )
  }

  listExerciseSets(): Observable<ExerciseSet[]> {
    if (this.storedExerciseSets) return of(this.storedExerciseSets);
    return this.regularCspaRest.listExerciseSets().pipe(
      tap( exerciseSets => this.storedExerciseSets = exerciseSets)
    );
  }

  syncChapters(exerciseSet: string, chaptersSyncFrequency: number): Observable<Chapter[]> {
    const updateTime = this.chaptersUpdateTime[exerciseSet];
    if (updateTime && new Date().getTime() - updateTime < chaptersSyncFrequency) return EMPTY;
    this.log(`syncing chapters for ${exerciseSet}`);
    return this.regularCspaRest.listChapters(exerciseSet).pipe(
      tap( chapters => {
        this.storedChapters.set(exerciseSet, chapters);
        this.chaptersUpdateTime.set(exerciseSet, new Date().getTime());
      })
    );
  }

  syncAvailabilities(exerciseSet: string, availabilityFrequencyMs: number): Observable<any> {
    const updateTime = this.availabilitiesUpdateTime[exerciseSet];
    if (updateTime && new Date().getTime() - updateTime < availabilityFrequencyMs) return EMPTY;
    this.log(`syncing availabilities for ${exerciseSet}`);
    return this.regularCspaRest.listAvailabilities(exerciseSet, 3).pipe(
      tap( availability => {
        this.storedAvailabilities.set(exerciseSet, availability);
        this.availabilitiesUpdateTime.set(exerciseSet, new Date().getTime());
      })
    );
  }

  syncQuestions(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    const updateTime = this.questionsUpdateTime[exerciseSet];
    if (updateTime && new Date().getTime() - updateTime < syncFrequencyMs) return EMPTY;
    this.log(`syncing questions for ${exerciseSet}`);
    return this.regularCspaRest.listQuestions([exerciseSet]).pipe(
      tap( questions => {
        this.storedQuestions.set(exerciseSet, questions);
        this.questionsUpdateTime.set(exerciseSet, new Date().getTime());
      })
    );
  }

  getChapters(exerciseSet: string): Observable<Chapter[]> {
    return of(this.storedChapters.get(exerciseSet));
  }

  getAvailabilities(exerciseSet: string): Observable<ItemAvailability[]> {
    return of(this.storedAvailabilities.get(exerciseSet));
  }

  getQuestions(exerciseSet: string): Observable<Question<any, any>[]> {
    return of(this.storedQuestions.get(exerciseSet));
  }

  storeCurrentSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.currentSession = session;
    return of(session);
  }

  getCurrentSession(): Observable<ExerciseSession> {
    return of(this.currentSession);
  }

  pushSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.storedSessions.push(session);
    return of(session);
  }

  storeAvailability(exerciseSet: string, availability: ItemAvailability[]): Observable<ItemAvailability[]> {
    this.storedAvailabilities.set(exerciseSet, availability);
    return of(availability);
  }

  constructor(private regularCspaRest: CspaRestService, private logger: LoggerService) {
  }

  private log(text: string) {
    this.logger.log(`[NATIVE_BY_EWB] ${text}`);
  }

  close(): void {
    this.log('DO CLOSE');
  }
}
