import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CasaRestServiceMain {
    constructor(http) {
        this.http = http;
    }
    loadSelfPerson() {
        return this.http.get(environment.authEndpoint + '/api/person/self');
    }
    listProfiles() {
        return this.http.get(environment.authEndpoint + '/api/person/self/authorizationProfile');
    }
    authInPay() {
        return this.http.post(environment.authEndpoint + '/api/people/self/snipcart/generateOAuthCode', {});
    }
    authInCol(profile) {
        return this.http.post(environment.authEndpoint + '/api/people/self/col/generate-oauth-code', profile);
    }
}
CasaRestServiceMain.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CasaRestServiceMain_Factory() { return new CasaRestServiceMain(i0.ɵɵinject(i1.HttpClient)); }, token: CasaRestServiceMain, providedIn: "root" });
