import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
export class LoggerService {
    log(toLog) {
        if (!environment.debug)
            return;
        console.log(toLog);
    }
}
LoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
