  <div *ngIf="isReady()">
    <div  class="mt-3" *ngFor="let section of selectedChapterSections()">
        <p class="lead">
            <span *ngIf="section.type == 'QA'" [translate]="'component.chapter-details.qa.title'">Question & Answer</span>
            <span *ngIf="section.type == 'DICT'" [translate]="'component.chapter-details.dict.title'">Dictation</span>
            <span *ngIf="section.type == 'LANG'" [translate]="'component.chapter-details.extra.title'">Extra Practice</span>
        </p>
        <p>
            <span *ngIf="section.type == 'QA'" [translate]="'component.chapter-details.qa.desc'">Listen to questions, say your answers, record and compare with native speakers.</span>
            <span *ngIf="section.type == 'DICT'" [translate]="'component.chapter-details.dict.desc'">Listen and write. See your mistakes and correct them.</span>
            <span *ngIf="section.type == 'LANG'" [translate]="'component.chapter-details.extra.desc'">Practise the grammar and vocabulary you’ve learned with these quick and fun exercises.</span>
        </p>
        <div class="my-3">

            <app-exercise-box
                *ngFor="let exercise of getSectionExercises(section)"
                [availability]="getExerciseAvailability(section, exercise)"
                [exercise]="exercise"
                (exerciseClick)="onExerciseSelected(section, exercise)" ></app-exercise-box>
        </div>
    </div>
</div>
