/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entry-point-exercise-set.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./entry-point-exercise-set.component";
import * as i5 from "../../services/rest-provider.service";
import * as i6 from "@angular/router";
import * as i7 from "../../services/logger.service";
import * as i8 from "../../services/translation.service";
var styles_EntryPointExerciseSetComponent = [i0.styles];
var RenderType_EntryPointExerciseSetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EntryPointExerciseSetComponent, data: {} });
export { RenderType_EntryPointExerciseSetComponent as RenderType_EntryPointExerciseSetComponent };
function View_EntryPointExerciseSetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "text-center my-2"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Loading, please wait..."]))], function (_ck, _v) { var currVal_0 = "pages.entry.wait"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EntryPointExerciseSetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "text-danger text-center"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Something went wrong. Please check the internet connection."]))], function (_ck, _v) { var currVal_0 = "pages.entry.error"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EntryPointExerciseSetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntryPointExerciseSetComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntryPointExerciseSetComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isError(); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EntryPointExerciseSetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-entry-point-exercise-set", [], null, null, null, View_EntryPointExerciseSetComponent_0, RenderType_EntryPointExerciseSetComponent)), i1.ɵdid(1, 114688, null, 0, i4.EntryPointExerciseSetComponent, [i5.ApiServiceProvider, i6.ActivatedRoute, i6.Router, i7.LoggerService, i8.TranslationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntryPointExerciseSetComponentNgFactory = i1.ɵccf("app-entry-point-exercise-set", i4.EntryPointExerciseSetComponent, View_EntryPointExerciseSetComponent_Host_0, {}, {}, []);
export { EntryPointExerciseSetComponentNgFactory as EntryPointExerciseSetComponentNgFactory };
