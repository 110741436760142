import { Component, OnInit } from '@angular/core';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { zip } from 'rxjs';
import { ExerciseSet } from 'src/app/model/struct';
import { ItemAvailability } from 'src/app/model/personal';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';


@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss']
})
export class ExercisesComponent implements OnInit {
  availabilities: Map<string, ItemAvailability>;
  exercises: Map<string, ExerciseSet>;
  faCoffee = 'faCoffee';
  private rest: CspaRestService;


  constructor(restProvider: ApiServiceProvider) {
    this.rest = restProvider.getRestServiceImplementation();
  }

  public identityOfSet(index: number, item: ItemAvailability) {
    return item.path;
  }

  loadData() {
    zip(
      this.rest.listExerciseSets(),
      this.rest.listAvailabilities('', 1)
    ).subscribe( ([sets, availabilties]) => this.setupAvailabilites(sets, availabilties));
  }

  listSets() {
    if (!this.availabilities || !this.exercises) {
      return [];
    }
    return Array.from( this.availabilities.values() )
      .filter ( av => av.assigned && av.available)
      .map( av => [av, this.exercises.get(av.path)])
      .filter( ([_, ex]) => ex != null)
      .sort( ([lav, lex], [rav, rex]) => lex.path.localeCompare(rex.path));
  }


  setupAvailabilites(exSets: ExerciseSet[], availabilties: ItemAvailability[]): void {
    this.availabilities = new Map(availabilties.map( a => [a.path, a]));
    this.exercises = new Map(exSets.map( exSet => [exSet.path, exSet]));
  }

  ngOnInit() {
    this.loadData();
  }

}
