import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { QuestionComponentBase, AnswerWrapper } from '../question-component-base';
import { Dictation, DictationAnswer } from 'src/app/model/questions';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../modal/modal.component';
import { TextCompare, CompareStream, CompareNode, TextBlock, BlockComparator } from 'src/app/utils/text-comparing';



@Component({
  selector: 'app-dictation',
  templateUrl: './dictation.component.html',
  styleUrls: ['./dictation.component.scss']
})
export class DictationComponent extends QuestionComponentBase<DictationAnswer, Dictation> implements OnInit {
  answer: DictationAnswer;
  responseWaitingObserver: Observer<AnswerWrapper<DictationAnswer>>;
  @ViewChild('summaryModal', {static: true}) summaryModal: ModalComponent;
  summary: TextBlock[];
  score: number;

  getAnswerForSubmit(): Observable<AnswerWrapper<DictationAnswer>> {
    return new Observable( observer => {
      this.responseWaitingObserver = observer;
      this.openDictationDialog();
      return {unsubscribe() {}};
    });
  }

  getScore() {
    return this.score;
  }

  backToCorrection() {
    this.closeHostContract();
  }

  closeHostContract() {
    if (this.responseWaitingObserver) {
      this.responseWaitingObserver.complete();
      this.responseWaitingObserver = null;
    }
    this.summaryModal.hide();
  }

  submitAnswer() {
    if (!this.responseWaitingObserver) {
      return;
    }
    this.responseWaitingObserver.next(new AnswerWrapper(this.answer));
  }

  openDictationDialog() {
    this.prepareSummaryReport();
    this.summaryModal.show();
  }

  prepareSummaryReport() {
    const correctAnswerStream = BlockComparator.tokenize(this.question.question.definition.answer.answer);
    const userAnswer = BlockComparator.tokenize(this.answer.answer);
    const result = BlockComparator.compare(correctAnswerStream, userAnswer);
    this.score = Math.round((1 - (result[result.length - 1].score / correctAnswerStream.content.length)) * 100);
    const blocks = BlockComparator.divideToBlocks(result, userAnswer);
    this.summary = BlockComparator.compressBlocks(blocks);
  }

  getMedia(suffix: string) {
    if (!this.question) {
      return null;
    }
    return environment.awsBase + this.question.question.definition.src + suffix;
  }

  setupAnswer(answer: DictationAnswer) {
    this.answer = answer ? answer : new DictationAnswer();
  }

  getVideoSource() {
    return this.getMedia('.mp4');
  }

  getAudioSource() {
    return this.getMedia('.mp3');
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.summaryModal.cancelSubject.subscribe(_ => {
      this.backToCorrection();
    });
  }
}
