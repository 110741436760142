<h5 class="mt-2" [translate]="'component.questions.correction.intro'">Click the word to make a correction</h5>
<div class="d-none d-md-block w-100" style="height: 100px;"></div>
<div *ngIf="!hasImage()" class="d-md-none w-100" style="height: 100px;"></div>
<div class="row">
    <div *ngIf="hasImage()" class="col-12 col-md-4">
        <div class="card question-image p-2">
        <img [src]="getImageSrc()" class="card-img-top">
        </div>
    </div>
    <div class="col">
        <div class="d-none d-md-block w-100" style="height: 50px;"></div>
        <div class="text-center display-5" >
            <div class="prev">
                <div *ngIf="isDifferent()">
                    <span *ngFor="let token of getChangesString()" [ngClass]="{incorrect: isTokenIncorrect(token)}">{{token.content}}</span>
                </div>
            </div>

            <textarea   autofocus #userInput
            autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
            class="answer"
            [(ngModel)]="content" (ngModelChange)="answerChanged($event)"
            style="resize: none;"
            rows="5"></textarea>
        </div>
    </div>
</div>
