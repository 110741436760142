import { Injector } from '@angular/core';
import { CspaRestServiceNew } from './rest/cspa-rest.service';
import { MobileNativeMockService } from '../mobile/services/MobileNativeMockService';
import { MobileToNewApiBridge } from '../mobile/services/MobileToNewApiBridge';
import { CasaStageBasedMobileApi } from '../mobile/services/CasaStageBasedMobileNativeApiImpl';
import { environment } from 'src/environments/environment';
import { SupportedPlatforms } from 'src/environments/platforms';
import { MobileNativeAudioRecorderAdapter } from '../mobile/services/MobileNativeApi';
import { AndroidNativeApi } from '../mobile/services/android/AndroidNativeApi';
import { IosNativeApi } from '../mobile/services/ios/IosNativeApi';
import { Observable, of, empty, from } from 'rxjs';
import { EmptyRecorder } from './Recorder';
import { NewMobileBridgeService } from "../mobile/services/new-mobile-bridge.service";
import { WebMobileKindEmulatedService } from "../mobile/services/web-mobile-kind-emulated.service";
import { IosNewNativeApi } from "../mobile/services/ios/ios-new-native-api";
import { AndroidRequestBasedNativeApi } from "../mobile/services/android/AndroidRequestBasedNativeApi";
import * as i0 from "@angular/core";
import * as i1 from "ngx-store";
import * as i2 from "./logger.service";
export class ApiServiceProvider {
    constructor(localStorage, injector, logger) {
        this.localStorage = localStorage;
        this.injector = injector;
        this.logger = logger;
    }
    getAlternativeAudioApi() {
        if (environment.platform === SupportedPlatforms.androidLegacy) {
            return this.getAndroidNativeAudio();
        }
        else if (environment.platform === SupportedPlatforms.iOsLegacy) {
            return this.getIosNativeAudio();
        }
        else if (environment.platform === SupportedPlatforms.ios) {
            return this.getIosNewNativeAudio();
        }
        else if (environment.platform === SupportedPlatforms.android) {
            return this.getAndroidNewNativeAudio();
        }
        return empty();
    }
    log(text) {
        if (environment.debug) {
            console.log(text);
        }
    }
    getIosNativeAudio() {
        if (this.iOsNativeAudio) {
            this.log('api provider - returning cached ios recorder instance');
            return of(this.iOsNativeAudio);
        }
        return from(new Promise((resolve, reject) => {
            this.getIosNative().initAudio(state => {
                this.log('api provider - got ios audio with state:');
                this.log(state);
                if (state) {
                    this.log('api provider - returning ios native audio recorder to the observable');
                    this.iOsNativeAudio = new MobileNativeAudioRecorderAdapter(this.getIosNative());
                    resolve(this.iOsNativeAudio);
                }
                else {
                    this.log('api provider - didn\'t found the ios audio recorder, closing obervable with empty result');
                    resolve(new EmptyRecorder());
                }
            });
        }));
    }
    getIosNewNativeAudio() {
        if (this.iosNewNativeAudio) {
            return of(this.iosNewNativeAudio);
        }
        return new Observable(observer => {
            this.getIosNewNativeApi().initAudio(state => {
                if (state) {
                    this.log('api provider - returning ios new audio recorder');
                    this.iosNewNativeAudio = new MobileNativeAudioRecorderAdapter(this.getIosNewNativeApi());
                    observer.next(this.iosNewNativeAudio);
                    observer.complete();
                }
                else {
                    this.log('api provider - didn\'t found the ios audio recorder, closing obervable with empty result');
                    observer.next(new EmptyRecorder());
                    observer.complete();
                }
            });
        });
    }
    getNewAndroidBridge() {
        if (!this.androidNewNativeBridge) {
            this.androidNewNativeBridge = new NewMobileBridgeService(this.getAndroidNewNativeApi(), this.logger);
        }
        return this.androidNewNativeBridge;
    }
    getAndroidNewNativeApi() {
        if (!this.androidNewNativeApi) {
            this.androidNewNativeApi = this.injector.get(AndroidRequestBasedNativeApi);
        }
        return this.androidNewNativeApi;
    }
    getAndroidNewNativeAudio() {
        if (this.androidNewNativeAudio) {
            return of(this.androidNewNativeAudio);
        }
        return new Observable(observer => {
            this.getAndroidNewNativeApi().initAudio(state => {
                if (state) {
                    this.log('api provider - returning ios new audio recorder');
                    this.androidNewNativeAudio = new MobileNativeAudioRecorderAdapter(this.getAndroidNewNativeApi());
                    observer.next(this.androidNewNativeAudio);
                    observer.complete();
                }
                else {
                    this.log('api provider - didn\'t found the ios audio recorder, closing obervable with empty result');
                    observer.next(new EmptyRecorder());
                    observer.complete();
                }
            });
        });
    }
    getAndroidNativeAudio() {
        if (this.androidNativeAudio) {
            return of(this.androidNativeAudio);
        }
        return new Observable(observer => {
            this.getAndroidNative().initAudio(state => {
                if (state) {
                    this.androidNativeAudio = new MobileNativeAudioRecorderAdapter(this.getAndroidNative());
                    observer.next(this.androidNativeAudio);
                    observer.complete();
                }
                else {
                    observer.complete();
                }
            });
            return { unsubscribe: () => { } };
        });
    }
    getRestServiceImplementation() {
        if (environment.platform === SupportedPlatforms.androidLegacy) {
            return this.getAndroidNativeBridge();
        }
        else if (environment.platform === SupportedPlatforms.iOsLegacy) {
            return this.getIosNativeBridge();
        }
        else if (environment.platform === SupportedPlatforms.mock) {
            return this.getMockNativeBridge();
        }
        else if (environment.platform === SupportedPlatforms.web) {
            return this.getRegularCspaRes();
        }
        else if (environment.platform === SupportedPlatforms.webCasa) {
            return this.getCasaBasedBridge();
        }
        else if (environment.platform === SupportedPlatforms.webMobileEmulated) {
            return this.getWebMobileEmulatedBridge();
        }
        else if (environment.platform === SupportedPlatforms.ios) {
            return this.getNewIosBridge();
        }
        else if (environment.platform === SupportedPlatforms.android) {
            return this.getNewAndroidBridge();
        }
        throw new Error(`unsupported platform ${environment.platform}`);
    }
    getRegularCspaRes() {
        if (!this.regularCspaRest) {
            this.regularCspaRest = this.injector.get(CspaRestServiceNew);
        }
        return this.regularCspaRest;
    }
    getCasaNative() {
        if (!this.casaNative) {
            this.casaNative = this.injector.get(CasaStageBasedMobileApi);
        }
        return this.casaNative;
    }
    getAndroidNative() {
        if (!this.androidNative) {
            this.androidNative = this.injector.get(AndroidNativeApi);
        }
        return this.androidNative;
    }
    getIosNative() {
        if (!this.iOSNative) {
            this.iOSNative = this.injector.get(IosNativeApi);
        }
        return this.iOSNative;
    }
    getMockNative() {
        if (!this.mockNative) {
            this.mockNative = this.injector.get(MobileNativeMockService);
        }
        return this.mockNative;
    }
    getAndroidNativeBridge() {
        if (!this.androidNativeBridge) {
            this.androidNativeBridge = new MobileToNewApiBridge(this.getAndroidNative(), this.localStorage, null);
        }
        return this.androidNativeBridge;
    }
    getIosNativeBridge() {
        if (!this.iOSNativeBridge) {
            this.iOSNativeBridge = new MobileToNewApiBridge(this.getIosNative(), this.localStorage, null);
        }
        return this.iOSNativeBridge;
    }
    getMockNativeBridge() {
        if (!this.mockNativeBridge) {
            this.mockNativeBridge = new MobileToNewApiBridge(this.getMockNative(), this.localStorage, this.getRegularCspaRes());
        }
        return this.mockNativeBridge;
    }
    getCasaBasedBridge() {
        if (!this.casaBasedBridge) {
            this.casaBasedBridge = new MobileToNewApiBridge(this.getCasaNative(), this.localStorage, this.getRegularCspaRes());
        }
        return this.casaBasedBridge;
    }
    getWebMobileEmulatedBridge() {
        if (this.webMobileEmulated)
            return this.webMobileEmulated;
        this.webMobileEmulated = new NewMobileBridgeService(new WebMobileKindEmulatedService(this.getRegularCspaRes(), this.logger), this.logger);
        return this.webMobileEmulated;
    }
    getMobileRestServiceImplementation() {
        return this.getRestServiceImplementation();
    }
    getNewIosBridge() {
        if (!this.iosNativeBridge) {
            this.iosNativeBridge = new NewMobileBridgeService(this.getIosNewNativeApi(), this.logger);
        }
        return this.iosNativeBridge;
    }
    getIosNewNativeApi() {
        if (!this.iosNewNativeApi) {
            this.iosNewNativeApi = this.injector.get(IosNewNativeApi);
        }
        return this.iosNewNativeApi;
    }
}
ApiServiceProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiServiceProvider_Factory() { return new ApiServiceProvider(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i2.LoggerService)); }, token: ApiServiceProvider, providedIn: "root" });
