import { Injectable, Injector, Type } from '@angular/core';
import { QuestionComponentBase } from '../components/questions/question-component-base';
import { QuestionAnswerComponent } from '../components/questions/question-answer/question-answer.component';
import { ExerciseSessionQuestion } from '../model/personal';
import { Qa, AnswerDefinitionBase, QuestionDefinitionBase, QaAnswer, DictationAnswer,
  Dictation, FillMultiAnswer, FillMulti, CorrectionAnswer, Correction, JumbleAnswer,
  Jumble, ImagesAnswer, Images } from '../model/questions';
import { DictationComponent } from '../components/questions/dictation/dictation.component';
import { AppBaseException } from '../errors';
import { FillMultiComponent } from '../components/questions/fill-multi/fill-multi.component';
import { CorrectionComponent } from '../components/questions/correction/correction.component';
import { JumbleComponent } from '../components/questions/jumble/jumble.component';
import { PicturesComponent } from '../components/questions/pictures/pictures.component';

class CD<A extends AnswerDefinitionBase, Q extends QuestionDefinitionBase<A>> {
  constructor(public answerType: Type<A>,
              public questionType: Type<Q>,
              public component: Type<QuestionComponentBase<A, Q>>,
              public questionTypeMeta: string) {}
}

@Injectable({
  providedIn: 'root'
})
export class QuestionComponentProviderService {
  questionsComponents = [
    new CD(QaAnswer, Qa, QuestionAnswerComponent, 'qa'),
    new CD(DictationAnswer, Dictation, DictationComponent, 'dict'),
    new CD(FillMultiAnswer, FillMulti, FillMultiComponent, 'ef'),
    new CD(CorrectionAnswer, Correction, CorrectionComponent, 'cor'),
    new CD(JumbleAnswer, Jumble, JumbleComponent, 'jmb'),
    new CD(ImagesAnswer, Images, PicturesComponent, 'pct')
  ];
  // questionsComponents: QuestionComponentBase<any, any>[] = [QuestionAnswerComponent];

  public recognizeComponent(sessionQuestion: ExerciseSessionQuestion<any, any>): Type<any> {
    for (const questionComponent of this.questionsComponents) {
      if ( (sessionQuestion.question.definition instanceof questionComponent.questionType)
          ||  sessionQuestion.question.definition['@type'] === questionComponent.questionTypeMeta) {
        return questionComponent.component;
      }
    }
    throw new AppBaseException('didn\'t found a correct component for the session question');
  }

  constructor() {
  }
}
