<div style="padding-top: 4rem;">
    <div class="container">
        <div class="row justify-content-center">
            <div *ngFor="let item of listSets(); trackBy:identityOfSet " class="col-12 col-sm-6 col-md-4 mb-4">
                <app-exercise-set-details [availability]="item[0]" [definition]="item[1]"></app-exercise-set-details>
            </div>
        </div>
    </div>
</div>

