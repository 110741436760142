import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { QuestionComponentBase, AnswerWrapper } from '../question-component-base';
import { CorrectionAnswer, Correction } from 'src/app/model/questions';
import { Observable, of, Subscription, Subject } from 'rxjs';
import { StringTokenizer, BlockComparator, CompareStream, TextBlock, CompareNode } from 'src/app/utils/text-comparing';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-correction',
  templateUrl: './correction.component.html',
  styleUrls: ['./correction.component.scss']
})
export class CorrectionComponent extends QuestionComponentBase<CorrectionAnswer, Correction> implements OnInit, OnDestroy {

  @ViewChild('userInput', {static: true}) inputRef: ElementRef;

  questionSubscription: Subscription;
  content: string;
  answer: CorrectionAnswer;
  originalStream: CompareStream;
  correctStream: CompareStream;
  answerStream: CompareStream;
  answerValueSubject = new Subject<string>();
  changesString: TextBlock[] = null;
  correctCompareResult: CompareNode[];

  getAnswerForSubmit(): Observable<AnswerWrapper<CorrectionAnswer>> {
    this.applyContentOnAnswer();
    return of(new AnswerWrapper(this.answer));
  }

  applyContentOnAnswer() {
    if (!this.answer) {
      return;
    }
    this.answer.correct = this.content;
  }

  setupAnswer(answer: CorrectionAnswer) {
    if (answer == null) {
      this.answer = new CorrectionAnswer();
    } else {
      this.answer = answer;
      this.applyAnswerOnContent();
    }
  }

  applyAnswerOnContent() {
    if (!this.answer || !this.answer.correct) {
      this.answerValueSubject.next(this.content);
      return;
    }
    this.content = this.answer.correct;
    this.answerValueSubject.next(this.content);
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.type === 'keydown' && event.key === 'Enter') {
      this.doFinish();
    }
  }

  getMiddleDescription() {
    if (this.correctCompareResult && this.correctCompareResult[this.correctCompareResult.length - 1].score > 0) {
      return 'help';
    } else {
      return null;
    }
  }

  hasImage() {
    if (!this.question) {
      return false;
    }
    return this.question.question.definition.img != null;
  }

  getImageSrc() {
    return  environment.awsBase + this.question.question.definition.img;
  }

  onMiddleClick() {
    const blocks = BlockComparator.compressBlocks(BlockComparator.divideToBlocks(this.correctCompareResult, this.answerStream));
    const toEdit = blocks.find( b => b.kind !== TextBlock.CORRECT && b.kind !== TextBlock.MISSING);
    if (!toEdit) {
      return;
    }
    const rangeStart = this.content.indexOf(toEdit.content);
    if (rangeStart < 0) {
      return;
    }
    const rangeEnd = rangeStart + toEdit.content.length;

    const input = this.inputRef.nativeElement;
    // input.selet();
    input.focus();
    input.setSelectionRange(rangeStart, rangeEnd);

  }

  constructor() {
    super();
    this.answerValueSubject.pipe(
      debounceTime(300)
    ).subscribe( currentValue => {
      this.answerStream = BlockComparator.tokenize(currentValue);
      this.correctCompareResult = BlockComparator.compare(this.correctStream, this.answerStream);
      const result = BlockComparator.compare(BlockComparator.tokenize(currentValue), this.originalStream);
      if (result[result.length - 1].score === 0) {
        this.changesString = null;
        return;
      }
      const compressedBlocks = BlockComparator.compressBlocks(BlockComparator.divideToBlocks(result, this.originalStream));
      this.changesString = compressedBlocks
        .filter( b => b.kind !== TextBlock.MISSING);
      this.changesString.filter( b => b.kind === TextBlock.CORRECT).forEach( b => b.content = '...');
    });
  }

  isTokenIncorrect(token: TextBlock) {
    return token.kind !== TextBlock.CORRECT;
  }

  isDifferent() {
    return this.changesString != null;
  }

  getChangesString() {
    return this.changesString;
  }

  ngOnInit() {
    this.questionSubscription = this.questionSubject.subscribe(_ => this.prepareQuestionData());
  }

  prepareQuestionData(): void {
    this.content = this.question.question.definition.incorrect;
    this.originalStream = BlockComparator.tokenize(this.content);
    this.correctStream = BlockComparator.tokenize(this.question.question.definition.answer.correct);
    this.applyAnswerOnContent();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.questionSubscription) {
      this.questionSubscription.unsubscribe();
      this.questionSubscription = null;
    }
  }

  answerChanged(current: string) {
    this.answerValueSubject.next(current);
  }
}
