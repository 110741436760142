export class SupportedPlatforms {
    /* supported platforms:
    - web - regular web platform - will use regular rest service based on the new cspa api
    - webCasa -  old way web platform - will mix old casa api with the new - exerciseSets and scoring will be displayed from the new api
                  doing the exercise will be using casa-dev
    - android - use android api only
    - iOS - use iOS only
    - mock - memory based mock
  */
    public static web = 'web';
    public static webCasa = 'web-casa';
    public static androidLegacy = 'android-legacy';
    public static iOsLegacy = 'iOS-legacy';
    public static ios = 'iOS';
    public static mock = 'mock';
    public static webMobileEmulated = "web-mobile-emulate";
    public static android = 'android';
}
