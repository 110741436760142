/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./start-exercise.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./start-exercise.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/rest-provider.service";
var styles_StartExerciseComponent = [i0.styles];
var RenderType_StartExerciseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartExerciseComponent, data: {} });
export { RenderType_StartExerciseComponent as RenderType_StartExerciseComponent };
export function View_StartExerciseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "my-5 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "lead"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Starting the exercise "]))], function (_ck, _v) { var currVal_0 = "pages.starting-exercise.in-progress"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StartExerciseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-start-exercise", [], null, null, null, View_StartExerciseComponent_0, RenderType_StartExerciseComponent)), i1.ɵdid(1, 114688, null, 0, i3.StartExerciseComponent, [i4.ActivatedRoute, i5.ApiServiceProvider, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartExerciseComponentNgFactory = i1.ɵccf("app-start-exercise", i3.StartExerciseComponent, View_StartExerciseComponent_Host_0, {}, {}, []);
export { StartExerciseComponentNgFactory as StartExerciseComponentNgFactory };
