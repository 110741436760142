/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./picture-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../directives/kids-rotation.directive";
import * as i3 from "../../../../services/theme.service";
import * as i4 from "@angular/common";
import * as i5 from "./picture-card.component";
var styles_PictureCardComponent = [i0.styles];
var RenderType_PictureCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PictureCardComponent, data: { "animation": [{ type: 7, name: "inverse", definitions: [{ type: 0, name: "aversed", styles: { type: 6, styles: { transform: "rotateY(0deg)" }, offset: null }, options: undefined }, { type: 0, name: "inversed", styles: { type: 6, styles: { transform: "rotateY(-180deg)" }, offset: null }, options: undefined }], options: {} }] } });
export { RenderType_PictureCardComponent as RenderType_PictureCardComponent };
function View_PictureCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "picture-card-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImageSrc(); _ck(_v, 0, 0, currVal_0); }); }
function View_PictureCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "picture-card-word"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getWord(); _ck(_v, 1, 0, currVal_0); }); }
export function View_PictureCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["appKidsRotation", "5"], ["style", "position: relative;"]], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "card picture-card"], ["style", "\nbackface-visibility: hidden;\n-webkit-backface-visibility: hidden;"]], [[24, "@inverse", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { selected: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "picture-card-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PictureCardComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PictureCardComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "card picture-card"], ["style", "\nposition: absolute;\nleft: 0;\nright: 0;\ntop: 0;\nbottom: 0;\nbackface-visibility: hidden;\n-webkit-backface-visibility: hidden;"]], [[24, "@inverse", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "picture-card-content"], ["style", "left: 0; right: 0; top: 0; bottom: 0; padding: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "picture-card-img"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "5"; _ck(_v, 1, 0, currVal_0); var currVal_2 = "card picture-card"; var currVal_3 = _ck(_v, 5, 0, _co.isSelected()); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.isPicture(); _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.isWord(); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isReversed() ? "inversed" : "aversed"); _ck(_v, 2, 0, currVal_1); var currVal_6 = (!_co.isReversed() ? "inversed" : "aversed"); _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.getReverseSrc(); _ck(_v, 13, 0, currVal_7); }); }
export function View_PictureCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-picture-card", [], null, null, null, View_PictureCardComponent_0, RenderType_PictureCardComponent)), i1.ɵdid(1, 114688, null, 0, i5.PictureCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PictureCardComponentNgFactory = i1.ɵccf("app-picture-card", i5.PictureCardComponent, View_PictureCardComponent_Host_0, { data: "data" }, { cardClick: "cardClick" }, []);
export { PictureCardComponentNgFactory as PictureCardComponentNgFactory };
