import { Observable, Subject } from 'rxjs';
import { CallbackRequest } from "../js-bridge-task-queue";
import * as i0 from "@angular/core";
import * as i1 from "../js-bridge-task-queue";
import * as i2 from "../../../services/logger.service";
class TemporaryBridge {
    constructor() {
        this.queue = [];
    }
    log(text) {
        if (true) {
            console.log(text);
        }
    }
    callHandler(name, params, f) {
        this.log(`ios native - adding the call ${name} to the temporary queue`);
        this.queue.push({ name, params, f });
    }
    registerHandlerHandler(name, f) { }
}
export class IosNewNativeApi {
    constructor(taskQueue, logger) {
        this.taskQueue = taskQueue;
        this.logger = logger;
        // queue of callbacks to resolve
        this.bridge = new TemporaryBridge();
        this.updateEventsSubject = new Subject();
        this.refreshingSubject = new Subject();
        this.id = 0;
        window.bridgeHandler = this;
        // wait after initialization, then replace old bridge with the new one - execute queued tasks if necesarry
        this.setupWebViewJavascriptBridge(bridge => {
            bridge.callHandler('init', {}, () => { });
            this.log('replacing temporary bridge with the one provided. Executing enqueued tasks.');
            for (const call of this.bridge.queue) {
                this.log(`executing ${call.name} enqueued using provided bridge`);
                bridge.callHandler(call.name, call.params, call.f);
            }
            this.bridge = bridge;
        });
    }
    // setup iOS bridge
    setupWebViewJavascriptBridge(callback) {
        // got bridge ready, return it
        if (window.WebViewJavascriptBridge) {
            return callback(window.WebViewJavascriptBridge);
        }
        // callbacks executed during initialization
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }
        // prepare callbacks for initialization
        window.WVJBCallbacks = [callback];
        const WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(_ => { document.documentElement.removeChild(WVJBIframe); }, 0);
    }
    callForResponse(name, args) {
        return Observable.create(observer => {
            const req = new CallbackRequest();
            req.args = args;
            req.id = this.id++;
            this.taskQueue.registerPendingRequest(req.id, observer);
            this.bridge.callHandler(name, req, res => {
                // do nothing, response will be handled by calling back from ios to javascript
            });
        });
    }
    notifyDataUpdate() {
        this.logger.log('notifying data update');
        this.updateEventsSubject.next();
    }
    handlePendingRequest(data) {
        this.taskQueue.handlePendingRequest(data);
    }
    listenForDataUpdates() {
        return this.updateEventsSubject;
    }
    log(text) {
        if (true) {
            this.logger.log(`[Ios-api2] - ${text}`);
        }
    }
    // #### audio implementation #####
    /*
    this is the same implementation of audio actions as it was in old cspa
     */
    /**
     * clear recording buffer
     */
    clear() {
        this.log("calling clear");
        return this.callForResponse('clear', {});
    }
    /**
     * start recording
     */
    record() {
        this.log("calling record");
        return this.callForResponse('record', {}).subscribe();
    }
    /**
     * stop recording or playback
     */
    stop() {
        this.log("calling stop");
        return this.callForResponse('stop', {});
    }
    /**
     * start recording playback
     */
    play2() {
        this.log("calling play");
        return this.callForResponse('play', {}).subscribe();
    }
    /**
     * clean up and release recording device
     */
    terminate() {
        this.log("calling terminate");
        return this.callForResponse('terminate', {}).subscribe();
    }
    /**
     * initialize recording device, returns true if positive
     * @param callback
     */
    initAudio(callback) {
        this.log(" calling clear");
        return this.callForResponse('initAudio', {}).subscribe(it => callback(it));
    }
    // #### data management implementation #####
    /**
     * read the availability JSON file for exerciseSet
     * @param exerciseSet
     */
    getAvailabilities(exerciseSet) {
        this.log(`getting availabilities for ${exerciseSet}`);
        return this.callForResponse('readAvailabilities', { exerciseSet });
    }
    /**
     * read chapters JSON file for exerciseSet
     * @param exerciseSet
     */
    getChapters(exerciseSet) {
        this.log(`getting chapters for ${exerciseSet}`);
        return this.callForResponse('readChapters', { exerciseSet });
    }
    /**
     * read current session JSON file
     */
    getCurrentSession() {
        this.log(`getting current session`);
        return this.callForResponse('readCurrentSession', {});
    }
    /**
     * read questions file for exerciseSet
     * @param exerciseSet
     */
    getQuestions(exerciseSet) {
        this.log(`getting questions for ${exerciseSet}`);
        return this.callForResponse('readQuestions', { exerciseSet });
    }
    /**
     * read exercise sets file (has to be synced by the native app !!!!)
     */
    listExerciseSets() {
        this.log('getting exercise sets');
        return this.callForResponse('readExerciseSets', {});
    }
    /**
     * push session to the FIFO sync queue
     * @param session
     */
    pushSession(session) {
        this.log(`pushing session ${session.deviceUUID}`);
        return this.callForResponse('pushSession', { session: JSON.stringify(session) });
    }
    /**
     * send stored sync sessions queue to the API and clean up the queue if positive.
     * Sync in FIFO order
     */
    sendStoredSessions() {
        this.log(`sending sessions to the server`);
        return this.callForResponse('sendSessions', {});
    }
    /**
     * store availability in the file, dont change update time
     * @param exerciseSet
     * @param availability
     */
    storeAvailability(exerciseSet, availability) {
        this.log(`storing availabilities for ${exerciseSet}`);
        return this.callForResponse('saveAvailability', { exerciseSet, availability: JSON.stringify(availability) });
    }
    /**
     * store session in current session file
     * @param session
     */
    storeCurrentSession(session) {
        this.log(`storing current session ${session.deviceUUID}`);
        return this.callForResponse('saveCurrentSession', { session: JSON.stringify(session) });
    }
    /**
     * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
     * @param exerciseSet
     * @param syncFrequencyMs
     */
    syncAvailabilities(exerciseSet, syncFrequencyMs) {
        this.log(`syncing availabilities for ${exerciseSet}`);
        return this.callForResponse('syncAvailability', { exerciseSet, syncFrequencyMs });
    }
    /**
     * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
     * @param exerciseSet
     * @param syncFrequencyMs
     */
    syncChapters(exerciseSet, syncFrequencyMs) {
        this.log(`syncing chapters for ${exerciseSet}`);
        return this.callForResponse('syncChapters', { exerciseSet, syncFrequencyMs });
    }
    /**
     * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
     * @param exerciseSet
     * @param syncFrequencyMs
     */
    syncQuestions(exerciseSet, syncFrequencyMs) {
        this.log(`syncing questions for ${exerciseSet}`);
        return this.callForResponse('syncQuestions', { exerciseSet, syncFrequencyMs });
    }
    /**
     * do application close
     */
    close() {
        this.log('calling for close the app.');
        this.callForResponse('close', {}).subscribe();
    }
    // #### data management api #####
    subscribeForRefreshingEvents() {
        return this.refreshingSubject;
    }
}
IosNewNativeApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IosNewNativeApi_Factory() { return new IosNewNativeApi(i0.ɵɵinject(i1.PendingRequestsQueue), i0.ɵɵinject(i2.LoggerService)); }, token: IosNewNativeApi, providedIn: "root" });
