/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./question-answer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../directives/kids-rotation.directive";
import * as i3 from "../../../services/theme.service";
import * as i4 from "@angular/common";
import * as i5 from "./question-answer.component";
import * as i6 from "../../../services/audio-recorder.service";
var styles_QuestionAnswerComponent = [i0.styles];
var RenderType_QuestionAnswerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuestionAnswerComponent, data: {} });
export { RenderType_QuestionAnswerComponent as RenderType_QuestionAnswerComponent };
function View_QuestionAnswerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-secondary"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.turnQuestionContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-align-left"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionAnswerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-secondary"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.turnQuestionContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-align-left"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionAnswerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-danger mr-1"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recordClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-microphone"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionAnswerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-danger mr-1 blink"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopRecording() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-stop-circle"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionAnswerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-danger mr-1"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-play-circle"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionAnswerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-secondary"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.turnAnswerContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-align-left"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionAnswerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-secondary"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.turnAnswerContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-align-left"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_QuestionAnswerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["appKidsRotation", "5.0"], ["class", "card speach-bubble answer my-3"]], null, null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", " card-body text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" - No, London isn't a vilage. It's a city."])), (_l()(), i1.ɵeld(6, 0, null, null, 17, "div", [["class", "card-footer text-right text-light"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_6)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 5, "a", [["appKidsRotation", ""], ["class", "btn btn-primary mr-1 ml-1"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playAnswer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { blink: 0 }), i1.ɵdid(17, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fas  fa-volume-up"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_7)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_8)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(23, 0, [[1, 0], ["audioTarget", 1]], null, 0, "audio", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "5.0"; _ck(_v, 2, 0, currVal_0); var currVal_2 = (_co.hasRecorder() && !_co.isRecording()); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.hasRecorder() && _co.isRecording()); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.isShowPlay(); _ck(_v, 12, 0, currVal_4); var currVal_5 = "btn btn-primary mr-1 ml-1"; var currVal_6 = _ck(_v, 16, 0, _co.isAnswerBlinking()); _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = ""; _ck(_v, 17, 0, currVal_7); var currVal_8 = !_co.isShowAnswerContent(); _ck(_v, 20, 0, currVal_8); var currVal_9 = _co.isShowAnswerContent(); _ck(_v, 22, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getAnswerContent(); _ck(_v, 4, 0, currVal_1); }); }
export function View_QuestionAnswerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { audioTarget: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["appKidsRotation", "5.0"], ["class", "card speach-bubble question mt-2 mb-3"]], null, null, null, null, null)), i1.ɵdid(3, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "no-gutters row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "col-md-4 d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "img", [["class", "card-img"]], [[8, "src", 4]], null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { avatar: 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "col p-3 text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "h4", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 10, "div", [["class", "card-footer text-right text-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "a", [["appKidsRotation", ""], ["class", "btn btn-primary mr-1"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playQuestion() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { blink: 0 }), i1.ɵdid(17, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fas fa-volume-up"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_1)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_2)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuestionAnswerComponent_3)), i1.ɵdid(24, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "5.0"; _ck(_v, 3, 0, currVal_0); var currVal_2 = "card-img"; var currVal_3 = _ck(_v, 9, 0, _co.isAvatar()); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = "btn btn-primary mr-1"; var currVal_6 = _ck(_v, 16, 0, _co.isQuestionBlinking()); _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = ""; _ck(_v, 17, 0, currVal_7); var currVal_8 = !_co.isShowQuestionContent(); _ck(_v, 20, 0, currVal_8); var currVal_9 = _co.isShowQuestionContent(); _ck(_v, 22, 0, currVal_9); var currVal_10 = _co.isShowAnswer(); _ck(_v, 24, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getImage(); _ck(_v, 6, 0, currVal_1); var currVal_4 = _co.getQuestionContent(); _ck(_v, 11, 0, currVal_4); }); }
export function View_QuestionAnswerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-question-answer", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_QuestionAnswerComponent_0, RenderType_QuestionAnswerComponent)), i1.ɵdid(1, 245760, null, 0, i5.QuestionAnswerComponent, [i6.AudioRecorderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuestionAnswerComponentNgFactory = i1.ɵccf("app-question-answer", i5.QuestionAnswerComponent, View_QuestionAnswerComponent_Host_0, {}, { finish: "finish" }, []);
export { QuestionAnswerComponentNgFactory as QuestionAnswerComponentNgFactory };
