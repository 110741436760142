import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {

  public static LEFT = 'left';
  public static RIGHT = 'right';
  public static MIDDLE = 'middle';
  left = FooterBarComponent.LEFT;
  right = FooterBarComponent.RIGHT;
  middle = FooterBarComponent.MIDDLE;

  @Input()
  showPrev: boolean;
  @Input()
  showNext: boolean;
  @Input()
  thirdDescription: string;

  @Output()
  interact = new EventEmitter<string>();

  onClick(state: string) {
    this.interact.emit(state);
  }

  constructor(private themeService: ThemeService) { }

  isDark() {
    return this.themeService.isFooterDark();
  }

  ngOnInit() {
  }

}
