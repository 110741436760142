import { Component, OnInit, OnDestroy, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionDataHolderService } from 'src/app/services/session-data-holder.service';
import { zip, Subscription, combineLatest, of } from 'rxjs';
import { QuestionComponentProviderService } from 'src/app/services/question-component-provider.service';
import { DynamicHostDirective } from 'src/app/directives/dynamic-host.directive';
import { map, filter, tap, switchMap } from 'rxjs/operators';
import { ExerciseSession, ExerciseSessionQuestion } from 'src/app/model/personal';
import { QuestionComponentBase } from '../questions/question-component-base';
import { FooterBarComponent } from '../footer-bar/footer-bar.component';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';
import { environment } from 'src/environments/environment';


export abstract class SessionQuestionHostBase implements OnInit, OnDestroy {
  dataSubscription: Subscription;

  @ViewChild(DynamicHostDirective, {static: true}) dynamicHost: DynamicHostDirective;
  questionNumber: number;
  session: ExerciseSession;
  question: ExerciseSessionQuestion<any, any>;
  questionComponent: QuestionComponentBase<any, any>;
  sessionId: string;
  currentQuestionOpenTime: number;
  questionComponentOnFinishSubscription: Subscription;
  protected rest: CspaRestService;

  constructor(protected activatedRoute: ActivatedRoute,
              protected sessionDataHolder: SessionDataHolderService,
              protected questionComponentProvider: QuestionComponentProviderService,
              protected componentFactoryResolver: ComponentFactoryResolver,
              protected router: Router,
              restProvider: ApiServiceProvider) {
    this.rest = restProvider.getRestServiceImplementation();
    this.activatedRoute.parent.paramMap.subscribe( params => {
      this.sessionId = params.get('sessionId');
      this.log(`question host - got session id = ${this.sessionId} from url.`);
    });
  }

  log(text: any) {
    if (environment.debug) {
      console.log(text);
    }
  }

  ngOnInit() {
    this.dataSubscription = this.readData().subscribe(
      ([questionNumber, session]) => {
        this.loadQuestionComponent(this.question);
      });
  }

  hasInstructions() {
    if (this.question && this.question.question.definition.instructions) {
      return true;
    }
    return false;
  }

  getInstructions() {
    return this.question.question.definition.instructions;
  }

  getTopBarTitle() {
    if (!this.session) {
      return '';
    }
    return `${this.session.chapterName} - ${this.session.exerciseName}`;
  }

  isShowPrev() {
    /*if (!this.questionNumber) {
      return false;
    }
    return true;*/
    return false;
  }

  isShowNext() {
    if (this.questionNumber == null || !this.session) {
      return false;
    }
    return this.questionNumber < this.session.questions.length && this.questionComponent && this.questionComponent.allowNext;
  }

  getMiddleDescription() {
    if (!this.questionComponent) {
      return null;
    }
    return this.questionComponent.getMiddleDescription();
  }

  onFooterInteract(event: string) {
    if (event === FooterBarComponent.LEFT) {
      this.moveQuestion(-1);
    } else if (event === FooterBarComponent.RIGHT) {
      this.moveQuestion(1);
    } else if (event === FooterBarComponent.MIDDLE) {
      this.questionComponent.onMiddleClick();
    }
  }

  applyQuestionTiming() {
    if (!this.question.submitDate) {
      this.question.submitDate = new Date().getTime();
    }
    this.question.timeSpent += new Date().getTime() - this.currentQuestionOpenTime;
    this.currentQuestionOpenTime = new Date().getTime();
  }

  moveQuestion(moveIndex: number) {
    if (!this.session || this.questionNumber == null) {
      return;
    }

    const newQuestionNumber = this.questionNumber + moveIndex;
    if (newQuestionNumber < 0 || newQuestionNumber > this.session.questions.length) {
      return;
    }

    this.questionComponent.getAnswerForSubmit().pipe(
      tap( answerWrapper => answerWrapper && answerWrapper.answer ?
            this.session.questions[this.questionNumber].answer = answerWrapper.answer
            : ''),
      tap( answerWrapper => {
        this.applyQuestionTiming();
      }),
      switchMap( answerWrapper =>
        answerWrapper && answerWrapper.answer ?
          this.rest.postSessionQuestionAnswer(this.sessionId, this.questionNumber, this.session.questions[this.questionNumber])
          : of(this.session) ),
      tap ( newSession => this.session.questions[this.questionNumber].answer = newSession.questions[this.questionNumber].answer)
    ).subscribe(
      newSession => this.navigateToQuestion(newQuestionNumber)
    );
  }

  abstract finishSession();

  abstract navigateToQuestion(newQuestionNumber: number);

  getProgressStyle() {
    if (!this.session) {
      return {width: '0px'};
    }
    const progressValue = Math.round((this.questionNumber + 1) / this.session.questions.length * 100);
    return {width: `${progressValue}%`};
  }

  closeExerciseSet() {
    this.finishSession();
    // this.router.navigate(['exercises', this.exerciseSet] );
  }

  loadQuestionComponent(question: ExerciseSessionQuestion<any, any>) {
    this.log('question host - loading question component for question: ');
    this.log(question);

    const viewContainerRef = this.dynamicHost.viewContainerRef;

    if (this.questionComponentOnFinishSubscription) {
      this.questionComponentOnFinishSubscription.unsubscribe();
      this.questionComponentOnFinishSubscription = null;
    }

    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver
      .resolveComponentFactory(this.questionComponentProvider.recognizeComponent(question));

    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.questionComponent = componentRef.instance as QuestionComponentBase<any, any>;
    this.log('question host - question component created');
    this.questionComponent.questionSubject.next(question);
    this.questionComponentOnFinishSubscription =
      this.questionComponent.finish.subscribe( _ => this.onFooterInteract(FooterBarComponent.RIGHT));
  }

  public readData() {
    return combineLatest(
      this.activatedRoute.paramMap.pipe(
        map( params => params.get('questionNumber')),
        filter( questionNumber => questionNumber != null),
        tap( questionNumber => this.questionNumber = Number.parseInt(questionNumber, 0))
      ),
      this.sessionDataHolder.session.pipe(
        filter( session => session != null),
        tap( session => this.session = session)
      )
    ).pipe(
      tap(([questionNumber, session]) => {
        this.log(`question host - received session and question number from params and holder`);
        this.question = session.questions[questionNumber];
        this.setupQuestionTimings();
      })
    );
  }

  setupQuestionTimings() {
    if (!this.question.startDate) {
      this.question.startDate = new Date().getTime();
    }
    if (this.question.timeSpent == null) {
      this.question.timeSpent = 0;
    }
    this.currentQuestionOpenTime = new Date().getTime();
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }
}

@Component({
  selector: 'app-session-question-host',
  templateUrl: './session-question-host.component.html',
  styleUrls: ['./session-question-host.component.scss']
})
export class SessionQuestionHostComponent extends SessionQuestionHostBase {

    exerciseSet: string;
    chapter: string;
    section: string;
    exercise: string;

    constructor(activatedRoute: ActivatedRoute,
                sessionDataHolder: SessionDataHolderService,
                questionComponentProvider: QuestionComponentProviderService,
                componentFactoryResolver: ComponentFactoryResolver,
                router: Router,
                restProvider: ApiServiceProvider) {
      super(activatedRoute, sessionDataHolder, questionComponentProvider, componentFactoryResolver, router, restProvider);
      activatedRoute.parent.paramMap.subscribe( params => {
        this.exerciseSet = params.get('exerciseSet');
        this.chapter = params.get('chapter');
        this.section = params.get('section');
        this.exercise = params.get('exercise');
      });
    }

    finishSession() {
      this.rest.finishSession(this.session.deviceUUID).subscribe( finishedSession => {
        this.sessionDataHolder.session.next(finishedSession);
        this.router.navigate(
          ['exercises', this.exerciseSet, this.chapter, this.section, this.exercise,
          'session', this.sessionId, 'summary' ]);
      });
    }

    navigateToQuestion(newQuestionNumber: number) {
      if (newQuestionNumber === this.session.questions.length) {
        this.finishSession();
      } else {
        this.router.navigate(
          ['exercises', this.exerciseSet, this.chapter, this.section, this.exercise,
          'session', this.sessionId, 'question', newQuestionNumber]);
      }
    }
}


