import { NgZone } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../../services/logger.service";
class CallbackResponse {
}
CallbackResponse.NextResponseType = 'next';
CallbackResponse.ErrorResponseType = 'error';
CallbackResponse.CompleteResponseType = 'complete';
export class CallbackRequest {
}
export class PendingRequestsQueue {
    constructor(ngZone, logger) {
        this.ngZone = ngZone;
        this.logger = logger;
        this.pendingRequestById = {};
    }
    registerPendingRequest(id, observer) {
        this.pendingRequestById[id] = observer;
    }
    deletePendingRequest(id) {
        delete this.pendingRequestById[id];
    }
    handlePendingRequest(data) {
        const response = data;
        this.logger.log(`got pending request response[${response.id}]: `);
        this.logger.log(JSON.stringify(data));
        const pendingRequest = this.pendingRequestById[response.id];
        if (!pendingRequest) {
            this.logger.log('missing pending request for id ' + pendingRequest.id);
        }
        if (response.responseType === CallbackResponse.NextResponseType) {
            this.logger.log('invoking next in ngzone');
            this.ngZone.run(() => pendingRequest.next(response.responseBody));
        }
        else if (response.responseType === CallbackResponse.CompleteResponseType) {
            this.logger.log('invoking complete in ngzone & deleting pending request');
            this.ngZone.run(() => pendingRequest.complete());
            this.deletePendingRequest(response.id);
        }
        else if (response.responseType === CallbackResponse.ErrorResponseType) {
            this.logger.log('invoking error in ngzone & deleting pending request');
            this.ngZone.run(() => pendingRequest.error(new Error(response.responseBody)));
            this.deletePendingRequest(response.id);
        }
    }
}
PendingRequestsQueue.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PendingRequestsQueue_Factory() { return new PendingRequestsQueue(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.LoggerService)); }, token: PendingRequestsQueue, providedIn: "root" });
