import { Observable, BehaviorSubject, of } from 'rxjs';
import { defaultIfEmpty, flatMap, tap } from 'rxjs/operators';
import { EmptyRecorder } from './Recorder';
import { RecorderState } from './RecorderState';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./rest-provider.service";
export class AudioRecorderService {
    constructor(apiProvider) {
        this.apiProvider = apiProvider;
    }
    createRecorderForStream(stream) {
        const recorder = new Recorder1(stream);
        recorder.init();
        return recorder;
    }
    getUserMedia(handler, errorHandler) {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(handler).catch(errorHandler);
        }
        else {
            navigator.getUserMedia({ audio: true, video: false }, handler, errorHandler);
        }
    }
    prepareRegularWebRecorder() {
        return new Observable(subscriber => {
            let subscriberRecorder = null;
            if (!navigator.mediaDevices.getUserMedia) {
            }
            this.getUserMedia(stream => {
                try {
                    subscriberRecorder = this.createRecorderForStream(stream);
                    subscriber.next(subscriberRecorder);
                }
                catch (e) {
                    subscriber.error(e);
                }
            }, error => {
                subscriber.error(error);
            });
            return { unsubscribe: () => {
                    if (subscriberRecorder) {
                        subscriberRecorder.destroy();
                    }
                } };
        });
    }
    log(text) {
        if (environment.debug) {
            console.log(text);
        }
    }
    // choose the device first, the create the recorder as observable
    init() {
        return this.apiProvider.getAlternativeAudioApi().pipe(defaultIfEmpty(new EmptyRecorder()), tap(alternative => {
            if (!(alternative instanceof EmptyRecorder)) {
                this.log('audio recorder - got alternative recorder (native)');
            }
            else {
                this.log('audio recorder - alternative recorder (native) not found - using javascript implementation');
            }
        }), flatMap(alternative => (alternative instanceof EmptyRecorder) ? this.prepareRegularWebRecorder() : of(alternative)));
    }
}
AudioRecorderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AudioRecorderService_Factory() { return new AudioRecorderService(i0.ɵɵinject(i1.ApiServiceProvider)); }, token: AudioRecorderService, providedIn: "root" });
class Recorder1 {
    constructor(stream) {
        this.stream = stream;
    }
    getRecorderState() {
        return this.internalRecorder.recordingState;
    }
    terminate() {
        this.internalRecorder.terminate();
    }
    play() {
        // not supported
    }
    clear() {
        this.internalRecorder.clear();
        return of(null);
    }
    record() {
        this.internalRecorder.recordingState.next(RecorderState.Recording);
    }
    stop() {
        return new Observable(observer => {
            this.internalRecorder.recordingState.next(RecorderState.Stopped);
            observer.next(this.internalRecorder.exportToWav('audio/wav'));
            observer.complete();
            return { unsubscribe: () => { } };
        });
    }
    init() {
        this.audioContext = this.createAudioContext();
        const source = this.audioContext.createMediaStreamSource(this.stream);
        this.internalRecorder = new InternalRecorder(source, this.audioContext);
    }
    createAudioContext() {
        const AudioContextClass = this.findAudioContextClass();
        return new AudioContextClass();
    }
    findAudioContextClass() {
        return window.AudioContext || window.webkitAudioContext;
    }
    destroy() {
        this.terminate();
    }
}
class InternalRecorder {
    constructor(input, audioContext) {
        this.recording = false;
        this.recBufferLeft = [];
        this.recBufferRight = [];
        this.recordingState = new BehaviorSubject(RecorderState.Stopped);
        this.sampleRate = audioContext.sampleRate;
        this.processor = input.context.createScriptProcessor(4096, 2, 2);
        this.processor.onaudioprocess = event => {
            this.record(event.inputBuffer.getChannelData(0), event.inputBuffer.getChannelData(1));
        };
        input.connect(this.processor);
        this.processor.connect(audioContext.destination);
        this.recordingState.subscribe(state => this.recording = state === RecorderState.Recording);
    }
    terminate() {
        this.processor.disconnect();
        this.recordingState.complete();
    }
    record(left, right) {
        if (!this.recording) {
            return;
        }
        this.recBufferLeft.push(new Float32Array(left));
        this.recBufferRight.push(new Float32Array(right));
    }
    clear() {
        this.recBufferLeft = [];
        this.recBufferRight = [];
    }
    exportToWav(type) {
        const bufferL = this.mergeBuffers(this.recBufferLeft);
        const bufferR = this.mergeBuffers(this.recBufferRight);
        const interleaved = this.interleave(bufferL, bufferR);
        const dataview = this.encodeWav(interleaved);
        const audioBlob = new Blob([dataview], { type });
        return audioBlob;
    }
    encodeWav(samples) {
        const buffer = new ArrayBuffer(44 + samples.length * 2);
        const view = new DataView(buffer);
        /* RIFF identifier */
        this.writeString(view, 0, 'RIFF');
        /* file length */
        view.setUint32(4, 32 + samples.length * 2, true);
        /* RIFF type */
        this.writeString(view, 8, 'WAVE');
        /* format chunk identifier */
        this.writeString(view, 12, 'fmt ');
        /* format chunk length */
        view.setUint32(16, 16, true);
        /* sample format (raw) */
        view.setUint16(20, 1, true);
        /* channel count */
        view.setUint16(22, 2, true);
        /* sample rate */
        view.setUint32(24, this.sampleRate, true);
        /* byte rate (sample rate * block align) */
        view.setUint32(28, this.sampleRate * 4, true);
        /* block align (channel count * bytes per sample) */
        view.setUint16(32, 4, true);
        /* bits per sample */
        view.setUint16(34, 16, true);
        /* data chunk identifier */
        this.writeString(view, 36, 'data');
        /* data chunk length */
        view.setUint32(40, samples.length * 2, true);
        this.floatTo16BitPCM(view, 44, samples);
        return view;
    }
    floatTo16BitPCM(output, off, input) {
        let offset = off;
        for (let i = 0; i < input.length; i++, offset += 2) {
            const s = Math.max(-1, Math.min(1, input[i]));
            output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }
    }
    writeString(view, offset, text) {
        for (let i = 0; i < text.length; i++) {
            view.setUint8(offset + i, text.charCodeAt(i));
        }
    }
    interleave(bufferL, bufferR) {
        const len = bufferL.length + bufferR.length;
        const result = new Float32Array(len);
        let index = 0;
        let inputIndex = 0;
        while (index < len) {
            result[index++] = bufferL[inputIndex];
            result[index++] = bufferR[inputIndex];
            inputIndex++;
        }
        return result;
    }
    mergeBuffers(recBuffer) {
        let len = 0;
        for (const buf of recBuffer) {
            len += buf.length;
        }
        const res = new Float32Array(len);
        let offset = 0;
        for (const buf of recBuffer) {
            res.set(buf, offset);
            offset += buf.length;
        }
        return res;
    }
}
