import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { ExerciseSession } from 'src/app/model/personal';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';

@Component({
  selector: 'app-start-exercise',
  templateUrl: './start-exercise.component.html',
  styleUrls: ['./start-exercise.component.scss']
})
export class StartExerciseComponent implements OnInit {

  exerciseSet: string;
  chapter: string;
  section: string;
  exercise: string;
  fullPath: string;
  private rest: CspaRestService;

  constructor(state: ActivatedRoute,
              restProvider: ApiServiceProvider,
              private router: Router) {
    this.rest = restProvider.getRestServiceImplementation();
    state.paramMap.subscribe(params => {
      this.exerciseSet = params.get('exerciseSet');
      this.chapter = params.get('chapter');
      this.section = params.get('section');
      this.exercise = params.get('exercise');
      this.fullPath = [this.exerciseSet, this.chapter, this.section, this.exercise].join('_');
      this.startSession();
    });
  }

  startSession() {
    this.rest.startExerciseSession(this.fullPath).subscribe(
      session => this.routeToTheSession(session)
    );
  }
  routeToTheSession(session: ExerciseSession): void {
    this.router.navigate(
      ['exercises', this.exerciseSet, this.chapter, this.section, this.exercise, 'session', session.deviceUUID]);
  }

  ngOnInit() {
  }

}
