/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exercises.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/exercise-set-details/exercise-set-details.component.ngfactory";
import * as i3 from "../../components/exercise-set-details/exercise-set-details.component";
import * as i4 from "@angular/common";
import * as i5 from "./exercises.component";
import * as i6 from "../../services/rest-provider.service";
var styles_ExercisesComponent = [i0.styles];
var RenderType_ExercisesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExercisesComponent, data: {} });
export { RenderType_ExercisesComponent as RenderType_ExercisesComponent };
function View_ExercisesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-sm-6 col-md-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-exercise-set-details", [], null, null, null, i2.View_ExerciseSetDetailsComponent_0, i2.RenderType_ExerciseSetDetailsComponent)), i1.ɵdid(2, 114688, null, 0, i3.ExerciseSetDetailsComponent, [], { availability: [0, "availability"], definition: [1, "definition"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit[0]; var currVal_1 = _v.context.$implicit[1]; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ExercisesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "padding-top: 4rem;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExercisesComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listSets(); var currVal_1 = _co.identityOfSet; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_ExercisesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exercises", [], null, null, null, View_ExercisesComponent_0, RenderType_ExercisesComponent)), i1.ɵdid(1, 114688, null, 0, i5.ExercisesComponent, [i6.ApiServiceProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExercisesComponentNgFactory = i1.ɵccf("app-exercises", i5.ExercisesComponent, View_ExercisesComponent_Host_0, {}, {}, []);
export { ExercisesComponentNgFactory as ExercisesComponentNgFactory };
