import { Observable, Subscriber, from } from 'rxjs';
import * as i0 from "@angular/core";
export class AndroidNativeApi {
    initAudio(callback) {
        if (mobileMediaRecorder) {
            try {
                callback(mobileMediaRecorder.init());
            }
            catch (e) {
                callback(false);
            }
        }
    }
    clear() {
        return from(new Promise((resolve, reject) => {
            mobileMediaRecorder.clear();
            resolve(null);
        }));
    }
    record() {
        mobileMediaRecorder.record();
    }
    stop() {
        return from(new Promise((resolve, reject) => {
            mobileMediaRecorder.stop();
            resolve(null);
        }));
    }
    play2() {
        mobileMediaRecorder.play();
    }
    terminate() {
        mobileMediaRecorder.stop();
    }
    verifyExistance(observer) {
        if (mobileCspaBridge) {
            observer.next(mobileCspaBridge);
            observer.complete();
            return true;
        }
        return false;
    }
    createSession(exerciseId) {
        return new Observable(observer => {
            observer.next(JSON.parse(mobileCspaBridge.createSession(JSON.stringify({ exerciseId }))));
            observer.complete();
            return {
                unsubscribe: () => { }
            };
        });
    }
    recreateSession(sessionId) {
        return new Observable(observer => {
            observer.next(JSON.parse(mobileCspaBridge.recreateSession(JSON.stringify({ sessionId }))));
            observer.complete();
            return {
                unsubscribe: () => { }
            };
        });
    }
    getSessionData(sessionId) {
        return new Observable(observer => {
            observer.next(JSON.parse(mobileCspaBridge.getSessionData(JSON.stringify({ sessionId }))));
            observer.complete();
            return {
                unsubscribe: () => { }
            };
        });
    }
    getSessionQuestionDefinition(sessionId, questionId) {
        return new Observable(observer => {
            observer.next(JSON.parse(mobileCspaBridge.getSessionQuestionDefinition(JSON.stringify({ sessionId, questionId }))));
            observer.complete();
            return {
                unsubscribe: () => { }
            };
        });
    }
    submitSessionScore(sessionId, questionId, submit) {
        return new Observable(observer => {
            observer.next(JSON.parse(mobileCspaBridge.submitSessionScore(JSON.stringify({ sessionId, questionId }), JSON.stringify(submit))));
            observer.complete();
            return {
                unsubscribe: () => { }
            };
        });
    }
    recalculateScore() {
        return new Observable(observer => {
            observer.next(mobileCspaBridge.recalculateScore());
            observer.complete();
            return {
                unsubscribe: () => { }
            };
        });
    }
    closeQuestions() {
        return new Observable(observer => {
            mobileCspaBridge.closeSession();
            observer.next(true);
            observer.complete();
            return {
                unsubscribe: () => { }
            };
        });
    }
}
AndroidNativeApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AndroidNativeApi_Factory() { return new AndroidNativeApi(); }, token: AndroidNativeApi, providedIn: "root" });
