/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/kids-rotation.directive";
import * as i3 from "../../services/theme.service";
import * as i4 from "@angular/common";
import * as i5 from "./footer-bar.component";
var styles_FooterBarComponent = [i0.styles];
var RenderType_FooterBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterBarComponent, data: {} });
export { RenderType_FooterBarComponent as RenderType_FooterBarComponent };
function View_FooterBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn-nav btn-nav-left"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_co.left) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-chevron-left"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FooterBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn btn-success"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_co.middle) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.thirdDescription; _ck(_v, 2, 0, currVal_1); }); }
function View_FooterBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "btn-nav btn-nav-right"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_co.right) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-chevron-right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FooterBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "height:100px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "bottom-nav"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "text-light": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterBarComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col text-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterBarComponent_2)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterBarComponent_3)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bottom-nav"; var currVal_1 = _ck(_v, 4, 0, _co.isDark()); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.showPrev; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.thirdDescription; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.showNext; _ck(_v, 13, 0, currVal_4); }, null); }
export function View_FooterBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer-bar", [], null, null, null, View_FooterBarComponent_0, RenderType_FooterBarComponent)), i1.ɵdid(1, 114688, null, 0, i5.FooterBarComponent, [i3.ThemeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterBarComponentNgFactory = i1.ɵccf("app-footer-bar", i5.FooterBarComponent, View_FooterBarComponent_Host_0, { showPrev: "showPrev", showNext: "showNext", thirdDescription: "thirdDescription" }, { interact: "interact" }, []);
export { FooterBarComponentNgFactory as FooterBarComponentNgFactory };
