import { SupportedPlatforms } from './platforms';

export const environment = {
  production: true,
  apiEndpoint: 'https://api.cspa.callanonline.com',
  serverBase: 'https://cspa.callanonline.com',
  authClientId: 'cja09sdgh8a9sh893gbadvz230haodgb789at3ASGDg98g',
  authEndpoint: 'https://casa.callanonline.com',
  awsBase: 'https://s3.amazonaws.com',
  platform: SupportedPlatforms.web,
  // should not be used in env different than developer
  casaTestingEndpoint: null,
  debug: false,
  envName: "prod"
};
