import { Component, OnInit, Input } from '@angular/core';
import { Chapter, Section, Exercise } from 'src/app/model/struct';
import { ItemAvailability } from 'src/app/model/personal';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SupportedPlatforms } from 'src/environments/platforms';
import {LoggerService} from "../../services/logger.service";

@Component({
  selector: 'app-chapter-details',
  templateUrl: './chapter-details.component.html',
  styleUrls: ['./chapter-details.component.scss']
})
export class ChapterDetailsComponent implements OnInit {

  @Input()
  chapter: Chapter;
  @Input()
  availabilities: Map<string, ItemAvailability>;

  constructor(private router: Router, private logger: LoggerService) { }

  onExerciseSelected(section: Section, exercise: Exercise) {
    const chapterPathSplit = this.chapter.path.split('_');
    if (!this.checkAvailability(section, exercise)) {
      return;
    }
    if (environment.platform === SupportedPlatforms.webCasa) {
      this.router.navigate(['cspa', 'startExercise', exercise.id]);
      return;
    }
    this.router.navigate(['exercises', chapterPathSplit[0], chapterPathSplit[1], section.path, exercise.path, 'start']);
  }

  checkAvailability(section: Section, exercise: Exercise) {
    const sectionFullPath = [this.chapter.path, section.path].join('_');
    const exerciseFullPath = [sectionFullPath, exercise.path].join('_');

    const sectionAv = this.availabilities.get(sectionFullPath);
    if (!sectionAv || !sectionAv.assigned || !sectionAv.available) {
      return false;
    }

    const exerciseAv = this.availabilities.get(exerciseFullPath);
    if (!exerciseAv || !exerciseAv.assigned || !exerciseAv.available) {
      return false;
    }
    return true;
  }

  isReady() {
    this.logger.log(`checking if chapter details component is read for values ${this.chapter != null} and ${this.availabilities != null}`)
    return this.chapter && this.availabilities;
  }

  selectedChapterSections() {
    return this.chapter.sections;
  }

  getSectionExercises(section: Section) {
    return section.exercises;
  }

  getExerciseAvailability(section: Section, exercise: Exercise) {
    const fullExercisePath = [this.chapter.path, section.path, exercise.path].join('_');
    return this.availabilities.get(fullExercisePath);
  }

  ngOnInit() {
  }

}
