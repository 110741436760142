import {Injectable, NgZone} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {map, share} from "rxjs/operators";
import {MobileNativeAudio, NewMobileNativeApi} from "../MobileNativeApi";
import {CallbackRequest, PendingRequestsQueue} from "../js-bridge-task-queue";
import {ExerciseSession, ItemAvailability} from "../../../model/personal";
import {Chapter, ExerciseSet} from "../../../model/struct";
import {Question} from "../../../model/questions";
import {LoggerService} from "../../../services/logger.service";

declare global {
  interface Window {
    WebViewJavascriptBridge: any;
    WVJBCallbacks: any;
  }
}

interface Bridge {
  queue: {
    name: string,
    params: any,
    f: (...args: any[]) => any
  }[];
  registerHandlerHandler(name: string, f: (data: any, callback: (...args: any[]) => any) => void );
  callHandler(name: string, params: any, f: (...args: any[]) => any);
}

class TemporaryBridge implements Bridge {

  queue = [];
  log(text: string) {
    if (true) {
      console.log(text);
    }
  }
  callHandler(name: string, params: any, f: (...args: any[]) => any) {
    this.log(`ios native - adding the call ${name} to the temporary queue`);
    this.queue.push( {name, params, f} );
  }
  registerHandlerHandler(name: string, f: (data: any, callback: (...args: any[]) => any) => void ) {}
}

@Injectable({
  providedIn: 'root'
})
export class IosNewNativeApi implements NewMobileNativeApi, MobileNativeAudio {

  // queue of callbacks to resolve
  bridge: Bridge = new TemporaryBridge();
  updateEventsSubject = new Subject<void>();
  private refreshingSubject = new Subject<Observable<void>>();

  id = 0;


  // setup iOS bridge
  setupWebViewJavascriptBridge(callback: (bridge: any) => void): any {

    // got bridge ready, return it
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge as Bridge);
    }

    // callbacks executed during initialization
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }

    // prepare callbacks for initialization
    window.WVJBCallbacks = [callback];
    const WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'https://__bridge_loaded__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout( _ => { document.documentElement.removeChild(WVJBIframe); } , 0);
  }

  constructor(private taskQueue: PendingRequestsQueue, private logger: LoggerService) {
    (window as any).bridgeHandler = this;
    // wait after initialization, then replace old bridge with the new one - execute queued tasks if necesarry
    this.setupWebViewJavascriptBridge( bridge => {
      bridge.callHandler('init', {}, () => {});

      this.log('replacing temporary bridge with the one provided. Executing enqueued tasks.');
      for (const call of this.bridge.queue) {
        this.log(`executing ${call.name} enqueued using provided bridge`);
        bridge.callHandler(call.name, call.params, call.f);
      }

      this.bridge = bridge;
    });
  }

  private callForResponse<T>(name: string, args: any): Observable<T> {
    return Observable.create( observer => {
      const req = new CallbackRequest();
      req.args = args;
      req.id = this.id++;
      this.taskQueue.registerPendingRequest(req.id, observer);
      this.bridge.callHandler(name, req, res => {
        // do nothing, response will be handled by calling back from ios to javascript
      });
    });
  }

  notifyDataUpdate(): void {
    this.logger.log('notifying data update');
    this.updateEventsSubject.next();
  }

  handlePendingRequest(data: any) {
    this.taskQueue.handlePendingRequest(data);
  }

  listenForDataUpdates(): Observable<void> {
    return this.updateEventsSubject;
  }

  log(text: string) {
    if (true) {
      this.logger.log(`[Ios-api2] - ${text}`);
    }
  }

  // #### audio implementation #####
  /*
  this is the same implementation of audio actions as it was in old cspa
   */

  /**
   * clear recording buffer
   */
  clear(): Observable<void> {
    this.log("calling clear");
    return this.callForResponse<void>('clear',{});
  }

  /**
   * start recording
   */
  record() {
    this.log("calling record");
    return this.callForResponse<void>('record',{}).subscribe();
  }

  /**
   * stop recording or playback
   */
  stop(): Observable<void> {
    this.log("calling stop");
    return this.callForResponse<void>('stop',{});
  }

  /**
   * start recording playback
   */
  play2() {
    this.log("calling play");
    return this.callForResponse<void>('play',{}).subscribe();
  }

  /**
   * clean up and release recording device
   */
  terminate() {
    this.log("calling terminate");
    return this.callForResponse<void>('terminate',{}).subscribe();
  }

  /**
   * initialize recording device, returns true if positive
   * @param callback
   */
  initAudio(callback: (state: boolean) => void) {
    this.log(" calling clear");
    return this.callForResponse<boolean>('initAudio',{}).subscribe(
      it => callback(it)
    )
  }

  // #### data management implementation #####

  /**
   * read the availability JSON file for exerciseSet
   * @param exerciseSet
   */
  getAvailabilities(exerciseSet: string): Observable<ItemAvailability[]> {
    this.log(`getting availabilities for ${exerciseSet}`);
    return this.callForResponse<ItemAvailability[]>('readAvailabilities', {exerciseSet});
  }

  /**
   * read chapters JSON file for exerciseSet
   * @param exerciseSet
   */
  getChapters(exerciseSet: string): Observable<Chapter[]> {
    this.log(`getting chapters for ${exerciseSet}`);
    return this.callForResponse<Chapter[]>('readChapters', {exerciseSet});
  }

  /**
   * read current session JSON file
   */
  getCurrentSession(): Observable<ExerciseSession> {
    this.log(`getting current session`);
    return this.callForResponse<ExerciseSession>('readCurrentSession', {});
  }

  /**
   * read questions file for exerciseSet
   * @param exerciseSet
   */
  getQuestions(exerciseSet: string): Observable<Question<any, any>[]> {
    this.log(`getting questions for ${exerciseSet}`);
    return this.callForResponse<Question<any, any>[]>('readQuestions', {exerciseSet});
  }

  /**
   * read exercise sets file (has to be synced by the native app !!!!)
   */
  listExerciseSets(): Observable<ExerciseSet[]> {
    this.log('getting exercise sets');
    return this.callForResponse<ExerciseSet[]>('readExerciseSets',{});
  }

  /**
   * push session to the FIFO sync queue
   * @param session
   */
  pushSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.log(`pushing session ${session.deviceUUID}`);
    return this.callForResponse<ExerciseSession>('pushSession', {session: JSON.stringify(session)});
  }

  /**
   * send stored sync sessions queue to the API and clean up the queue if positive.
   * Sync in FIFO order
   */
  sendStoredSessions(): Observable<any> {
    this.log(`sending sessions to the server`);
    return this.callForResponse<any>('sendSessions', {});
  }

  /**
   * store availability in the file, dont change update time
   * @param exerciseSet
   * @param availability
   */
  storeAvailability(exerciseSet: string, availability: ItemAvailability[]): Observable<ItemAvailability[]> {
    this.log(`storing availabilities for ${exerciseSet}`);
    return this.callForResponse<ItemAvailability[]>('saveAvailability', {exerciseSet, availability: JSON.stringify(availability)});
  }

  /**
   * store session in current session file
   * @param session
   */
  storeCurrentSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.log(`storing current session ${session.deviceUUID}`);
    return this.callForResponse<ExerciseSession>('saveCurrentSession', {session: JSON.stringify(session)});
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncAvailabilities(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    this.log(`syncing availabilities for ${exerciseSet}`);
    return this.callForResponse<any>('syncAvailability', {exerciseSet, syncFrequencyMs});
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncChapters(exerciseSet: string, syncFrequencyMs: number): Observable<Chapter[]> {
    this.log(`syncing chapters for ${exerciseSet}`);
    return this.callForResponse<Chapter[]>('syncChapters', {exerciseSet, syncFrequencyMs})
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncQuestions(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    this.log(`syncing questions for ${exerciseSet}`);
    return this.callForResponse<any>('syncQuestions', {exerciseSet, syncFrequencyMs});
  }

  /**
   * do application close
   */
  close(): void {
    this.log('calling for close the app.');
    this.callForResponse<void>('close',{}).subscribe();
  }

  // #### data management api #####
  subscribeForRefreshingEvents(): Observable<Observable<void>> {
    return this.refreshingSubject;
  }

}
