<div style="position: relative;" appKidsRotation="5">
<div style="
backface-visibility: hidden;
-webkit-backface-visibility: hidden;" [@inverse]="isReversed() ? 'inversed' : 'aversed'" class="card picture-card" [ngClass]="{selected: isSelected()}" (click)="onClick()">
    <div class="picture-card-content">
        <img class="picture-card-img" *ngIf="isPicture()" [src]="getImageSrc()">
        <h1 class="picture-card-word" *ngIf="isWord()">{{getWord()}}</h1>
    </div>
</div>
<div style="
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
backface-visibility: hidden;
-webkit-backface-visibility: hidden;" [@inverse]="!isReversed() ? 'inversed' : 'aversed'" class="card picture-card" (click)="onClick()">
    <div class="picture-card-content" style="left: 0; right: 0; top: 0; bottom: 0; padding: 0;">
        <img class="picture-card-img" [src]="getReverseSrc()">
    </div>
</div>
</div>
