import {Component, NgZone, OnInit} from '@angular/core';
import {ApiServiceProvider} from "../../services/rest-provider.service";
import {MobileBridgeServices} from "../../mobile/services/new-mobile-bridge.service";
import {catchError, defaultIfEmpty, finalize, switchMap} from "rxjs/operators";
import {EMPTY, forkJoin, Observable, of, throwError} from "rxjs";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {MenuControlsService} from "../../services/menu-controls.service";
import {LoggerService} from "../../services/logger.service";
import {TranslationService} from "../../services/translation.service";

@Component({
  selector: 'app-entry-point-exercise-set',
  templateUrl: './entry-point-exercise-set.component.html',
  styleUrls: ['./entry-point-exercise-set.component.scss']
})
export class EntryPointExerciseSetComponent implements OnInit {
  private api: MobileBridgeServices;
  private exerciseSet: string;
  private loading = true;
  private error = false;

  constructor(apiProvider: ApiServiceProvider,
              activatedRoute: ActivatedRoute,
              private router: Router, private logger: LoggerService,
              private translation: TranslationService
              ) {
    this.api = apiProvider.getMobileRestServiceImplementation();
    activatedRoute.queryParams.subscribe(queryParams => this.translation.extractLang(queryParams))
    activatedRoute.paramMap.subscribe(params => {
      this.logger.log('got params');
      if (!params.get('exerciseSet')) {
        throw new Error('missing required parameter');
      }
      this.exerciseSet = params.get('exerciseSet');
      this.logger.log(`got exercise set ${this.exerciseSet}`)
      this.syncData().subscribe( {
          complete: () => this.openExerciseSet(),
          error: () => this.openExerciseSetIfDataAvailable()
        }
      )
    })
  }

  openExerciseSet() {
    this.router.navigate(['exercises',this.exerciseSet])
  }

  private syncData(): Observable<void> {
    this.logger.log('syncing data');
    return this.api.syncSessions().pipe(
      defaultIfEmpty( _ => 'session sync done'),
      switchMap( _ => this.api.syncStructure(this.exerciseSet)),
      finalize( () => this.loading = false)
    );
  }

  ngOnInit() {


  }

  private openExerciseSetIfDataAvailable() {
    this.api.isDataValid(this.exerciseSet).subscribe(
      valid => {
        if (valid) {
          this.openExerciseSet();
        } else {
          this.showError();
        }
      }
    )
  }

  private showError() {
    this.error = true;
  }

  isError() {
    return this.error;
  }

  isLoading() {
    return this.loading;
  }
}
