import { Component, OnInit, Input } from '@angular/core';
import { ItemAvailability } from 'src/app/model/personal';
import { ExerciseSet } from 'src/app/model/struct';

@Component({
  selector: 'app-exercise-set-details',
  templateUrl: './exercise-set-details.component.html',
  styleUrls: ['./exercise-set-details.component.scss']
})
export class ExerciseSetDetailsComponent implements OnInit {
  @Input()
  availability: ItemAvailability;
  @Input()
  definition: ExerciseSet;

  constructor() { }

  public isReady() {
    return this.availability && this.definition;
  }

  public getImageUrl() {
    if (!this.definition) {
      return null;
    }
    return this.definition.imageUrl;
  }

  public getName() {
    return this.definition.name;
  }

  public getDescription() {
    return 'This set description should be provided by the api call but still is not. TODO';
  }

  public getProgress() {
    return (this.availability.progress * 100).toFixed();
  }

  public getScore() {
    return (this.availability.score * 100).toFixed();
  }

  public getPath() {
    return this.definition.path;
  }

  ngOnInit() {
  }

}
