import { MobileNativeApi } from './MobileNativeApi';
import { CasaExerciseSession, CasaSessionQuestionDefinition, CasaQuestionScoreSubmit } from 'src/app/model/oldModel';
import { Observable, of, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MobileNativeMockService implements MobileNativeApi {
    createSession(exerciseId: number): Observable<CasaExerciseSession> {
        let res: CasaExerciseSession = {id: 2615704, score: 0.0, baseSessionQuestionNumber: 6,
            questions: [
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 3, definition: {affirm: null, question: null, answer: null, correction: null, askingSexMale: null, answers: ['clock', 'door', 'box', 'picture'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 38, type: 'extra-picts', hasImage: null, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/vocab', instructions: null}, score: 0.0, questionName: '1', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a chair.', correction: null, askingSexMale: null, answers: ['chair'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 39, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/s01_a01_q005_vcb.jpg', instructions: null}, score: 0.0, questionName: '2', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a light.', correction: null, askingSexMale: null, answers: ['light', '[0::lamp]', '[0::bulb]'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 40, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/light_ceiling.jpg', instructions: null}, score: 0.0, questionName: '3', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 6, definition: {affirm: null, question: 'This $$$ a pen; it\'s a pencil.', answer: 'This isn\'t a pen; it\'s a pencil.', correction: null, askingSexMale: null, answers: ['isn\'t', 'it\'s', 'no is', 'is'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 43, type: 'extra-jumb', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '4', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'A village isn\'t large; it\'s $$$.', answer: 'A village isn\'t large; it\'s small.', correction: null, askingSexMale: null, answers: ['small', '[0::little]', '[0::tiny]'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 44, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '5', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'The pencil isn\'t $$$; it\'s short.', answer: 'The pencil isn\'t long; it\'s short.', correction: null, askingSexMale: null, answers: ['long'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 45, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '6', submitDate: null, updateDate: 1583145442893}
            ], deviceUUID: '5966e990-82ad-430c-ac54-0c85719ea079', prevUUID: null, lastUdpateDate: 1583145442893, startDate: 1583145442893};
        // tslint:disable-next-line:max-line-length
        res = {deviceUUID: '6BB0FD80-5BC3-44F1-B4EF-6DDE24C20141', id: '6BB0FD80-5BC3-44F1-B4EF-6DDE24C20141', score: 0, baseSessionQuestionNumber: 20, startDate: '2020-03-06T13:37:59+01:00',
            questions: [
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, London isn\'t a village; it\'s a city.', affirm: '', correctAnswer: '', correction: 'No, ... village; ...', question: 'Is London a village?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q001_qaa_', instructions: '', id: 1, type: 'question-answer', hasImage: 0}, score: 0, questionName: '1'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'A city\'s large.', affirm: '', correctAnswer: '', correction: 'A ...', question: 'Is a city large or small?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q002_qaa_', instructions: '', id: 2, type: 'question-answer', hasImage: 0}, score: 0, questionName: '2'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, I\'m not standing on the floor; I\'m sitting on the chair.', affirm: '', correctAnswer: '', correction: 'No, ... floor; ... the chair.', question: 'Are you standing on the floor?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q003_qaa_', instructions: '', id: 3, type: 'question-answer', hasImage: 0}, score: 0, questionName: '3'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of book is "books".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "book"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q004_qaa_', instructions: '', id: 4, type: 'question-answer', hasImage: 0}, score: 0, questionName: '4'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of "man" is "men".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "man"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q005_qaa_', instructions: '', id: 5, type: 'question-answer', hasImage: 0}, score: 0, questionName: '5'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of "woman" is "women".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "woman"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q006_qaa_', instructions: '', id: 6, type: 'question-answer', hasImage: 0}, score: 0, questionName: '6'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The five vowels are A E I O U.', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What are the five vowels?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q007_qaa_', instructions: '', id: 7, type: 'question-answer', hasImage: 0}, score: 0, questionName: '7'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, the letter B isn\'t a vowel; it\'s a consonant.', affirm: '', correctAnswer: '', correction: 'No, ... vowel; ...', question: 'Is the letter B a vowel?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q008_qaa_', instructions: '', id: 8, type: 'question-answer', hasImage: 0}, score: 0, questionName: '8'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'D\'s before E.', affirm: '', correctAnswer: '', correction: '... E.', question: 'Which letter\'s before E?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q009_qaa_', instructions: '', id: 9, type: 'question-answer', hasImage: 0}, score: 0, questionName: '9'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'H\'s after G.', affirm: '', correctAnswer: '', correction: '... G.', question: 'Which letter\'s after G?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q010_qaa_', instructions: '', id: 10, type: 'question-answer', hasImage: 0}, score: 0, questionName: '10'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'I\'s between H and J.', affirm: '', correctAnswer: '', correction: '... H and J.', question: 'Which letter\'s between H and J?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q011_qaa_', instructions: '', id: 11, type: 'question-answer', hasImage: 0}, score: 0, questionName: '11'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, I\'m not the teacher; I\'m a student.', affirm: '', correctAnswer: '', correction: 'No, ... teacher; I\'m ...', question: 'Are you the teacher?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q012_qaa_', instructions: '', id: 12, type: 'question-answer', hasImage: 0}, score: 0, questionName: '12'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: '13 plus 5 equals 18.', affirm: '', correctAnswer: '', correction: '... 18.', question: 'How much is 13 plus 5?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q013_qaa_', instructions: '', id: 13, type: 'question-answer', hasImage: 0}, score: 0, questionName: '13'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'Yes, there\'s a light on the ceiling.', affirm: '', correctAnswer: '', correction: 'Yes, ...', question: 'Is there a light on the ceiling?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q014_qaa_', instructions: '', id: 14, type: 'question-answer', hasImage: 0}, score: 0, questionName: '14'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, there isn\'t a clock on the table.', affirm: '', correctAnswer: '', correction: 'No, ...', question: 'Is there a clock on the table?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q015_qaa_', instructions: '', id: 15, type: 'question-answer', hasImage: 0}, score: 0, questionName: '15'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, there aren\'t a hundred pictures in this room; there are three pictures in this room.', affirm: '', correctAnswer: '', correction: 'No, ...; ... three ...', question: 'Are there a hundred pictures in this room?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q016_qaa_', instructions: '', id: 16, type: 'question-answer', hasImage: 0}, score: 0, questionName: '16'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, the wall isn\'t low; it\'s high.', affirm: '', correctAnswer: '', correction: 'No, ... low; ...', question: 'Is the wall low?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q017_qaa_', instructions: '', id: 17, type: 'question-answer', hasImage: 0}, score: 0, questionName: '17'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'London\'s the capital of England.', affirm: '', correctAnswer: '', correction: '... England.', question: 'What\'s the capital of England?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q018_qaa_', instructions: '', id: 18, type: 'question-answer', hasImage: 0}, score: 0, questionName: '18'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'Athens is the capital of Greece.', affirm: '', correctAnswer: '', correction: '... Greece.', question: 'What\'s the capital of Greece?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q019_qaa_', instructions: '', id: 19, type: 'question-answer', hasImage: 0}, score: 0, questionName: '19'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'There\'s one clock in this room.', affirm: '', correctAnswer: '', correction: '... one ...', question: 'How many clocks are there in this room?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q020_qaa_', instructions: '', id: 20, type: 'question-answer', hasImage: 0}, score: 0, questionName: '20'}]
                } as unknown as CasaExerciseSession;
        return of(res);
    }

    recreateSession(sessionId: number): Observable<CasaExerciseSession> {
        let res: CasaExerciseSession = {id: 2615704, score: 0.0, baseSessionQuestionNumber: 6,
            questions: [
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 3, definition: {affirm: null, question: null, answer: null, correction: null, askingSexMale: null, answers: ['clock', 'door', 'box', 'picture'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 38, type: 'extra-picts', hasImage: null, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/vocab', instructions: null}, score: 0.0, questionName: '1', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a chair.', correction: null, askingSexMale: null, answers: ['chair'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 39, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/s01_a01_q005_vcb.jpg', instructions: null}, score: 0.0, questionName: '2', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a light.', correction: null, askingSexMale: null, answers: ['light', '[0::lamp]', '[0::bulb]'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 40, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/light_ceiling.jpg', instructions: null}, score: 0.0, questionName: '3', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 6, definition: {affirm: null, question: 'This $$$ a pen; it\'s a pencil.', answer: 'This isn\'t a pen; it\'s a pencil.', correction: null, askingSexMale: null, answers: ['isn\'t', 'it\'s', 'no is', 'is'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 43, type: 'extra-jumb', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '4', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'A village isn\'t large; it\'s $$$.', answer: 'A village isn\'t large; it\'s small.', correction: null, askingSexMale: null, answers: ['small', '[0::little]', '[0::tiny]'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 44, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '5', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'The pencil isn\'t $$$; it\'s short.', answer: 'The pencil isn\'t long; it\'s short.', correction: null, askingSexMale: null, answers: ['long'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 45, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '6', submitDate: null, updateDate: 1583145442893}
            ], deviceUUID: '5966e990-82ad-430c-ac54-0c85719ea079', prevUUID: null, lastUdpateDate: 1583145442893, startDate: 1583145442893};
        // tslint:disable-next-line:max-line-length
        res = {deviceUUID: '6BB0FD80-5BC3-44F1-B4EF-6DDE24C20141', id: '6BB0FD80-5BC3-44F1-B4EF-6DDE24C20141', score: 0, baseSessionQuestionNumber: 20, startDate: '2020-03-06T13:37:59+01:00',
            questions: [
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, London isn\'t a village; it\'s a city.', affirm: '', correctAnswer: '', correction: 'No, ... village; ...', question: 'Is London a village?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q001_qaa_', instructions: '', id: 1, type: 'question-answer', hasImage: 0}, score: 0, questionName: '1'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'A city\'s large.', affirm: '', correctAnswer: '', correction: 'A ...', question: 'Is a city large or small?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q002_qaa_', instructions: '', id: 2, type: 'question-answer', hasImage: 0}, score: 0, questionName: '2'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, I\'m not standing on the floor; I\'m sitting on the chair.', affirm: '', correctAnswer: '', correction: 'No, ... floor; ... the chair.', question: 'Are you standing on the floor?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q003_qaa_', instructions: '', id: 3, type: 'question-answer', hasImage: 0}, score: 0, questionName: '3'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of book is "books".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "book"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q004_qaa_', instructions: '', id: 4, type: 'question-answer', hasImage: 0}, score: 0, questionName: '4'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of "man" is "men".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "man"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q005_qaa_', instructions: '', id: 5, type: 'question-answer', hasImage: 0}, score: 0, questionName: '5'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of "woman" is "women".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "woman"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q006_qaa_', instructions: '', id: 6, type: 'question-answer', hasImage: 0}, score: 0, questionName: '6'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The five vowels are A E I O U.', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What are the five vowels?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q007_qaa_', instructions: '', id: 7, type: 'question-answer', hasImage: 0}, score: 0, questionName: '7'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, the letter B isn\'t a vowel; it\'s a consonant.', affirm: '', correctAnswer: '', correction: 'No, ... vowel; ...', question: 'Is the letter B a vowel?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q008_qaa_', instructions: '', id: 8, type: 'question-answer', hasImage: 0}, score: 0, questionName: '8'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'D\'s before E.', affirm: '', correctAnswer: '', correction: '... E.', question: 'Which letter\'s before E?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q009_qaa_', instructions: '', id: 9, type: 'question-answer', hasImage: 0}, score: 0, questionName: '9'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'H\'s after G.', affirm: '', correctAnswer: '', correction: '... G.', question: 'Which letter\'s after G?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q010_qaa_', instructions: '', id: 10, type: 'question-answer', hasImage: 0}, score: 0, questionName: '10'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'I\'s between H and J.', affirm: '', correctAnswer: '', correction: '... H and J.', question: 'Which letter\'s between H and J?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q011_qaa_', instructions: '', id: 11, type: 'question-answer', hasImage: 0}, score: 0, questionName: '11'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, I\'m not the teacher; I\'m a student.', affirm: '', correctAnswer: '', correction: 'No, ... teacher; I\'m ...', question: 'Are you the teacher?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q012_qaa_', instructions: '', id: 12, type: 'question-answer', hasImage: 0}, score: 0, questionName: '12'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: '13 plus 5 equals 18.', affirm: '', correctAnswer: '', correction: '... 18.', question: 'How much is 13 plus 5?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q013_qaa_', instructions: '', id: 13, type: 'question-answer', hasImage: 0}, score: 0, questionName: '13'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'Yes, there\'s a light on the ceiling.', affirm: '', correctAnswer: '', correction: 'Yes, ...', question: 'Is there a light on the ceiling?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q014_qaa_', instructions: '', id: 14, type: 'question-answer', hasImage: 0}, score: 0, questionName: '14'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, there isn\'t a clock on the table.', affirm: '', correctAnswer: '', correction: 'No, ...', question: 'Is there a clock on the table?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q015_qaa_', instructions: '', id: 15, type: 'question-answer', hasImage: 0}, score: 0, questionName: '15'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, there aren\'t a hundred pictures in this room; there are three pictures in this room.', affirm: '', correctAnswer: '', correction: 'No, ...; ... three ...', question: 'Are there a hundred pictures in this room?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q016_qaa_', instructions: '', id: 16, type: 'question-answer', hasImage: 0}, score: 0, questionName: '16'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, the wall isn\'t low; it\'s high.', affirm: '', correctAnswer: '', correction: 'No, ... low; ...', question: 'Is the wall low?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q017_qaa_', instructions: '', id: 17, type: 'question-answer', hasImage: 0}, score: 0, questionName: '17'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'London\'s the capital of England.', affirm: '', correctAnswer: '', correction: '... England.', question: 'What\'s the capital of England?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q018_qaa_', instructions: '', id: 18, type: 'question-answer', hasImage: 0}, score: 0, questionName: '18'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'Athens is the capital of Greece.', affirm: '', correctAnswer: '', correction: '... Greece.', question: 'What\'s the capital of Greece?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q019_qaa_', instructions: '', id: 19, type: 'question-answer', hasImage: 0}, score: 0, questionName: '19'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'There\'s one clock in this room.', affirm: '', correctAnswer: '', correction: '... one ...', question: 'How many clocks are there in this room?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q020_qaa_', instructions: '', id: 20, type: 'question-answer', hasImage: 0}, score: 0, questionName: '20'}]
                } as unknown as CasaExerciseSession;

        return of(res);
    }
    getSessionData(sessionId: number): Observable<CasaExerciseSession> {
        let res: CasaExerciseSession = {id: 2615704, score: 0.0, baseSessionQuestionNumber: 6,
            questions: [
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 3, definition: {affirm: null, question: null, answer: null, correction: null, askingSexMale: null, answers: ['clock', 'door', 'box', 'picture'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 38, type: 'extra-picts', hasImage: null, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/vocab', instructions: null}, score: 0.0, questionName: '1', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a chair.', correction: null, askingSexMale: null, answers: ['chair'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 39, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/s01_a01_q005_vcb.jpg', instructions: null}, score: 0.0, questionName: '2', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a light.', correction: null, askingSexMale: null, answers: ['light', '[0::lamp]', '[0::bulb]'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 40, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/light_ceiling.jpg', instructions: null}, score: 0.0, questionName: '3', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 6, definition: {affirm: null, question: 'This $$$ a pen; it\'s a pencil.', answer: 'This isn\'t a pen; it\'s a pencil.', correction: null, askingSexMale: null, answers: ['isn\'t', 'it\'s', 'no is', 'is'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 43, type: 'extra-jumb', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '4', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'A village isn\'t large; it\'s $$$.', answer: 'A village isn\'t large; it\'s small.', correction: null, askingSexMale: null, answers: ['small', '[0::little]', '[0::tiny]'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 44, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '5', submitDate: null, updateDate: 1583145442893},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 7, definition: {affirm: null, question: 'The pencil isn\'t $$$; it\'s short.', answer: 'The pencil isn\'t long; it\'s short.', correction: null, askingSexMale: null, answers: ['long'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 45, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null}, score: 0.0, questionName: '6', submitDate: null, updateDate: 1583145442893}
            ], deviceUUID: '5966e990-82ad-430c-ac54-0c85719ea079', prevUUID: null, lastUdpateDate: 1583145442893, startDate: 1583145442893};

        // tslint:disable-next-line:max-line-length
        res = {deviceUUID: '6BB0FD80-5BC3-44F1-B4EF-6DDE24C20141', id: '6BB0FD80-5BC3-44F1-B4EF-6DDE24C20141', score: 0, baseSessionQuestionNumber: 20, startDate: '2020-03-06T13:37:59+01:00',
            questions: [
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, London isn\'t a village; it\'s a city.', affirm: '', correctAnswer: '', correction: 'No, ... village; ...', question: 'Is London a village?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q001_qaa_', instructions: '', id: 1, type: 'question-answer', hasImage: 0}, score: 0, questionName: '1'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'A city\'s large.', affirm: '', correctAnswer: '', correction: 'A ...', question: 'Is a city large or small?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q002_qaa_', instructions: '', id: 2, type: 'question-answer', hasImage: 0}, score: 0, questionName: '2'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, I\'m not standing on the floor; I\'m sitting on the chair.', affirm: '', correctAnswer: '', correction: 'No, ... floor; ... the chair.', question: 'Are you standing on the floor?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q003_qaa_', instructions: '', id: 3, type: 'question-answer', hasImage: 0}, score: 0, questionName: '3'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of book is "books".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "book"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q004_qaa_', instructions: '', id: 4, type: 'question-answer', hasImage: 0}, score: 0, questionName: '4'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of "man" is "men".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "man"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q005_qaa_', instructions: '', id: 5, type: 'question-answer', hasImage: 0}, score: 0, questionName: '5'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The plural of "woman" is "women".', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What\'s the plural of "woman"?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q006_qaa_', instructions: '', id: 6, type: 'question-answer', hasImage: 0}, score: 0, questionName: '6'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'The five vowels are A E I O U.', affirm: '', correctAnswer: '', correction: 'The ...', question: 'What are the five vowels?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q007_qaa_', instructions: '', id: 7, type: 'question-answer', hasImage: 0}, score: 0, questionName: '7'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, the letter B isn\'t a vowel; it\'s a consonant.', affirm: '', correctAnswer: '', correction: 'No, ... vowel; ...', question: 'Is the letter B a vowel?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q008_qaa_', instructions: '', id: 8, type: 'question-answer', hasImage: 0}, score: 0, questionName: '8'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'D\'s before E.', affirm: '', correctAnswer: '', correction: '... E.', question: 'Which letter\'s before E?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q009_qaa_', instructions: '', id: 9, type: 'question-answer', hasImage: 0}, score: 0, questionName: '9'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'H\'s after G.', affirm: '', correctAnswer: '', correction: '... G.', question: 'Which letter\'s after G?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q010_qaa_', instructions: '', id: 10, type: 'question-answer', hasImage: 0}, score: 0, questionName: '10'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'I\'s between H and J.', affirm: '', correctAnswer: '', correction: '... H and J.', question: 'Which letter\'s between H and J?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q011_qaa_', instructions: '', id: 11, type: 'question-answer', hasImage: 0}, score: 0, questionName: '11'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, I\'m not the teacher; I\'m a student.', affirm: '', correctAnswer: '', correction: 'No, ... teacher; I\'m ...', question: 'Are you the teacher?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q012_qaa_', instructions: '', id: 12, type: 'question-answer', hasImage: 0}, score: 0, questionName: '12'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: '13 plus 5 equals 18.', affirm: '', correctAnswer: '', correction: '... 18.', question: 'How much is 13 plus 5?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q013_qaa_', instructions: '', id: 13, type: 'question-answer', hasImage: 0}, score: 0, questionName: '13'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'Yes, there\'s a light on the ceiling.', affirm: '', correctAnswer: '', correction: 'Yes, ...', question: 'Is there a light on the ceiling?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q014_qaa_', instructions: '', id: 14, type: 'question-answer', hasImage: 0}, score: 0, questionName: '14'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, there isn\'t a clock on the table.', affirm: '', correctAnswer: '', correction: 'No, ...', question: 'Is there a clock on the table?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q015_qaa_', instructions: '', id: 15, type: 'question-answer', hasImage: 0}, score: 0, questionName: '15'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, there aren\'t a hundred pictures in this room; there are three pictures in this room.', affirm: '', correctAnswer: '', correction: 'No, ...; ... three ...', question: 'Are there a hundred pictures in this room?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q016_qaa_', instructions: '', id: 16, type: 'question-answer', hasImage: 0}, score: 0, questionName: '16'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'No, the wall isn\'t low; it\'s high.', affirm: '', correctAnswer: '', correction: 'No, ... low; ...', question: 'Is the wall low?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q017_qaa_', instructions: '', id: 17, type: 'question-answer', hasImage: 0}, score: 0, questionName: '17'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'London\'s the capital of England.', affirm: '', correctAnswer: '', correction: '... England.', question: 'What\'s the capital of England?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q018_qaa_', instructions: '', id: 18, type: 'question-answer', hasImage: 0}, score: 0, questionName: '18'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'Athens is the capital of Greece.', affirm: '', correctAnswer: '', correction: '... Greece.', question: 'What\'s the capital of Greece?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q019_qaa_', instructions: '', id: 19, type: 'question-answer', hasImage: 0}, score: 0, questionName: '19'},
                // tslint:disable-next-line:max-line-length
                {def: {typeId: 1, definition: {answer: 'There\'s one clock in this room.', affirm: '', correctAnswer: '', correction: '... one ...', question: 'How many clocks are there in this room?', answers: [], wrongAnswers: [], askingSexMale: 1, hasImage: 0}, resourceBase: 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q020_qaa_', instructions: '', id: 20, type: 'question-answer', hasImage: 0}, score: 0, questionName: '20'}]
                } as unknown as CasaExerciseSession;
        return of(res);
    }

    private selectSessionQuestionDefinition(questionId): CasaSessionQuestionDefinition {
        let res = new CasaSessionQuestionDefinition();
        res.chapterName = 'chapter name';
        res.exerciseName = 'exercise name';
        switch (questionId) {
            // tslint:disable-next-line:max-line-length
            case 38:
                // tslint:disable-next-line:max-line-length
                res.qDef = {typeId: 3, definition: {affirm: null, question: null, answer: null, correction: null, askingSexMale: null, answers: ['clock', 'door', 'box', 'picture'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 38, type: 'extra-picts', hasImage: null, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/vocab', instructions: null};
                break;
            case 39:
                // tslint:disable-next-line:max-line-length
                res.qDef = {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a chair.', correction: null, askingSexMale: null, answers: ['chair'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 39, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/s01_a01_q005_vcb.jpg', instructions: null};
                break;
            case 40:
                // tslint:disable-next-line:max-line-length
                res.qDef = {typeId: 7, definition: {affirm: null, question: 'This is a $$$.', answer: 'This is a light.', correction: null, askingSexMale: null, answers: ['light', '[0::lamp]', '[0::bulb]'], wrongAnswers: null, hasImage: true, correctAnswer: null}, id: 40, type: 'extra-fill-multi', hasImage: true, resourceBase: 'https://s3.amazonaws.com/callan_assets/images/stages/stage_01/vcb/light_ceiling.jpg', instructions: null};
                break;
            case 43:
                // tslint:disable-next-line:max-line-length
                res.qDef = {typeId: 6, definition: {affirm: null, question: 'This $$$ a pen; it\'s a pencil.', answer: 'This isn\'t a pen; it\'s a pencil.', correction: null, askingSexMale: null, answers: ['isn\'t', 'it\'s', 'no is', 'is'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 43, type: 'extra-jumb', hasImage: null, resourceBase: null, instructions: null};
                break;
            case 44:
                // tslint:disable-next-line:max-line-length
                res.qDef = {typeId: 7, definition: {affirm: null, question: 'A village isn\'t large; it\'s $$$.', answer: 'A village isn\'t large; it\'s small.', correction: null, askingSexMale: null, answers: ['small', '[0::little]', '[0::tiny]'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 44, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null};
                break;
            case 45:
                // tslint:disable-next-line:max-line-length
                res.qDef = {typeId: 7, definition: {affirm: null, question: 'The pencil isn\'t $$$; it\'s short.', answer: 'The pencil isn\'t long; it\'s short.', correction: null, askingSexMale: null, answers: ['long'], wrongAnswers: null, hasImage: null, correctAnswer: null}, id: 45, type: 'extra-fill-multi', hasImage: null, resourceBase: null, instructions: null};
                break;
        }
        res = {
            nextQuestionType : 'question-answer',
            chapterName : 'Stage 1, Lesson 1-9',
            questionNumber : 0,
            qDef : {
              typeId : 1,
              definition : {
                answer : 'No, London isn\'t a village; it\'s a city.',
                affirm : '',
                correctAnswer : '',
                correction : 'No, ... village; ...',
                question : 'Is London a village?',
                answers : [

                ],
                wrongAnswers : [

                ],
                askingSexMale : 1,
                hasImage : 0
              },
              resourceBase : 'https:\/\/s3.amazonaws.com\/callan_assets\/audio\/stages\/stage_01\/qaa\/s01_a01_q001_qaa_',
              instructions : '',
              id : 1,
              type : 'question-answer',
              hasImage : 0
            },
            nextQuestionId : 2,
            exerciseName : 'Set 1',
            questions : 20
        } as unknown as CasaSessionQuestionDefinition;
        return res;
    }

    getSessionQuestionDefinition(sessionId: number, questionId: number): Observable<CasaSessionQuestionDefinition> {
        const res: CasaSessionQuestionDefinition = {} as CasaSessionQuestionDefinition;
        return of(this.selectSessionQuestionDefinition(questionId));
    }

    submitSessionScore(sessionId: number, questionId: number, submit: CasaQuestionScoreSubmit): Observable<boolean> {
        return of(true);
    }
    recalculateScore(): Observable<void> {
        return empty();
    }

    log(text: string) {
        if (environment.debug) {
          console.log(text);
        }
    }
    closeQuestions(): Observable<boolean> {
        this.log('has finish event');
        return of(true);
    }
}
