/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pictures.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./picture-card/picture-card.component.ngfactory";
import * as i3 from "./picture-card/picture-card.component";
import * as i4 from "@angular/common";
import * as i5 from "./pictures.component";
var styles_PicturesComponent = [i0.styles];
var RenderType_PicturesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PicturesComponent, data: {} });
export { RenderType_PicturesComponent as RenderType_PicturesComponent };
function View_PicturesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col mb-2 px-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-picture-card", [], null, [[null, "cardClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cardClick" === en)) {
        var pd_0 = (_co.onCardClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PictureCardComponent_0, i2.RenderType_PictureCardComponent)), i1.ɵdid(2, 114688, null, 0, i3.PictureCardComponent, [], { data: [0, "data"] }, { cardClick: "cardClick" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PicturesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row row-cols-3 row-cols-md-4 ex-image text-dark no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PicturesComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pictures; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PicturesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pictures", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PicturesComponent_0, RenderType_PicturesComponent)), i1.ɵdid(1, 245760, null, 0, i5.PicturesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PicturesComponentNgFactory = i1.ɵccf("app-pictures", i5.PicturesComponent, View_PicturesComponent_Host_0, {}, { finish: "finish" }, []);
export { PicturesComponentNgFactory as PicturesComponentNgFactory };
