/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./menu-layout.component";
import * as i6 from "../../services/auth.service";
import * as i7 from "../../services/casa-rest.service";
import * as i8 from "../../services/menu-controls.service";
import * as i9 from "../../services/rest-provider.service";
var styles_MenuLayoutComponent = [i0.styles];
var RenderType_MenuLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuLayoutComponent, data: { "animation": [{ type: 7, name: "showHide", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { right: "0px" }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { right: "-260px", display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: [{ type: 4, styles: null, timings: "0.2s ease-in" }], options: null }, { type: 1, expr: "hide => show", animation: [{ type: 6, styles: { display: "block" }, offset: null }, { type: 4, styles: null, timings: "0.2s ease-out" }], options: null }], options: {} }, { type: 7, name: "showDim", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { opacity: 0, display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: [{ type: 4, styles: null, timings: "0.2s ease-in" }], options: null }, { type: 1, expr: "hide => show", animation: [{ type: 6, styles: { display: "block" }, offset: null }, { type: 4, styles: null, timings: "0.2s ease-out" }], options: null }], options: {} }] } });
export { RenderType_MenuLayoutComponent as RenderType_MenuLayoutComponent };
function View_MenuLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nav", [["class", "navbar-dark menu-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "custom navbar-toggler text-light"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null))], null, null); }
function View_MenuLayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "nav-link"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToCol() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Open Callan Online"]))], function (_ck, _v) { var currVal_0 = "layout.menu.open-col"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MenuLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["style", "position:relative"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuLayoutComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "side-bar-dim"]], [[24, "@showDim", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "side-bar bg-primary px-3 py-2"]], [[24, "@showHide", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close text-white p-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "text-white mt-4"], ["style", "overflow: hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " (", ")"])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "ul", [["class", "navbar-nav ml-auto mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuLayoutComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["class", "nav-link"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Logout"])), (_l()(), i1.ɵeld(18, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(19, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuEnabled(); _ck(_v, 2, 0, currVal_0); var currVal_5 = _co.hasCol(); _ck(_v, 13, 0, currVal_5); var currVal_6 = "layout.menu.logout"; _ck(_v, 16, 0, currVal_6); _ck(_v, 19, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isShowMenu() ? "show" : "hide"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.isShowMenu() ? "show" : "hide"); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.getName(); var currVal_4 = _co.getEmail(); _ck(_v, 10, 0, currVal_3, currVal_4); }); }
export function View_MenuLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-layout", [], null, null, null, View_MenuLayoutComponent_0, RenderType_MenuLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i5.MenuLayoutComponent, [i6.AuthService, i7.CasaRestServiceMain, i8.MenuControlsService, i9.ApiServiceProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuLayoutComponentNgFactory = i1.ɵccf("app-menu-layout", i5.MenuLayoutComponent, View_MenuLayoutComponent_Host_0, {}, {}, []);
export { MenuLayoutComponentNgFactory as MenuLayoutComponentNgFactory };
