<div>
  <div class="card speach-bubble question mt-2 mb-3" appKidsRotation="5.0">
    <div class="no-gutters row">
      <div class="col-md-4 d-flex">
        <img [src]="getImage()" class="card-img" [ngClass]="{avatar: isAvatar()}">
      </div>
      <div class="col p-3 text-dark">
        <h4 [innerHtml]="getQuestionContent()"></h4>
      </div>
    </div>
    <div class="card-footer text-right text-light">
      <a class="btn btn-primary mr-1" [ngClass]="{blink: isQuestionBlinking()}" (click)="playQuestion()" tabindex="" appKidsRotation><i class="fas fa-volume-up"></i></a>
      <a *ngIf="!isShowQuestionContent()" class="btn btn-secondary" (click)="turnQuestionContent()" tabindex="" appKidsRotation>
        <i  class="fas fa-align-left"></i> 
      </a>
      <a *ngIf="isShowQuestionContent()"  class="btn btn-secondary" (click)="turnQuestionContent()" tabindex="" appKidsRotation>
        <i class="fas fa-align-left"></i> 
      </a>
      
    </div>
  </div>
  <div *ngIf="isShowAnswer()" >
    <div class="card speach-bubble answer my-3" appKidsRotation="5.0">
      <div class=" card-body text-dark">
        <h4 [innerHtml]="getAnswerContent()"> - No, London isn't a vilage. It's a city.</h4>
      </div>
      
      <div class="card-footer text-right text-light">
        <a *ngIf="hasRecorder() && !isRecording()" class="btn btn-danger mr-1" (click)="recordClicked()" tabindex="" appKidsRotation><i class="fas fa-microphone"></i></a>
        <a *ngIf="hasRecorder() && isRecording()" class="btn btn-danger mr-1 blink" (click)="stopRecording()" tabindex="" appKidsRotation><i class="fas fa-stop-circle"></i></a>
        <a *ngIf="isShowPlay()" class="btn btn-danger mr-1" (click)="play()" tabindex="" appKidsRotation><i class="fas fa-play-circle"></i></a>
        <a class="btn btn-primary mr-1 ml-1" [ngClass]="{blink: isAnswerBlinking()}" tabindex="" (click)="playAnswer()" appKidsRotation><i class="fas  fa-volume-up"></i></a>

        <a *ngIf="!isShowAnswerContent()" class="btn btn-secondary" tabindex="" (click)="turnAnswerContent()" appKidsRotation><i class="fas fa-align-left"></i></a>
        <a *ngIf="isShowAnswerContent()" class="btn btn-secondary" tabindex="" (click)="turnAnswerContent()" appKidsRotation><i class="fas fa-align-left"></i></a>
        <audio #audioTarget>
          
        </audio>
      </div>
    </div>
  </div>
</div>