<div class="pt-2">
    <div class="container">
        <app-top-bar [title]="getTopBarTitle()" (closeClick)="closeExerciseSet()"></app-top-bar>
        <div class="mt-2 row align-items-end no-gutters ">
            <div class="stage-list-container col">
              <div class="stage-list">
                <app-chapter-progress-circle
                    *ngFor="let avChapter of getAvailabileChapters()"
                    [chapter]="avChapter"
                    [availability]="getChapterAvailability(avChapter)"
                    (chapterClick)="onChapterClick(avChapter)"
                    [ngClass]="getChapterSelectionClass(avChapter)">
                </app-chapter-progress-circle>
              </div>
            </div>

        </div>
        <div class="text-center mt-2">
            <div class="row no-gutters align-items-center">
                <div class="col-auto col-sm-2 text-right">
                    <a [hidden]="!hasPrev()" class="stage-btn cursor-pointer" (click)="onFooterInteract('left')"  tabindex="" appKidsRotation><i class="fas fa-chevron-left"></i></a>
                </div>
                <div class="col text-center">
                    <h2>{{getChapterName()}}</h2>
                </div>
                <div class="col-auto col-sm-2 text-left">
                    <a [hidden]="!hasNext()" class="stage-btn cursor-pointer" (click)="onFooterInteract('right')"  tabindex="" appKidsRotation><i class="fas fa-chevron-right"></i></a>
                </div>
            </div>

            <app-chapter-details
                [chapter]="getSelectedChapter()"
                [availabilities]="getAvailabilities()"
            ></app-chapter-details>
        </div>

    </div>
</div>
