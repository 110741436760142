import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map, flatMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) { }
  filter(req: HttpRequest<any>): boolean {
    if (req.url.startsWith(environment.apiEndpoint)) { return true; }
    if (req.url.startsWith(environment.authEndpoint)) { return true; }
    if (req.url.startsWith(environment.casaTestingEndpoint)) {return true; }
    return false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      if (!this.filter(req)) {
        return next.handle(req);
      }

      return this.auth.readCurrentAuthData().pipe(
        map( tokenData => tokenData && tokenData.expiryDate && tokenData.expiryDate > new Date().getTime() ? tokenData.token : null),
        flatMap ( token => token ?
          next.handle(
            req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            })
          ) : next.handle(req))
      );
    }
}
