import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./rest-provider.service";
export class AuthGuardService {
    constructor(auth, apiProvider) {
        this.auth = auth;
        this.api = apiProvider.getRestServiceImplementation();
    }
    canActivate(route, state) {
        if (this.api.isNativeImplementation()) {
            return of(true);
        }
        return this.auth.readCurrentAuthData().pipe(map(authData => authData != null && authData.expiryDate &&
            authData.expiryDate > new Date().getTime() + AuthGuardService.timeMargin), tap(isAuthorised => {
            if (!isAuthorised) {
                this.auth.login(state.url);
            }
        }));
    }
}
AuthGuardService.timeMargin = 1000 * 60 * 60;
AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.ApiServiceProvider)); }, token: AuthGuardService, providedIn: "root" });
