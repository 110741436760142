import { MobileNativeApi, MobileNativeAudio } from '../MobileNativeApi';
import { CasaExerciseSession, CasaQuestionScoreSubmit, CasaSessionQuestionDefinition } from 'src/app/model/oldModel';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare global {
    interface Window {
        WebViewJavascriptBridge: any;
        WVJBCallbacks: any;
    }
}

interface Bridge {
    queue: {
        name: string,
        params: any,
        f: (...args: any[]) => any
        }[];
    registerHandlerHandler(name: string, f: (data: any, callback: (...args: any[]) => any) => void );
    callHandler(name: string, params: any, f: (...args: any[]) => any);
}

class TemporaryBridge implements Bridge {

    queue = [];
    log(text: string) {
        if (environment.debug) {
          console.log(text);
        }
    }
    callHandler(name: string, params: any, f: (...args: any[]) => any) {
        this.log(`ios native - adding the call ${name} to the temporary queue`);
        this.queue.push( {name, params, f} );
    }
    registerHandlerHandler(name: string, f: (data: any, callback: (...args: any[]) => any) => void ) {}
}

@Injectable({
    providedIn: 'root'
})
export class IosNativeApi implements MobileNativeApi, MobileNativeAudio {


    // queue of callbacks to resolve
    bridge: Bridge = new TemporaryBridge();
    // setup iOS bridge
    setupWebViewJavascriptBridge(callback: (bridge: any) => void): any {

        // got bridge ready, return it
        if (window.WebViewJavascriptBridge) {
            return callback(window.WebViewJavascriptBridge as Bridge);
        }

        // callbacks executed during initialization
        if (window.WVJBCallbacks) {
            return window.WVJBCallbacks.push(callback);
        }

        // prepare callbacks for initialization
        window.WVJBCallbacks = [callback];
        const WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout( _ => { document.documentElement.removeChild(WVJBIframe); } , 0);
    }

    constructor() {
        // wait after initialization, then replace old bridge with the new one - execute queued tasks if necesarry
        this.setupWebViewJavascriptBridge( bridge => {
            /*bridge.registerHandlerHandler('footerFinishClicked', (data, callback) => {
                callback();
            });
            bridge.registerHandlerHandler('footerHelpClicked', (data, callback) => {
                callback();
            });*/
            bridge.callHandler('initCspa', {}, () => {});

            this.log('replacing temporary bridge with the one provided. Executing enqueued tasks.');
            for (const call of this.bridge.queue) {
                this.log(`executing ${call.name} enqueued using provided bridge`);
                bridge.callHandler(call.name, call.params, call.f);
            }

            this.bridge = bridge;
        });
    }

    log(text: string) {
        if (environment.debug) {
          console.log(text);
        }
    }

    // ##### audio implementation ####
    clear(): Observable<void> {
        this.log('ios native - calling clear');
        return from(new Promise<void>( (resolve, reject) => {
            this.bridge.callHandler('clear', {}, () => {
                resolve(null);
            } );
        }));
    }

    record() {
        this.log('ios native - calling record');
        this.bridge.callHandler('record', {}, () => {} );
    }
    stop(): Observable<void> {
        this.log('ios native - calling stop');
        return from(new Promise<void>( (resolve, reject) => {
            this.bridge.callHandler('stop', {}, () => {
                resolve(null);
            } );
        }));
    }

    play2() {
        this.log('ios native - calling play');
        this.bridge.callHandler('play', {}, () => {} );
    }
    terminate() {
        this.log('ios native - calling stop in terminate');
        this.bridge.callHandler('stop', {}, () => {} );
    }

    initAudio(callback: (state: boolean) => void) {
        this.log('ios native - initializing audio');

        this.bridge.callHandler('initAudio', {}, audioStatus => {
            this.log('ios native - audio initialized - invoking callback');
            callback(audioStatus);
        });
    }

    // #### api implementation #####
    recreateSession(sessionId: number): Observable<CasaExerciseSession> {
        return from(new Promise<CasaExerciseSession>( (resolve, reject) => {
            this.bridge.callHandler('recreateSession', {args: {sessionId}}, res => {
                resolve(JSON.parse(res));
            });
        }));
    }

    getSessionData(sessionId: number): Observable<CasaExerciseSession> {
        return from(new Promise<CasaExerciseSession>( (resolve, reject) => {
            this.bridge.callHandler('getSessionData', {args: {sessionId}}, res => {
                resolve(JSON.parse(res));
            });
        }));
    }

    getSessionQuestionDefinition(sessionId: number, questionId: number): Observable<CasaSessionQuestionDefinition> {
        return from(new Promise<CasaSessionQuestionDefinition>( (resolve, reject) => {
            this.bridge.callHandler('getSessionQuestionDefinition', {args: {sessionId, questionId}}, res => {
                resolve(JSON.parse(res));
            });
        }));
    }

    submitSessionScore(sessionId: number, questionId: number, submit: CasaQuestionScoreSubmit): Observable<boolean> {
        return from(new Promise<boolean>( (resolve, reject) => {
            this.log(`ios native - submiting session score for session ${sessionId} question ${questionId} with score ${submit.score}`);
            this.bridge.callHandler('submitSessionScore', {args: {sessionId, questionId}, data: submit}, res => {
                resolve(JSON.parse(res));
            });
        }));
    }

    recalculateScore(): Observable<void> {
        return from(new Promise<void>( (resolve, reject) => {
            this.bridge.callHandler('recalculateScore', {}, res => {
                resolve();
            });
        }));
    }

    closeQuestions(): Observable<boolean> {
        return from(new Promise<boolean>( (resolve, reject) => {
            this.bridge.callHandler('closeQuestions', {}, res => {
                resolve(JSON.parse(res));
            });
        }));
    }

    createSession(exerciseId: number): Observable<CasaExerciseSession> {
        this.log(`ios native - create session for exercise ${exerciseId}`);

        const promise = new Promise<CasaExerciseSession>( (resolve, reject) => {
            this.bridge.callHandler('createSession', {args: {exerciseId}}, res => {
                resolve(JSON.parse(res));
            });
        });

        return from(promise);
    }
}
