/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dictation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../modal/modal.component.ngfactory";
import * as i6 from "../../modal/modal.component";
import * as i7 from "./dictation.component";
var styles_DictationComponent = [i0.styles];
var RenderType_DictationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DictationComponent, data: {} });
export { RenderType_DictationComponent as RenderType_DictationComponent };
function View_DictationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.kind; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.content; _ck(_v, 3, 0, currVal_1); }); }
export function View_DictationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { summaryModal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "card-deck mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "card d-none d-sm-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["videoPlayer", 1]], null, 1, "video", [["class", "card-img-top"], ["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 19, "div", [["class", "card answer-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "card-body position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "answer-container text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "textarea", [["autocapitalize", "off"], ["autocomplete", "off"], ["autocorrect", "off"], ["class", "answer form-control h-100"], ["spellcheck", "false"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.answer.answer = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(13, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 9, "div", [["class", "card-footer text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "row justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "col d-sm-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, [["audioPlayer", 1]], null, 1, "audio", [["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "source", [["type", "audio/mp3"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "d-block d-sm-none mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doFinish() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Check"])), (_l()(), i1.ɵeld(25, 0, null, null, 19, "app-modal", [], null, null, null, i5.View_ModalComponent_0, i5.RenderType_ModalComponent)), i1.ɵdid(26, 114688, [[1, 4], ["summaryModal", 4]], 0, i6.ModalComponent, [], null, null), (_l()(), i1.ɵeld(27, 0, null, 0, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 6, "div", [["class", "modal-header text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(30, null, ["", " (", "%)"])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(32, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToCorrection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(35, 0, null, null, 2, "div", [["class", "modal-body text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DictationComponent_1)), i1.ɵdid(37, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(38, 0, null, null, 6, "div", [["class", "modal-footer text-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 2, "a", [["class", "btn btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToCorrection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Correct mistakes"])), (_l()(), i1.ɵeld(42, 0, null, null, 2, "a", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitAnswer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(43, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Continue"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.answer.answer; _ck(_v, 11, 0, currVal_9); var currVal_11 = "component.questions.dict.action.check"; _ck(_v, 23, 0, currVal_11); _ck(_v, 26, 0); var currVal_14 = _co.summary; _ck(_v, 37, 0, currVal_14); var currVal_15 = "component.questions.dict.action.correct"; _ck(_v, 40, 0, currVal_15); var currVal_16 = "component.questions.dict.action.continue"; _ck(_v, 43, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getVideoSource(); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 14).transform("component.questions.dict.form.write-here")), ""); var currVal_2 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 13).ngClassValid; var currVal_7 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = _co.getAudioSource(); _ck(_v, 19, 0, currVal_10); var currVal_12 = i1.ɵunv(_v, 30, 0, i1.ɵnov(_v, 31).transform("component.questions.dict.summary")); var currVal_13 = _co.getScore(); _ck(_v, 30, 0, currVal_12, currVal_13); }); }
export function View_DictationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dictation", [], null, null, null, View_DictationComponent_0, RenderType_DictationComponent)), i1.ɵdid(1, 245760, null, 0, i7.DictationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DictationComponentNgFactory = i1.ɵccf("app-dictation", i7.DictationComponent, View_DictationComponent_Host_0, {}, { finish: "finish" }, []);
export { DictationComponentNgFactory as DictationComponentNgFactory };
