import { AnswerDefinitionBase, QuestionDefinitionBase, Question } from '../../model/questions';
import { ExerciseSessionQuestion } from '../../model/personal';
import { ReplaySubject, Observable, Subscription } from 'rxjs';
import { OnDestroy, Output, EventEmitter } from '@angular/core';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


export class AnswerWrapper<T extends AnswerDefinitionBase> {
    constructor(public answer: T) {}
}

/**
 * Base component for any question. TO ADD A NEW COMPONENT REMEBER:
 * - add the definition to the QuestionComponentProviderService
 * - add the component to the AppModules.entryComponents
 */
export abstract class QuestionComponentBase<A extends AnswerDefinitionBase, Q extends QuestionDefinitionBase<A>> implements OnDestroy {


    // data will be provided by hosting component
    public questionSubject = new ReplaySubject<ExerciseSessionQuestion<A, Q>>(1);
    public question: ExerciseSessionQuestion<A, Q>;
    private subscription: Subscription;
    public allowNext = true;

    @Output() finish = new EventEmitter<void>();

    public doFinish() {
        this.finish.emit();
    }

    log(text: any) {
        if (environment.debug) {
          console.log(text);
        }
    }

    getInstructions() {
        if (!this.question) {
            return '';
        }
        return this.question.question.definition.instructions;
    }

    abstract getAnswerForSubmit(): Observable<AnswerWrapper<A>>;

    public setupAnswer(answer: A) {
    }

    constructor() {
        this.subscription = this.questionSubject.pipe(
            filter( question => question != null)
        ).subscribe(question => {
            this.log('question base - got session question row - setting up question and answer');
            this.log(question);
            this.question = question;
            this.setupAnswer(question.answer);
        });
    }

    /**
     * Action on middle button click
     */
    onMiddleClick() {
    }

    /**
     * Content of the middile button
     */
    getMiddleDescription(): string {
        return null;
    }

    ngOnDestroy(): void {
       this.subscription.unsubscribe();
    }
}



