import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { tap, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ExerciseSession } from 'src/app/model/personal';
import { SessionQuestionHostComponent } from '../session-question-host/session-question-host.component';
import { SessionDataHolderService } from 'src/app/services/session-data-holder.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';

@Component({
  selector: 'app-session-host',
  templateUrl: './session-host.component.html',
  styleUrls: ['./session-host.component.scss']
})
export class SessionHostComponent implements OnInit {

  exerciseSet: string;
  chapter: string;
  section: string;
  exercise: string;
  sessionId: string;
  session: ExerciseSession;
  private rest: CspaRestService;

  constructor(private activatedRoute: ActivatedRoute,
              restProvider: ApiServiceProvider,
              private router: Router,
              private sessionDataHolder: SessionDataHolderService,
              theme: ThemeService) {
    this.rest = restProvider.getRestServiceImplementation();
    activatedRoute.paramMap.pipe(
      tap(
        params => {
          this.exerciseSet = params.get('exerciseSet');
          this.chapter = params.get('chapter');
          this.section = params.get('section');
          this.exercise = params.get('exercise');
          this.sessionId = params.get('sessionId');
          theme.chapter.next(this.chapter);
          theme.exerciseSet.next(this.exerciseSet);
        }
      ),
      switchMap( _ => this.loadSession()),
      tap( session => this.sessionDataHolder.updateSession(session)),
      tap( session => this.session = session)
    ).subscribe(
      session => this.startQuestionIfRequired()
    );
  }

  startQuestionIfRequired(): void {
    if (this.activatedRoute.children.length === 0) {
      this.router.navigate(
        ['exercises', this.exerciseSet, this.chapter, this.section, this.exercise, 'session', this.sessionId, 'question', '0']
      );
    }
  }

  loadSession(): Observable<ExerciseSession> {
    return this.rest.getExerciseSession(this.sessionId);
  }

  ngOnInit() {
  }

}
