import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { WebStorageModule } from 'ngx-store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OauthComponent } from './pages/oauth/oauth.component';
import { ExercisesComponent } from './pages/exercises/exercises.component';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { ExerciseSetDetailsComponent } from './components/exercise-set-details/exercise-set-details.component';
import { ExerciseSetHostComponent } from './components/exercise-set-host/exercise-set-host.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { ExerciseBoxComponent } from './components/exercise-box/exercise-box.component';
import { ChapterProgressCircleComponent } from './components/chapter-progress-circle/chapter-progress-circle.component';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { ChapterDetailsComponent } from './components/chapter-details/chapter-details.component';
import { StartExerciseComponent } from './pages/start-exercise/start-exercise.component';
import { SessionHostComponent } from './components/session-host/session-host.component';
import { SessionQuestionHostComponent } from './components/session-question-host/session-question-host.component';
import { QuestionAnswerComponent } from './components/questions/question-answer/question-answer.component';
import { DictationComponent } from './components/questions/dictation/dictation.component';
import { DynamicHostDirective } from './directives/dynamic-host.directive';
import { ModalComponent } from './components/modal/modal.component';
import { CorrectionComponent } from './components/questions/correction/correction.component';
import { FillMultiComponent } from './components/questions/fill-multi/fill-multi.component';
import { JumbleComponent } from './components/questions/jumble/jumble.component';
import { PicturesComponent } from './components/questions/pictures/pictures.component';
import { PictureCardComponent } from './components/questions/pictures/picture-card/picture-card.component';
import { SessionSummaryComponent } from './pages/session-summary/session-summary.component';
import { KidsRotationDirective } from './directives/kids-rotation.directive';
import { MenuLayoutComponent } from './layout/menu-layout/menu-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { TokenAuthComponent } from './pages/token-auth/token-auth.component';
import { CfkHomeComponent } from './pages/cfk-home/cfk-home.component';
import { MobileStartExerciseComponent } from './mobile/pages/mobile-start-exercise/mobile-start-exercise.component';
import { MobileSessionHostComponent } from './mobile/components/mobile-session-host/mobile-session-host.component';
import { MobileSessionQuestionHostComponent
} from './mobile/components/mobile-session-question-host/mobile-session-question-host.component';
import { MobileSessionSummaryComponent } from './mobile/pages/mobile-session-summary/mobile-session-summary.component';
import { EntryPointExerciseSetComponent } from './pages/entry-point-exercise-set/entry-point-exercise-set.component';
import { CloseComponent } from './pages/close/close.component';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import {Router} from "@angular/router";
import {environment} from "../environments/environment";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { HoldingModalComponent } from './components/holding-modal/holding-modal.component';


Sentry.init({
  dsn: "https://e0441a451462499fa0bad9341d2cd1fe@o334018.ingest.sentry.io/5598636",
  environment: environment.envName,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://cspa-dev.callanonline.com", "https://api.cspa.callanonline.com"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ]
});

// AoT, loader factory for ngx/translations
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/_', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    OauthComponent,
    ExercisesComponent,
    ExerciseSetDetailsComponent,
    ExerciseSetHostComponent,
    TopBarComponent,
    ExerciseBoxComponent,
    ChapterProgressCircleComponent,
    FooterBarComponent,
    ChapterDetailsComponent,
    StartExerciseComponent,
    SessionHostComponent,
    SessionQuestionHostComponent,
    QuestionAnswerComponent,
    DictationComponent,
    DynamicHostDirective,
    ModalComponent,
    CorrectionComponent,
    FillMultiComponent,
    JumbleComponent,
    PicturesComponent,
    PictureCardComponent,
    SessionSummaryComponent,
    KidsRotationDirective,
    MenuLayoutComponent,
    HomeComponent,
    TokenAuthComponent,
    CfkHomeComponent,
    MobileStartExerciseComponent,
    MobileSessionHostComponent,
    MobileSessionQuestionHostComponent,
    MobileSessionSummaryComponent,
    EntryPointExerciseSetComponent,
    CloseComponent,
    HoldingModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    WebStorageModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader : {
        provide : TranslateLoader,
        useFactory : (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage : 'en'
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    QuestionAnswerComponent,
    DictationComponent,
    FillMultiComponent,
    CorrectionComponent,
    JumbleComponent,
    PicturesComponent
  ]
})
export class AppModule { }
