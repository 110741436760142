import { AnswerDefinitionBase, QaAnswer, Qa,
    DictationAnswer, Dictation, FillMultiAnswer,
    FillMulti, CorrectionAnswer, Correction,
    JumbleAnswer, Jumble, ImagesAnswer, Images } from 'src/app/model/questions';
import { ExerciseSessionQuestion } from 'src/app/model/personal';
import { BlockComparator } from 'src/app/utils/text-comparing';

export class ScoreResults {
    constructor(public score: number) {}
}

export class OldCasaQuestionsScoringService {

    public score(sessionQuestion: ExerciseSessionQuestion<any, any>): ScoreResults {
        switch (sessionQuestion.question.definition['@type']) {
            case 'qa':
                return this.scoreQa(sessionQuestion as ExerciseSessionQuestion<QaAnswer, Qa>);
            case 'dict':
                return this.scoreDict(sessionQuestion as ExerciseSessionQuestion<DictationAnswer, Dictation>);
            case 'ef':
                return this.scoreEf(sessionQuestion as ExerciseSessionQuestion<FillMultiAnswer, FillMulti>);
            case 'cor':
                return this.scoreCor(sessionQuestion as ExerciseSessionQuestion<CorrectionAnswer, Correction>);
            case 'jmb':
                return this.scoreJmb(sessionQuestion as ExerciseSessionQuestion<JumbleAnswer, Jumble>);
            case 'pct':
                return this.scorePct(sessionQuestion as ExerciseSessionQuestion<ImagesAnswer, Images>);
            default:
                return new ScoreResults(0.0);

        }
    }
    scorePct(session: ExerciseSessionQuestion<ImagesAnswer, Images>): ScoreResults {
        const answer = session.answer;
        let scoreSum = session.question.definition.answer.answers.map(
            p => {
                const answered = answer.answers.find( i => i.name === p.name );
                if (!answered || !answered.val || answered.val.length < 1) {
                    return 0.0;
                }
                const imageResource = (p.val && p.val.length > 0) ? p.val[0] : p.name + '.png';
                if (imageResource === answered.val[0]) {
                    return 1.0;
                }
                return 0.0;
            }
        ).reduce( (sum, current) => sum + current, 0.0);
        scoreSum /= session.question.definition.words.length;
        return new ScoreResults(scoreSum);
    }
    scoreJmb(session: ExerciseSessionQuestion<JumbleAnswer, Jumble>): ScoreResults {
        const answer = session.answer;
        let scoreSum = session.question.definition.answer.answer.map(
            e => {
                const answered = answer.answer.find ( a => a.name === e.name && a.val && a.val.length > 0);
                if (!answered) {
                    return 0.0;
                }
                const answeredLow = answered.val[0].trim().toLowerCase();
                if (e.val && e.val.find(v => v.toLowerCase() === answeredLow)) {
                    return 1.0;
                }
                if (e.alt && e.alt.find( v => v.toLowerCase() === answeredLow)) {
                    return 0.5;
                }
                return 0.0;
            }
        ).reduce( (sum, current) => sum + current, 0);

        const len = session.question.definition.answer.answer.length;
        if (len > 0) {
          scoreSum /= len;
        } else {
          scoreSum = 1;
        }

        return new ScoreResults(scoreSum);
    }
    scoreCor(session: ExerciseSessionQuestion<CorrectionAnswer, Correction>): ScoreResults {
        const expectedToekns = BlockComparator.tokenize(session.question.definition.answer.correct);
        const answerTokens = BlockComparator.tokenize(session.answer.correct);
        const compareResults = BlockComparator.compare(expectedToekns, answerTokens);

        const score = compareResults[compareResults.length - 1].score;
        if (score < 0.5) {
            return new ScoreResults(1.0);
        } else if (score  < 1.0) {
            return new ScoreResults(0.5);
        } else {
            return new ScoreResults(0.0);
        }
    }
    scoreEf(session: ExerciseSessionQuestion<FillMultiAnswer, FillMulti>): ScoreResults {
        const answer = session.answer;
        let scoreSum = session.question.definition.answer.answer.map(
            e => {
                const answered = answer.answer.find ( a => a.name === e.name && a.val && a.val.length > 0);
                if (!answered) {
                    return 0.0;
                }
                const answeredLow = answered.val[0].trim().toLowerCase();
                if (e.val && e.val.find(v => v.toLowerCase() === answeredLow)) {
                    return 1.0;
                }
                if (e.alt && e.alt.find( v => v.toLowerCase() === answeredLow)) {
                    return 0.5;
                }
                return 0.0;
            }
        ).reduce( (sum, current) => sum + current, 0);

      const len = session.question.definition.answer.answer.length;
      if (len > 0) {
        scoreSum /= len;
      } else {
        scoreSum = 1;
      }
        return new ScoreResults(scoreSum);
    }
    scoreDict(session: ExerciseSessionQuestion<DictationAnswer, Dictation>): ScoreResults {
        const answer = session.answer.answer;
        const correct = session.question.definition.answer.answer;
        const expectedTokens = BlockComparator.tokenize(correct);
        const answerTokens = BlockComparator.tokenize(answer);
        const compareResult = BlockComparator.compare(expectedTokens, answerTokens);
        let score = compareResult[compareResult.length - 1].score / expectedTokens.content.length;
        if (score > 1) {
            score = 1;
        }
        return new ScoreResults( 1 - score);
    }

    scoreQa(session: ExerciseSessionQuestion<QaAnswer, Qa>): ScoreResults {
        if (session.answer) {
            return new ScoreResults(1.0);
        } else {
            return new ScoreResults(0.0);
        }
    }
}
