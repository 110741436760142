import { Component, OnInit } from '@angular/core';
import { SessionSummaryComponentBase } from 'src/app/pages/session-summary/session-summary.component';
import { SessionDataHolderService } from 'src/app/services/session-data-holder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';
import { environment } from 'src/environments/environment';
import { SupportedPlatforms } from 'src/environments/platforms';

@Component({
  selector: 'app-mobile-session-summary',
  templateUrl: '../../../pages/session-summary/session-summary.component.html',
  styleUrls: ['../../../pages/session-summary/session-summary.component.scss']
})
export class MobileSessionSummaryComponent extends SessionSummaryComponentBase {

  constructor(sessionHolder: SessionDataHolderService,
              route: ActivatedRoute,
              restProvider: ApiServiceProvider,
              router: Router) {
    super(sessionHolder, route, restProvider, router);
  }

  recreateSession() {
    this.rest.recreateExerciseSession(this.session.deviceUUID).subscribe(
      newSession =>
      this.router.navigate(
        ['cspa', 'session', newSession.deviceUUID])
      );
  }

  finishSession() {
    this.rest.questionSessionFinished();

    if (environment.platform === SupportedPlatforms.webCasa) {
      this.router.navigate(['exercises']);
    }
  }

}
