import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-holding-modal',
  templateUrl: './holding-modal.component.html',
  styleUrls: ['./holding-modal.component.scss']
})
export class HoldingModalComponent implements OnInit {
  visible = false;
  timer = 10
  constructor() { }

  ngOnInit() {
    this.visible = true;
    setInterval(() => {
      this.timer -= 1
      if(this.timer < 1 && this.visible)
        this.redirect()
    }, 1000)
  }

  redirect() {
    window.location.href = 'https://callan.app/'
  }

  public hide() {
    this.visible = false;
  }

  public cancel() {
    this.hide();
  }

  preventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
