import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class SessionDataHolderService {
    constructor() {
        this.session = new BehaviorSubject(null);
    }
    log(text) {
        if (environment.debug) {
            console.log(text);
        }
    }
    updateSession(session) {
        this.log('Session holder - updating session data');
        this.log(session);
        this.session.next(session);
    }
}
SessionDataHolderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionDataHolderService_Factory() { return new SessionDataHolderService(); }, token: SessionDataHolderService, providedIn: "root" });
