import { of } from 'rxjs';
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CasaStageBasedMobileApi {
    constructor(http) {
        this.http = http;
    }
    prepareUrl(suffix) {
        return environment.casaTestingEndpoint + suffix;
    }
    createSession(exerciseId) {
        return this.http.post(this.prepareUrl(`/session/create/${exerciseId}`), {});
    }
    recreateSession(sessionId) {
        return this.http.post(this.prepareUrl(`/session/${sessionId}/recreate`), {});
    }
    getSessionData(sessionId) {
        return this.http.get(this.prepareUrl(`/session/${sessionId}`));
    }
    getSessionQuestionDefinition(sessionId, questionId) {
        return this.http.get(this.prepareUrl(`/session/${sessionId}/question/${questionId}`));
    }
    submitSessionScore(sessionId, questionId, submit) {
        return this.http.post(this.prepareUrl(`/session/${sessionId}/question/${questionId}`), submit);
    }
    recalculateScore() {
        return this.http.patch(this.prepareUrl(`/sef/recalculateScore`), {});
    }
    closeQuestions() {
        return of(true);
    }
}
CasaStageBasedMobileApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CasaStageBasedMobileApi_Factory() { return new CasaStageBasedMobileApi(i0.ɵɵinject(i1.HttpClient)); }, token: CasaStageBasedMobileApi, providedIn: "root" });
