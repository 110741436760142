<div style="height:100px"></div>

<div class="bottom-nav" [ngClass]="{'text-light':isDark()}">
    <div class="container">
        <div class="row justify-content-between align-items-center" >
            <div *ngIf="showPrev" class="col-auto" >
                <a  class="btn-nav btn-nav-left" (click)="onClick(left)" tabindex="" appKidsRotation><i class="fas fa-chevron-left"></i></a>
            </div>
            <div class="col text-left" >
                <a *ngIf="thirdDescription" (click)="onClick(middle)" tabindex="" class="btn btn-success" appKidsRotation>{{thirdDescription}}</a>
            </div>
            <div *ngIf="showNext" class="col-auto" >
                <a class="btn-nav btn-nav-right" (click)="onClick(right)"  tabindex="" appKidsRotation><i class="fas fa-chevron-right"></i></a>
            </div>
        </div>
    </div>
    
</div>
