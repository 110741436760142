/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-question-host.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../top-bar/top-bar.component.ngfactory";
import * as i3 from "../top-bar/top-bar.component";
import * as i4 from "@angular/common";
import * as i5 from "../../directives/dynamic-host.directive";
import * as i6 from "../footer-bar/footer-bar.component.ngfactory";
import * as i7 from "../footer-bar/footer-bar.component";
import * as i8 from "../../services/theme.service";
import * as i9 from "./session-question-host.component";
import * as i10 from "@angular/router";
import * as i11 from "../../services/session-data-holder.service";
import * as i12 from "../../services/question-component-provider.service";
import * as i13 from "../../services/rest-provider.service";
var styles_SessionQuestionHostComponent = [i0.styles];
var RenderType_SessionQuestionHostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionQuestionHostComponent, data: {} });
export { RenderType_SessionQuestionHostComponent as RenderType_SessionQuestionHostComponent };
function View_SessionQuestionHostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h5", [["class", "mt-2"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getInstructions(); _ck(_v, 0, 0, currVal_0); }); }
function View_SessionQuestionHostComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_SessionQuestionHostComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dynamicHost: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-top-bar", [], null, [[null, "closeClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeClick" === en)) {
        var pd_0 = (_co.closeExerciseSet() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TopBarComponent_0, i2.RenderType_TopBarComponent)), i1.ɵdid(2, 114688, null, 0, i3.TopBarComponent, [], { title: [0, "title"] }, { closeClick: "closeClick" }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "progress mt-1 mb-2 lesson-progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["aria-valuenow", "25"], ["class", "progress-bar"], ["role", "progressbar"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionQuestionHostComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionQuestionHostComponent_2)), i1.ɵdid(10, 16384, [[1, 4]], 0, i5.DynamicHostDirective, [i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-footer-bar", [], null, [[null, "interact"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("interact" === en)) {
        var pd_0 = (_co.onFooterInteract($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FooterBarComponent_0, i6.RenderType_FooterBarComponent)), i1.ɵdid(12, 114688, null, 0, i7.FooterBarComponent, [i8.ThemeService], { showPrev: [0, "showPrev"], showNext: [1, "showNext"], thirdDescription: [2, "thirdDescription"] }, { interact: "interact" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTopBarTitle(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getProgressStyle(); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.hasInstructions(); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.isShowPrev(); var currVal_4 = _co.isShowNext(); var currVal_5 = _co.getMiddleDescription(); _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_SessionQuestionHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-session-question-host", [], null, null, null, View_SessionQuestionHostComponent_0, RenderType_SessionQuestionHostComponent)), i1.ɵdid(1, 245760, null, 0, i9.SessionQuestionHostComponent, [i10.ActivatedRoute, i11.SessionDataHolderService, i12.QuestionComponentProviderService, i1.ComponentFactoryResolver, i10.Router, i13.ApiServiceProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionQuestionHostComponentNgFactory = i1.ɵccf("app-session-question-host", i9.SessionQuestionHostComponent, View_SessionQuestionHostComponent_Host_0, {}, {}, []);
export { SessionQuestionHostComponentNgFactory as SessionQuestionHostComponentNgFactory };
