import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MenuControlsService } from 'src/app/services/menu-controls.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-token-auth',
  templateUrl: './token-auth.component.html',
  styleUrls: ['./token-auth.component.scss']
})
export class TokenAuthComponent implements OnInit {

  token: string;

  constructor(activatedRoute: ActivatedRoute,
              private auth: AuthService,
              menuControls: MenuControlsService) {
    activatedRoute.queryParamMap
    .subscribe( params => {
      let state: string = null;
      if (params.get('state')) {
        state = params.get('state');
      }
      this.authorizeToken(params.get('token'), state);
      if (params.get('closeOnFinish')) {
        menuControls.updateCloseOnFinish(Boolean(JSON.parse(params.get('closeOnFinish'))));
      }
      if (params.get('showMenu')) {
          menuControls.updateMenuState(Boolean(JSON.parse(params.get('showMenu'))));
        }
      }
    );
  }

  log(text: string) {
    if (environment.debug) {
      console.log(text);
    }
  }

  authorizeToken(token: string, state?: string): void {
    if (!token) {
      this.log('missing token');
      return;
    }
    const targetState = state ? state : "/exercises";
    this.auth.loginWithPlainToken(token, targetState);
  }

  ngOnInit() {
  }

}
