
<div  style="position:relative">

    <nav *ngIf="isMenuEnabled()" class="navbar-dark menu-button">
    <button class="custom navbar-toggler text-light" type="button" (click)="showMenu()">
        <span class="navbar-toggler-icon"></span>
    </button>
    </nav>

    <div class="side-bar-dim"  (click)="hideMenu()" [@showDim]="isShowMenu() ? 'show' : 'hide'"></div>

    <div class="side-bar bg-primary px-3 py-2"  [@showHide]="isShowMenu() ? 'show' : 'hide'">
        <button type="button" class="close text-white p-2" (click)="hideMenu()"  aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
        <div class="text-white mt-4" style="overflow: hidden">
            <div class="mt-2">{{getName()}} ({{getEmail()}})</div>
        </div>
        <ul class="navbar-nav ml-auto mt-4">
            <li *ngIf="hasCol()" class="nav-item">
                <a tabindex="" class="nav-link" (click)="goToCol()" [translate]="'layout.menu.open-col'">Open Callan Online</a>
            </li>
            <li class="nav-item"> 
                <a tabindex="" (click)="logout()" tabindex="" class="nav-link" [translate]="'layout.menu.logout'">Logout</a>
            </li>
        </ul>
    </div>

    <router-outlet></router-outlet>
</div>
