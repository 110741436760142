import { Observable, of, Subject } from "rxjs";
import { environment } from "../../../../environments/environment";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { switchMap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "../../../services/logger.service";
import * as i2 from "@angular/common/http";
/**
 * Temporary structure to keep requests invocation until AndroidWebBridge.initialize() method
 * call by native
 */
class WaitingCall {
    constructor(id, nativeCall, observer) {
        this.id = id;
        this.nativeCall = nativeCall;
        this.observer = observer;
    }
    doCall() {
        try {
            const result = this.nativeCall(this.id);
            this.observer.next(result);
            this.observer.complete;
        }
        catch (e) {
            this.observer.error(e);
        }
    }
}
/**
 * The service is responsible for the communication between native and web part.
 * Before a full communication channel would be established the method initialize() has to be invoked first
 * by the native.
 *
 * The communication uses two different techniques. Methods related to the sound recording device
 * calls native api directly. Other uses a REST protocol which has to be intercepted by a native.
 * For data manipulation requests like POST or PUT we had to prepare a special
 * protocol for data providing (because request body can't be read during the native intercept phase).
 * For that cases api calls storeRequestData() method which provides a request body
 * before a regular request call.
 *
 * The URL scheme is formatted in typical tree structure:
 * + /sets +                                              - set definitions container
 * |       + /${exerciseSet} +                            - specific set content definitions container (chapters)
 * |                         + availability               - specific set availabilities container
 * |                         + questions                  - specific set question definitions container
 * + sessions +                                           - device stored sessions container
 *            + current                                   - device current session content
 */
export class AndroidRequestBasedNativeApi {
    constructor(logger, http) {
        this.logger = logger;
        this.http = http;
        this.initialized = false;
        this.updateEventsSubject = new Subject();
        this.waitingCallsQueue = [];
        this.refreshingSubject = new Subject();
        this.id = 0;
        // endpoint for fake http intercepted calls
        this.apiEndpoint = environment.apiEndpoint;
        logger.log('creating android native bridge');
        window.webBridge = this;
    }
    /**
     * build full request URL value basing on the relative path
     *
     * with the current environment value the call would be the same as { return `http://iternal-api-calls/api/${rest of the path}; }
     *
     * @param path
     * @private
     */
    buildPath(path) {
        return `${this.apiEndpoint}/api${path}`;
    }
    initialize() {
        this.logger.log(`initializing with ${this.waitingCallsQueue.length} waiting tasks.`);
        this.initialized = true;
        for (const waitingCall of this.waitingCallsQueue) {
            this.logger.log(`calling native during init [${waitingCall.id}]`);
            waitingCall.doCall();
        }
        this.waitingCallsQueue = [];
    }
    /**
     * internal implementation of native calls which will take care for
     * waiting calls stored before initialization
     * @param nativeCall
     * @private
     */
    callNative(nativeCall) {
        const id = this.getNextRequestId();
        if (this.initialized) {
            this.logger.log(`calling native [${id}] directly`);
            return of(nativeCall(id));
        }
        this.logger.log(`adding to waiting tasks [${id}]`);
        return new Observable(observer => {
            this.waitingCallsQueue.push(new WaitingCall(id, nativeCall, observer));
        });
    }
    /**
     * generic method to store the POST, PUT request data before
     * request invocation
     * @param data
     */
    storeRequestData(data) {
        return this.callNative(id => {
            window.nativeBridge.storeRequestData(id.toString(), JSON.stringify(data));
            return id;
        });
    }
    notifyDataUpdate() {
        this.logger.log('notifying data update');
        this.updateEventsSubject.next();
    }
    listenForDataUpdates() {
        return this.updateEventsSubject;
    }
    log(text) {
        this.logger.log(`[android-api2] - ${text}`);
    }
    clear() {
        this.log("calling clear");
        return this.callNative(id => window.nativeBridge.clear(id));
    }
    /**
     * start recording
     */
    record() {
        this.log("calling record");
        return this.callNative(id => window.nativeBridge.record(id)).subscribe();
    }
    /**
     * stop recording or playback
     */
    stop() {
        this.log("calling stop");
        return this.callNative(id => window.nativeBridge.stop(id));
    }
    /**
     * start recording playback
     */
    play2() {
        this.log("calling play");
        return this.callNative(id => window.nativeBridge.play(id)).subscribe();
    }
    /**
     * clean up and release recording device
     */
    terminate() {
        this.log("calling terminate");
        return this.callNative(id => window.nativeBridge.terminate(id)).subscribe();
    }
    /**
     * initialize recording device, returns true if positive
     * @param callback
     */
    initAudio(callback) {
        this.log(" calling clear");
        return this.callNative(id => window.nativeBridge.init(id)).subscribe(it => callback(it));
    }
    // #### data management implementation #####
    getNextRequestId() {
        return this.id++;
    }
    static buildRequestIdHeader(id) {
        return new HttpHeaders({ "X-Request-Id": id.toString() });
    }
    nextRequestIdHeader() {
        return AndroidRequestBasedNativeApi.buildRequestIdHeader(this.getNextRequestId());
    }
    /**
     * read the availability JSON file for exerciseSet [GET] /sets/${exerciseSet}/availability
     * @param exerciseSet
     */
    getAvailabilities(exerciseSet) {
        this.log(`getting availabilities for ${exerciseSet}`);
        return this.http.get(this.buildPath(`/sets/${exerciseSet}/availability`), { headers: this.nextRequestIdHeader() });
    }
    /**
     * read chapters JSON file for exerciseSet [GET] /sets/${exerciseSet}
     * @param exerciseSet
     */
    getChapters(exerciseSet) {
        this.log(`getting chapters for ${exerciseSet}`);
        return this.http.get(this.buildPath(`/sets/${exerciseSet}`), { headers: this.nextRequestIdHeader() });
    }
    /**
     * read current session JSON file [GET] /sessions/current
     */
    getCurrentSession() {
        this.log(`getting current session`);
        return this.http.get(this.buildPath(`/sessions/current`), { headers: this.nextRequestIdHeader() });
    }
    /**
     * read questions file for exerciseSet [GET] /sets/${exerciseSet}/questions
     * @param exerciseSet
     */
    getQuestions(exerciseSet) {
        this.log(`getting questions for ${exerciseSet}`);
        return this.http.get(this.buildPath(`/sets/${exerciseSet}/questions`), { headers: this.nextRequestIdHeader() });
    }
    /**
     * read exercise sets file (has to be synced by the native app !!!!) [GET] /sets
     */
    listExerciseSets() {
        this.log('getting exercise sets');
        return this.http.get(this.buildPath(`/sets`), { headers: this.nextRequestIdHeader() });
    }
    /**
     * push session to the FIFO sync queue [POST] /sessions {request body - session to store, response body - stored session}
     * @param session
     */
    pushSession(session) {
        this.log(`pushing session ${session.deviceUUID}`);
        return this.storeRequestData(session).pipe(switchMap(id => this.http.post(this.buildPath("/sessions"), {}, { headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id) })));
    }
    /**
     * send stored sync sessions queue to the API and clean up the queue if positive. [POST] /sessions/sync {request and response body is empty}
     * Sync in FIFO order
     */
    sendStoredSessions() {
        this.log(`sending sessions to the server`);
        return this.http.post(this.buildPath("/sessions/sync"), { headers: this.nextRequestIdHeader() });
    }
    /**
     * store availability in the file, dont change update time [PUT] /sets/${exerciseSet}/availability {request and response body has a data to write}
     * @param exerciseSet
     * @param availability
     */
    storeAvailability(exerciseSet, availability) {
        this.log(`storing availabilities for ${exerciseSet}`);
        return this.storeRequestData(availability).pipe(switchMap(id => this.http.put(this.buildPath(`/sets/${exerciseSet}/availability`), {}, { headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id) })));
    }
    /**
     * store session in current session file [PUT] /sessions/current {bodies are session to save}
     * @param session
     */
    storeCurrentSession(session) {
        this.log(`storing current session ${session.deviceUUID}`);
        return this.storeRequestData(session).pipe(switchMap(id => this.http.put(this.buildPath("/sessions/current"), {}, { headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id) })));
    }
    /**
     * Append or create http parameter to pass the sync frequency value in http request
     * @param freq frequency in Ms
     * @param paramsToAppend existing request parameters or empty if non exists
     */
    static prepareFreqParams(freq, paramsToAppend) {
        if (!paramsToAppend) {
            paramsToAppend = new HttpParams();
        }
        if (!freq)
            return paramsToAppend;
        return paramsToAppend.append("freq", freq.toString());
    }
    /**
     * do the file sync if required. Check if the existing version is older than @syncFrequencyMs [POST] /sets/${exerciseSet}/availability/sync {bodies are empty}
     * @param exerciseSet
     * @param syncFrequencyMs
     */
    syncAvailabilities(exerciseSet, syncFrequencyMs) {
        this.log(`syncing availabilities for ${exerciseSet}`);
        return this.http.post(this.buildPath(`/sets/${exerciseSet}/availability/sync`), {}, {
            headers: this.nextRequestIdHeader(),
            params: AndroidRequestBasedNativeApi.prepareFreqParams(syncFrequencyMs)
        });
    }
    /**
     * do the file sync if required. Check if the existing version is older than @syncFrequencyMs [POST] /sets/${exerciseSet} {request body empty, response has chapters}
     * @param exerciseSet
     * @param syncFrequencyMs
     */
    syncChapters(exerciseSet, syncFrequencyMs) {
        this.log(`syncing chapters for ${exerciseSet}`);
        return this.http.post(this.buildPath(`/sets/${exerciseSet}/sync`), {}, {
            headers: this.nextRequestIdHeader(),
            params: AndroidRequestBasedNativeApi.prepareFreqParams(syncFrequencyMs)
        });
    }
    /**
     * do the file sync if required. Check if the existing version is older than @syncFrequencyMs [POST] /sets/${exerciseSet}/questions/sync {bodies are empty}
     * @param exerciseSet
     * @param syncFrequencyMs
     */
    syncQuestions(exerciseSet, syncFrequencyMs) {
        this.log(`syncing questions for ${exerciseSet}`);
        return this.http.post(this.buildPath(`/sets/${exerciseSet}/questions/sync`), {}, {
            headers: this.nextRequestIdHeader(),
            params: AndroidRequestBasedNativeApi.prepareFreqParams(syncFrequencyMs)
        });
    }
    /**
     * do application close
     */
    close() {
        this.log('calling for close the app.');
        this.callNative(id => window.nativeBridge.close(id)).subscribe();
    }
    // #### data management api #####
    subscribeForRefreshingEvents() {
        return this.refreshingSubject;
    }
}
AndroidRequestBasedNativeApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AndroidRequestBasedNativeApi_Factory() { return new AndroidRequestBasedNativeApi(i0.ɵɵinject(i1.LoggerService), i0.ɵɵinject(i2.HttpClient)); }, token: AndroidRequestBasedNativeApi, providedIn: "root" });
