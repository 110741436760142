<div class="kids container">
    <div class="text-center mt-2 mb-4">
        <h1 class="red" [translate]="'pages.cfk.hello'">Hello Kids!</h1>
        <h4 [translate]="'pages.cfk.welcome'">Welcome to the Callan for Kids</h4>
        <h2><span class="red">S</span>tudent <span class="red">P</span>ractice <span class="red">A</span>rea</h2>
    </div>
    <p class="text-center" [translate]="'pages.cfk.cspa-is-fun'">
        Here, you can have lots of fun with English between lessons at school
    </p>
    <div class="row justify-content-center">
        <div class="col-auto" [translate]="'pages.cfk.activities.listening'">Listening</div>
        <div class="col-auto" [translate]="'pages.cfk.activities.speaking'">Speaking</div>
        <div class="col-auto" [translate]="'pages.cfk.activities.reading'">Reading</div>
        <div class="col-auto" [translate]="'pages.cfk.activities.writing'">Writing</div>
    </div>
    <div class="text-center mt-5 mb-2">
        <a routerLink="/exercises" class="btn btn-lg btn-danger" [translate]="'common.action.enter'">Enter</a>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-6">
            <div class="row justify-content-center">
                <div class="col-12 mt-5">
                    <h3 class="text-center" [translate]="'pages.cfk.section.qa.title'">Question and Answer</h3>
                </div>
                <div class="col-sm-8 col-md-6">
                    <div class="back-shadow-wrapper screenshot mx-auto d-block my-2">
                        <div class="back-shadow"></div>
                        <img class="img-fluid" src="/assets/img/mobile_cfk_qa1.png">
                    </div>
                </div>
                <div class="col-12 order-md-12 text-center">
                    <ul class="list-unstyled">
                        <li [translate]="'pages.cfk.section.qa.activity.listen'">Listen to a question.</li>
                        <li [translate]="'pages.cfk.section.qa.activity.record'">Answer and record your voice.</li>
                        <li [translate]="'pages.cfk.section.qa.activity.verify'">Listen to the right answer.</li>
                        <li [translate]="'pages.cfk.section.qa.activity.improve'">Record your answer again!</li>
                    </ul>
                </div>
                <div class="col-sm-8 col-md-6">
                    <div class="back-shadow-wrapper screenshot mx-auto d-block my-2">
                        <div class="back-shadow"></div>
                        <img class="img-fluid" src="/assets/img/mobile_cfk_qa2.png">
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-6">
            <div class="row justify-content-center">
                <div class="col-12 mt-5">
                    <h3 class="text-center" [translate]="'pages.cfk.section.extra.title'">Extra Practice</h3>
                </div>
                <div class="col-sm-8 col-md-6">
                    <div class="back-shadow-wrapper screenshot mx-auto d-block my-2">
                        <div class="back-shadow"></div>
                        <img class="img-fluid" src="/assets/img/mobile_cfk_ex1.png">
                    </div>
                </div>
                <div class="col-12 order-md-12 text-center">
                    <ul class="list-unstyled">
                        <li [translate]="'pages.cfk.section.extra.activity.fun'">Try lots of fun exercises.</li>
                        <li [translate]="'pages.cfk.section.extra.activity.verify'">Check your answers.</li>
                        <li [translate]="'pages.cfk.section.extra.activity.improve'">Try again to get everything right!</li>
                    </ul>
                </div>
                <div class="col-sm-8 col-md-6">
                    <div class="back-shadow-wrapper screenshot mx-auto d-block my-2">
                        <div class="back-shadow"></div>
                        <img class="img-fluid" src="/assets/img/mobile_cfk_ex2.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h1 class="red text-center" [translate]="'pages.cfk.have-fun'">Have fun!</h1>
    <h2 class="my-5" [translate]="'pages.cfk.parents.title'">A message to parents, teachers and guardians:</h2>

    <p [translate]="'pages.cfk.parents.app.intro'">Soon, the Callan for Kids SPA will be available on the Callan Method App.</p>
    <div class="row justify-content-center">
        <div class="col-sm-4 col-lg-3 ">
            <div class="back-shadow-wrapper screenshot mx-auto d-block my-4">
                <div class="back-shadow"></div>
                <img class="img-fluid" src="/assets/img/mobile_app.png">
            </div>
        </div>
        <div class="col-sm-8 col-lg-6 pt-4">
            <p [translate]="'pages.cfk.parents.app.about'">The Callan Method App gives you the CSPA, your eBooks
                and your audiobooks – all you need, and all in one place!</p>
            <p [translate]="'pages.cfk.parents.app.platforms'">You can get the app on Android and iOS.</p>
            <p [translate]="'pages.cfk.parents.app.download'">Download the one you want here:</p>

            <div class="row justify-content-center">
                <div class="col-6 mb-2">
                    <a  href="https://itunes.apple.com/gb/app/callan-method-app/id1099785360">
                        <img class="img-fluid shadow-intensive d-block mx-auto" src="/assets/img/appstore.jpg" style="border-radius: 12px;">
                    </a>
                </div>
                <div class="col-6 mb-2">
                    <a href="https://play.google.com/store/apps/details?id=pl.arceo.callan.callandigitalbooks">
                        <img class="img-fluid shadow-intensive d-block mx-auto" src="/assets/img/gplay.jpg" style="border-radius: 12px;">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <h3 class="mt-5" [translate]="'pages.cfk.questions.title'">Questions? Feedback? Need Help?</h3>
    <div class="text-center my-5  pb-5">
        <a href="mailto: help@callan.co.uk?Subject=Cspa contact us" class="btn btn-lg btn-danger"
           [translate]="'pages.cfk.questions.contact-us'"
        >Contact us by email</a>
    </div>
</div>
