import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./logger.service";
export class TranslationService {
    constructor(translate, logger) {
        this.translate = translate;
        this.logger = logger;
        translate.setDefaultLang('en');
        translate.use(this.determineBrowserLanguage());
    }
    determineBrowserLanguage() {
        let browserLang = this.translate.getBrowserLang();
        console.log(browserLang);
        // treat portuguese exceptionally - use brazilian dialect
        if (browserLang === 'pt')
            browserLang = 'br_pt';
        if (browserLang === 'ja')
            browserLang = 'jp';
        // for non supported languages use english
        if (TranslationService.supportedLangs.indexOf(browserLang) < 0)
            browserLang = 'en';
        // prefix with dash according to file names
        return browserLang;
    }
    switchLang(lang) {
        this.translate.use(lang);
    }
    get(key, params) {
        return this.translate.get(key, params);
    }
    extractLang(params) {
        let lang = params['lang'];
        if (!lang)
            return;
        lang = `_${lang.toLocaleLowerCase()}`;
        if (TranslationService.supportedLangs.indexOf(lang) >= 0) {
            this.logger.log(`switching lang to ${lang}`);
            this.translate.use(lang);
        }
    }
}
TranslationService.supportedLangs = [
    'en',
    'pl',
    'br_pt',
    'ca',
    'de',
    'es',
    'hr',
    'it',
    'jp',
    'ru',
];
TranslationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.LoggerService)); }, token: TranslationService, providedIn: "root" });
