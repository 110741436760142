<div class="row no-gutters">
    <div class="col-auto mr-2">
      <a tabindex="" (click)="onCloseClick()" class="btn btn-light text-dark" appKidsRotation="10">
        <i class="fas fa-times" ></i>
      </a>
    </div>
    <div class="col align-self-center pt-1">
      <h3 [innerHtml]="getTitle()"></h3>
    </div>
</div>
