import { NgZone, Injectable } from '@angular/core';
import {LoggerService} from "../../services/logger.service";

class CallbackResponse<T> {
    static NextResponseType = 'next';
    static ErrorResponseType = 'error';
    static CompleteResponseType = 'complete';
    id: number;
    responseType: string;
    responseBody: T | string;
}

export class CallbackRequest<T> {
    id: number;
    args: any;
}

@Injectable({
    providedIn: 'root'
})
export class PendingRequestsQueue {

    constructor(private ngZone: NgZone, private logger: LoggerService) {}
    pendingRequestById: { [requestId: number]: any} = {};

    registerPendingRequest(id: number, observer: any) {
        this.pendingRequestById[id] = observer;
    }

    deletePendingRequest(id: number) {
        delete this.pendingRequestById[id];
    }

    handlePendingRequest(data: any) {
        const response = data as CallbackResponse<any>;
        this.logger.log(`got pending request response[${response.id}]: `);
        this.logger.log(JSON.stringify(data));
        const pendingRequest = this.pendingRequestById[response.id];
        if (!pendingRequest) {
            this.logger.log('missing pending request for id ' + pendingRequest.id);
        }
        if (response.responseType === CallbackResponse.NextResponseType) {
            this.logger.log('invoking next in ngzone');
            this.ngZone.run( () => pendingRequest.next(response.responseBody));
        } else if (response.responseType === CallbackResponse.CompleteResponseType) {
            this.logger.log('invoking complete in ngzone & deleting pending request');
            this.ngZone.run( () => pendingRequest.complete());
            this.deletePendingRequest(response.id);
        } else if (response.responseType === CallbackResponse.ErrorResponseType) {
            this.logger.log('invoking error in ngzone & deleting pending request');
            this.ngZone.run( () => pendingRequest.error(new Error(response.responseBody as string)));
            this.deletePendingRequest(response.id);
        }
    }
}

