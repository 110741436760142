export class CasaExerciseSet {
    id: number;
    name: string;
    code: string;
}

export class CasaQuestionScoreSubmit {
    constructor(public score: number,
                public sessionDate: Date,
                public submitDate: Date) {}
}

export class CasaDefinition {
    affirm: string;
    question: string;
    answer: string;
    correction: string;
    askingSexMale: boolean;
    answers: string[];
    wrongAnswers: string[];
    hasImage: boolean;
    correctAnswer: string;
}

export class CasaSessionQuestionDefinition {
    exerciseName: string;
    qDef: CasaQuestionDefinition;
    questions: number;
    questionNumber: number;
    chapterName: string;
    prevQuetionId: number;
    prevQuestionType: string;
    nextQuestionId: number;
    nextQuestionType: string;
}

export class CasaQuestionDefinition {
    typeId: number;
    definition: CasaDefinition;
    id: number;
    type: string;
    hasImage: boolean;
    resourceBase: string;
    instructions: string;
}

export class CasaSessionQuestion {
    def: CasaQuestionDefinition;
    score: number;
    questionName: string;
    submitDate: Date;
    updateDate: number;
}

export class CasaExerciseSession {
    id: number;
    score: number;
    baseSessionQuestionNumber: number;
    questions: CasaSessionQuestion[];
    deviceUUID: string;
    prevUUID: string;
    lastUdpateDate: number;
    startDate: number;
}

export class CasaScoresQuestion {
    id: number;
    score: number;
    lastSubmitDate: Date;
}

export class CasaScoresExercise {
    id: number;
    enabled: boolean;
    score: number;
    lastSubmitDate: Date;
    questions: CasaScoresQuestion[];
}

export class CasaScoresSection {
    TYPE_QA = 'QA';
    TYPE_DICT = 'DICT';
    TYPE_EP = 'EP';

    id: number;
    enabled: boolean;
    score: number;
    sectionType: string;
    exercises: CasaScoresExercise[];
}

export class CasaPersonScores {
    id: number;
    available: boolean;
    enabled: boolean;
    sections: CasaScoresSection[];
}

export class CasaStructQuestion {
    id: number;
    typeId: number;
    hasImage: boolean;
    affirm: string;
    answer: string;
    resourceBase: string;
    question: string;
    correction: string;
    askingSexMale: boolean;
    answers: string;
    wrongAnswers: string;
    defHasImage: boolean;
    correctAnswer: string;
    instructions: string;
    oldId: number;
}

export class CasaStructExercise {
    name: string;
    id: number;
    firstQuestionId: number;
    firstQuestionType: string;
    shortName: string;
    questions: CasaStructQuestion[];
}

export class CasaStructSection {
    TYPE_QA = 'QA';
    TYPE_DICT = 'DICT';
    TYPE_EP = 'EP';

    name: string;
    type: string;
    exercises: CasaStructExercise[];
}

export class CasaStructChapter {
    name: string;
    id: number;
    code: string;
    version: number;
    langCode: string;
    chapterOrder: number;
    sections: CasaStructSection[];
}

export class CasaExerciseStructure {
    chapters: CasaStructChapter[];
}

