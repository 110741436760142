/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-host.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./session-host.component";
import * as i4 from "../../services/rest-provider.service";
import * as i5 from "../../services/session-data-holder.service";
import * as i6 from "../../services/theme.service";
var styles_SessionHostComponent = [i0.styles];
var RenderType_SessionHostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionHostComponent, data: {} });
export { RenderType_SessionHostComponent as RenderType_SessionHostComponent };
export function View_SessionHostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_SessionHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-session-host", [], null, null, null, View_SessionHostComponent_0, RenderType_SessionHostComponent)), i1.ɵdid(1, 114688, null, 0, i3.SessionHostComponent, [i2.ActivatedRoute, i4.ApiServiceProvider, i2.Router, i5.SessionDataHolderService, i6.ThemeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionHostComponentNgFactory = i1.ɵccf("app-session-host", i3.SessionHostComponent, View_SessionHostComponent_Host_0, {}, {}, []);
export { SessionHostComponentNgFactory as SessionHostComponentNgFactory };
