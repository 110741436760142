/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exercise-set-host.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../chapter-progress-circle/chapter-progress-circle.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../chapter-progress-circle/chapter-progress-circle.component";
import * as i5 from "../top-bar/top-bar.component.ngfactory";
import * as i6 from "../top-bar/top-bar.component";
import * as i7 from "../../directives/kids-rotation.directive";
import * as i8 from "../../services/theme.service";
import * as i9 from "../chapter-details/chapter-details.component.ngfactory";
import * as i10 from "../chapter-details/chapter-details.component";
import * as i11 from "@angular/router";
import * as i12 from "../../services/logger.service";
import * as i13 from "./exercise-set-host.component";
import * as i14 from "../../services/rest-provider.service";
var styles_ExerciseSetHostComponent = [i0.styles];
var RenderType_ExerciseSetHostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExerciseSetHostComponent, data: {} });
export { RenderType_ExerciseSetHostComponent as RenderType_ExerciseSetHostComponent };
function View_ExerciseSetHostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-chapter-progress-circle", [], null, [[null, "chapterClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("chapterClick" === en)) {
        var pd_0 = (_co.onChapterClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ChapterProgressCircleComponent_0, i2.RenderType_ChapterProgressCircleComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 114688, null, 0, i4.ChapterProgressCircleComponent, [], { chapter: [0, "chapter"], availability: [1, "availability"] }, { chapterClick: "chapterClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getChapterSelectionClass(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.getChapterAvailability(_v.context.$implicit); _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_ExerciseSetHostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-top-bar", [], null, [[null, "closeClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeClick" === en)) {
        var pd_0 = (_co.closeExerciseSet() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TopBarComponent_0, i5.RenderType_TopBarComponent)), i1.ɵdid(3, 114688, null, 0, i6.TopBarComponent, [], { title: [0, "title"] }, { closeClick: "closeClick" }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "mt-2 row align-items-end no-gutters "]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "stage-list-container col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "stage-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExerciseSetHostComponent_1)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 14, "div", [["class", "text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "row no-gutters align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "col-auto col-sm-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "stage-btn cursor-pointer"], ["tabindex", ""]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFooterInteract("left") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 147456, null, 0, i7.KidsRotationDirective, [i1.ElementRef, i8.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "col text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "col-auto col-sm-2 text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [["appKidsRotation", ""], ["class", "stage-btn cursor-pointer"], ["tabindex", ""]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFooterInteract("right") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 147456, null, 0, i7.KidsRotationDirective, [i1.ElementRef, i8.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-chevron-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "app-chapter-details", [], null, null, null, i9.View_ChapterDetailsComponent_0, i9.RenderType_ChapterDetailsComponent)), i1.ɵdid(23, 114688, null, 0, i10.ChapterDetailsComponent, [i11.Router, i12.LoggerService], { chapter: [0, "chapter"], availabilities: [1, "availabilities"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTopBarTitle(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getAvailabileChapters(); _ck(_v, 8, 0, currVal_1); var currVal_3 = ""; _ck(_v, 13, 0, currVal_3); var currVal_6 = ""; _ck(_v, 20, 0, currVal_6); var currVal_7 = _co.getSelectedChapter(); var currVal_8 = _co.getAvailabilities(); _ck(_v, 23, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.hasPrev(); _ck(_v, 12, 0, currVal_2); var currVal_4 = _co.getChapterName(); _ck(_v, 17, 0, currVal_4); var currVal_5 = !_co.hasNext(); _ck(_v, 19, 0, currVal_5); }); }
export function View_ExerciseSetHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exercise-set-host", [], null, null, null, View_ExerciseSetHostComponent_0, RenderType_ExerciseSetHostComponent)), i1.ɵdid(1, 114688, null, 0, i13.ExerciseSetHostComponent, [i14.ApiServiceProvider, i11.ActivatedRoute, i11.Router, i8.ThemeService, i12.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExerciseSetHostComponentNgFactory = i1.ɵccf("app-exercise-set-host", i13.ExerciseSetHostComponent, View_ExerciseSetHostComponent_Host_0, {}, {}, []);
export { ExerciseSetHostComponentNgFactory as ExerciseSetHostComponentNgFactory };
