import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionDataHolderService } from 'src/app/services/session-data-holder.service';
import { ExerciseSession, ExerciseSessionQuestion } from 'src/app/model/personal';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';
import {MenuControlsService} from "../../services/menu-controls.service";


export abstract class SessionSummaryComponentBase implements OnInit, OnDestroy {
  session: ExerciseSession;
  sessionSubscr: Subscription;
  resultState = {
    unknown: 'unknown',
    bad: 'bad',
    poor: 'poor',
    good: 'good'
  };
  protected rest: CspaRestService;

  constructor(sessionHolder: SessionDataHolderService,
              route: ActivatedRoute,
              restProvider: ApiServiceProvider,
              protected router: Router) {
    this.rest = restProvider.getRestServiceImplementation();
    this.sessionSubscr = sessionHolder.session.subscribe( session => {
      this.session = session;
    });
  }

  abstract recreateSession();

  isShowFireworks() {
    if (!this.session) {
      return false;
    }
    return this.session.score > 0.95;
  }

  abstract finishSession();

  getScore() {
    if (!this.session) {
      return;
    }
    return Math.round(this.session.score * 100);
  }

  mayCorrect() {
    if (!this.session) {
      return false;
    }
    return this.session.questions.find( q => !q.correct) != null;
  }

  getQuestionScoreClass(question: ExerciseSessionQuestion<any, any>) {
    if (!question.answered || question.score < 0.5) {
      return 'bad';
    } else if (question.score < 0.95) {
      return 'enough';
    } else {
      return 'perfect';
    }
  }

  getSessionName() {
    if (!this.session) {
      return null;
    }

    return this.session.exerciseName;
  }

  getResultState() {
    if (!this.session) {
      return this.resultState.unknown;
    }
    if (this.session.score < 0.5) {
      return this.resultState.bad;
    } else if (this.session.score < 0.95) {
      return this.resultState.poor;
    } else {
      return this.resultState.good;
    }
  }

  hasErrors() {
    if (!this.session) {
      return false;
    }
    return this.session.questions.find( sq => sq.score < 0.5) != null;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sessionSubscr.unsubscribe();
  }
}


@Component({
  selector: 'app-session-summary',
  templateUrl: './session-summary.component.html',
  styleUrls: ['./session-summary.component.scss']
})
export class SessionSummaryComponent extends SessionSummaryComponentBase {

  exerciseSet: string;
  chapter: string;
  section: string;
  exercise: string;
  private closeSubscription: Subscription;
  private sessionId: string;

  constructor(sessionHolder: SessionDataHolderService,
              route: ActivatedRoute,
              restProvider: ApiServiceProvider,
              router: Router,
              private menuController: MenuControlsService) {
      super(sessionHolder, route, restProvider, router);

      route.parent.paramMap.subscribe(
        params => {
          this.exerciseSet = params.get('exerciseSet');
          this.chapter = params.get('chapter');
          this.section = params.get('section');
          this.exercise = params.get('exercise');
          this.sessionId = params.get('sessionId');
        }
      );
  }

  finishSession() {
    this.rest.closeSession(this.sessionId).subscribe();
    this.closeSubscription = this.menuController.closeWindowOnFinish.subscribe(
      close => {
        close ? this.closeWindow() : this.navigateToExercises();
        if (this.closeSubscription) this.closeSubscription.unsubscribe();
      }
    )

  }

  recreateSession() {
    this.rest.recreateExerciseSession(this.session.deviceUUID).subscribe(
      newSession =>
      this.router.navigate(
        ['exercises', this.exerciseSet, this.chapter, this.section, this.exercise, 'session', newSession.deviceUUID])
    );
  }

  private closeWindow() {
    this.router.navigate(['close']);
  }

  private navigateToExercises() {
    this.router.navigate(['exercises', this.exerciseSet]);
  }
}
