<div
  (click)="cancel()"
  class="modal"
  [ngStyle]="{
    display: visible ? 'block' : 'none'
  }"
>
  <div class="modal-dialog" (click)="preventPropagation($event)">
    <div class="modal-content border-0">
      <div class="modal-body text-center p-4 p-md-5">
        <h4 class="mb-3">We're moving to callan.app</h4>
        <p class="mb-0">You'll be redirected in</p>
        <p class="my-5">
          <span class="h2">{{ timer }}</span>
          <span class="text-info"> sec.</span>
        </p>
        <button
          class="btn btn-outline-light rounded-pill py-2 px-3"
          (click)="redirect()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 16 16"
            height="16px"
            viewBox="3 2 20 22"
            width="16px"
            fill="#000000"
          >
            <g>
              <path
                d="M22,12c0-5.52-4.48-10-10-10C6.48,2,2,6.48,2,12s4.48,10,10,10C17.52,22,22,17.52,22,12z M4,12c0-4.42,3.58-8,8-8 c4.42,0,8,3.58,8,8s-3.58,8-8,8C7.58,20,4,16.42,4,12z M16,12l-4,4l-1.41-1.41L12.17,13H8v-2h4.17l-1.59-1.59L12,8L16,12z"
                fill="#FFFFFF"
              />
            </g>
          </svg>
          Go there now
        </button>
      </div>
    </div>
  </div>
</div>
