/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exercise-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/kids-rotation.directive";
import * as i4 from "../../services/theme.service";
import * as i5 from "./exercise-box.component";
var styles_ExerciseBoxComponent = [i0.styles];
var RenderType_ExerciseBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExerciseBoxComponent, data: {} });
export { RenderType_ExerciseBoxComponent as RenderType_ExerciseBoxComponent };
function View_ExerciseBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["appKidsRotation", ""], ["class", "exercise-entry-block cursor-pointer"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(4, 147456, null, 0, i3.KidsRotationDirective, [i1.ElementRef, i4.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "exercise-entry-block cursor-pointer"; var currVal_1 = _co.getStatusClass(); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getName(); _ck(_v, 5, 0, currVal_3); }); }
export function View_ExerciseBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExerciseBoxComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isReady(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ExerciseBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exercise-box", [], null, null, null, View_ExerciseBoxComponent_0, RenderType_ExerciseBoxComponent)), i1.ɵdid(1, 114688, null, 0, i5.ExerciseBoxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExerciseBoxComponentNgFactory = i1.ɵccf("app-exercise-box", i5.ExerciseBoxComponent, View_ExerciseBoxComponent_Host_0, { exercise: "exercise", availability: "availability" }, { exerciseClick: "exerciseClick" }, []);
export { ExerciseBoxComponentNgFactory as ExerciseBoxComponentNgFactory };
