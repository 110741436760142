import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { QuestionComponentBase, AnswerWrapper } from '../question-component-base';
import { QaAnswer, Qa } from 'src/app/model/questions';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AudioRecorderService } from 'src/app/services/audio-recorder.service';
import { RecorderState } from 'src/app/services/RecorderState';
import { Recorder, BlobUnsupported } from 'src/app/services/Recorder';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss']
})
export class QuestionAnswerComponent extends QuestionComponentBase<QaAnswer, Qa> implements OnInit, OnDestroy {

  showQuestionContent = false;
  showAnswer = false;
  answer: QaAnswer;
  recorderSubscription: Subscription;
  recorder: Recorder;
  showPlay = false;
  currentPromptNumber = 0;

  @ViewChild('audioTarget', {static: false}) audioTarget: ElementRef;
  recording = false;
  readyToPlayNative = false;
  recorderStateSubscription: Subscription;

  log(text: string) {
    if (environment.debug) {
      console.log(text);
    }
  }

  hasRecorder() {
    const res = this.recorder != null;
    this.log(`qa - hasRecorder() => ${res}`);
    return res;
  }

  stopRecording() {
    this.recorder.stop().subscribe( blob => {
      if (blob == null) {
        this.readyToPlayNative = true;
      } else {
        this.audioTarget.nativeElement.src = URL.createObjectURL(blob);
      }
      this.showPlay = true;
    });
    this.log('qa - stopRecording()');
  }

  recordClicked() {
    this.recorder.stop().pipe(
      flatMap( () => this.recorder.clear())
    ).subscribe(
      () => {
        this.recorder.record();
        this.log('qa - recordClicked()');
      }
    );
  }

  isRecording() {
    this.log(`qa - isRecording() => ${this.recording}`);
    return this.recording;
  }

  isShowPlay() {
    this.log(`qa - isShowPlay() => ${this.showPlay}`);
    return this.showPlay;
  }

  play() {
    this.log(`qa - play`);
    this.recorder.stop().subscribe( blob => {
      if (blob == null) {
        this.log('qa - ready to play native, playing the content by native');
        this.recorder.play();
      } else {
        this.log('qa - playing by html5 tag');
        this.audioTarget.nativeElement.load();
        this.audioTarget.nativeElement.play();
      }
    });
  }

  getAnswerForSubmit(): Observable<AnswerWrapper<QaAnswer>> {
    this.log(`qa - getAnswerForSubmit() => (Observable ${this.answer})`);
    return of(new AnswerWrapper(this.answer));
  }

  getQuestionContent() {
    if (!this.question) {
      this.log(`qa - getQuestionContent() => ""`);
      return '';
    }
    if (!this.showQuestionContent) {
      this.log(`qa - getQuestionContent() => "..."`);
      return '...';
    }
    this.log(`qa - getQuestionContent() => "${this.question.question.definition.question}"`);
    return this.question.question.definition.question;
  }

  turnQuestionContent() {
    this.log(`qa - turnQuestionContent() => `);
    this.showQuestionContent = !this.showQuestionContent;
  }

  isShowQuestionContent() {
    this.log(`qa - isShowQuestionContent() => ${this.showQuestionContent}`);
    return this.showQuestionContent;
  }

  isShowAnswerContent() {
    this.log(`qa - isShowAnswerContent() => ${this.currentPromptNumber > 0}`);
    return this.currentPromptNumber > 0;
  }

  turnAnswerContent() {
    this.log(`qa - turnAnswerContent() => `);
    this.currentPromptNumber++;
    if (this.currentPromptNumber > this.question.question.definition.prompt.length) {
      this.currentPromptNumber = 0;
    }
  }

  isShowAnswer() {
    this.log(`qa - usShowAnswer() => ${this.showAnswer}`);
    return this.showAnswer ;
  }

  playAnswer() {
    this.log(`qa - playAnswer() => `);
    this.playUrl(environment.awsBase + this.question.question.definition.src + 'answer.mp3',
    () => {
      if (!this.answer) {
        this.answer = new QaAnswer();
      }
      this.allowNext = true;
    });
  }

  isPlaying = false;

  playUrl(url: string, onFinish: () => void) {
    if (this.isPlaying) return;
    this.isPlaying = true;
    const audio = new Audio();
    audio.src = url;
    audio.onended = () => {
      this.isPlaying = false;
      onFinish();
    };
    audio.load();
    audio.play().then(
      null,
      () => this.isPlaying = false
    );
  }

  playQuestion() {
    this.log(`qa - playQuestion() => `);
    if (!this.question) {
      return;
    }
    this.playUrl(
      environment.awsBase + this.question.question.definition.src + 'question.mp3',
      () => this.showAnswer = true);
  }

  isAvatar() {
    if (!this.question || this.question.question.definition.img) {
      return false;
    }
    return true;
  }

  isQuestionBlinking() {
    this.log(`qa - isQuestionBlinking() => ${!this.isShowAnswer()}`);
    return !this.isShowAnswer();
  }

  isAnswerBlinking() {
    this.log(`qa - isAnswerBlinking() => ${!this.answer}`);
    return !this.answer;
  }

  getImage() {
    if (!this.question) {
      this.log(`qa - getImage() => ""`);
      return '';
    }
    if (this.question.question.definition.img) {
      this.log(`qa - getImage() => ${environment.awsBase + this.question.question.definition.img}`);
      return environment.awsBase + this.question.question.definition.img;
    }
    if (this.question.question.definition.female) {
      this.log(`getImage() => female`);
      return 'https://s3.amazonaws.com/callan_assets/images/assets/female.png';
    }
    this.log(`getImage() => male`);
    return 'https://s3.amazonaws.com/callan_assets/images/assets/male.png';
  }

  setupAnswer(answer: QaAnswer) {
    this.log(`qa - setupAnswer(${answer}) => `);
    this.answer = answer;
    if (answer) {
      this.showAnswer = true;
      this.allowNext = true;
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.type === 'keydown' && event.key === 'Enter') {
      this.doFinish();
    }
  }

  getAnswerContent() {
    if (!this.question) {
      this.log(`qa - getAnswerContent() => ""`);
      return '';
    }
    if (this.currentPromptNumber === this.question.question.definition.prompt.length) {
      this.log(`qa - getAnswerContent() => ${this.question.question.definition.questionAnswer}`);
      return this.question.question.definition.questionAnswer;
    } else {
      this.log(`qa - getAnswerContent() => ${this.question.question.definition.prompt[this.currentPromptNumber]}`);
      return this.question.question.definition.prompt[this.currentPromptNumber];
    }
  }

  constructor(private audioRecorder: AudioRecorderService) {
    super();
    this.allowNext = false;
  }

  ngOnInit() {
    this.log('initializing Q&A - trying to get a native recorder');
    this.recorderSubscription = this.audioRecorder.init().subscribe( recorder => {
      this.log('Q&A - setting up recorder after initialization.');
      this.recorder = recorder;
      this.recorderStateSubscription = recorder.getRecorderState().subscribe(state => this.recording = state === RecorderState.Recording);
    }, error => {
      this.log('QA - got recorder init error.');
      this.log(error);
    }, () => {
      this.log('QA - get recorder complete');
    });
  }

  ngOnDestroy() {
    this.log(`qa - onDestroy() => `);
    super.ngOnDestroy();
    if (this.recorderSubscription) {
      this.recorderSubscription.unsubscribe();
    }

    if (this.recorderStateSubscription) {
      this.recorderStateSubscription.unsubscribe();
    }
  }


}
