import { Observable, of } from 'rxjs';
import { RecorderState } from './RecorderState';
export interface Recorder {
  play();
  destroy(): void;
  clear(): Observable<void>;
  record(): void;
  stop(): Observable<Blob>;
  getRecorderState(): Observable<RecorderState>;
}

export class EmptyRecorder implements Recorder {
  play() {
  }
  destroy(): void {
  }
  clear(): Observable<void> {
    return null;
  }
  record(): void {
  }
  stop(): Observable<Blob> {
    return null;
  }
  getRecorderState(): Observable<RecorderState> {
    return of();
  }
}

export class BlobUnsupported extends Blob {
}
