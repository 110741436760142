import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-cfk-home',
  templateUrl: './cfk-home.component.html',
  styleUrls: ['./cfk-home.component.scss']
})
export class CfkHomeComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.theme.clear();
  }

  constructor(private theme: ThemeService) { }

  ngOnInit() {
    this.theme.exerciseSet.next('encfk');
  }

}
