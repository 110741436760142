/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./oauth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./oauth.component";
import * as i4 from "../../services/auth.service";
import * as i5 from "@angular/router";
var styles_OauthComponent = [i0.styles];
var RenderType_OauthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OauthComponent, data: {} });
export { RenderType_OauthComponent as RenderType_OauthComponent };
export function View_OauthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mt-5 text-center"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Authorizing..."]))], function (_ck, _v) { var currVal_0 = "pages.auth.in-progress"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OauthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-oauth", [], null, null, null, View_OauthComponent_0, RenderType_OauthComponent)), i1.ɵdid(1, 114688, null, 0, i3.OauthComponent, [i4.AuthService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OauthComponentNgFactory = i1.ɵccf("app-oauth", i3.OauthComponent, View_OauthComponent_Host_0, {}, {}, []);
export { OauthComponentNgFactory as OauthComponentNgFactory };
