import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuControlsService {
  showMenu = new BehaviorSubject(true);
  closeWindowOnFinish = new BehaviorSubject(false);

  constructor() {
  }

  updateMenuState(hasToShow: boolean) {
    this.showMenu.next(hasToShow);
  }

  updateCloseOnFinish(closeOnFinish: boolean) {
    this.closeWindowOnFinish.next(closeOnFinish);
  }

}
