import { CasaExerciseSession, CasaSessionQuestionDefinition, CasaQuestionScoreSubmit
    } from 'src/app/model/oldModel';
import { Observable, of, BehaviorSubject} from 'rxjs';
import { RecorderState } from 'src/app/services/RecorderState';
import { Recorder} from 'src/app/services/Recorder';
import { map, flatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {ExerciseSession, ItemAvailability} from "../../model/personal";
import {Chapter, ExerciseSet} from "../../model/struct";
import {Question} from "../../model/questions";


export interface MobileNativeApi {
    createSession(exerciseId: number): Observable<CasaExerciseSession>;
    recreateSession(sessionId: number): Observable<CasaExerciseSession>;
    getSessionData(sessionId: number): Observable<CasaExerciseSession>;
    getSessionQuestionDefinition(sessionId: number, questionId: number): Observable<CasaSessionQuestionDefinition>;
    submitSessionScore(sessionId: number, questionId: number, submit: CasaQuestionScoreSubmit): Observable<boolean>;
    recalculateScore(): Observable<void>;
    closeQuestions(): Observable<boolean>;
}

export interface NewMobileNativeApi {
  /**
   * 1) send all sessions stored locally to the server in natural order (FIFO)
   * 2) remove local session after success complete
   */
  sendStoredSessions(): Observable<any>;

  /**
   * read exercise set structure from file
   * Application is responsible for synchronization at itself.
   *
   * Application may need to read exercise sets list before running the
   * angular app, i.e. to check if specific lang set is available.
   * - use proper REST call to list exercise sets - may be done on application init
   * or in time interval
   * - use proper REST call to load availabilities with path = '' and depth = 1,
   * store in separated file
   * On dashboard you can display available exercise sets for specific language
   * with information about set availability, progress and score
   */
  listExerciseSets(): Observable<ExerciseSet[]>;

  /**
   * Load chapters from file related to the provided @exerciseSet
   * @param exerciseSet
   */
  getChapters(exerciseSet: string): Observable<Chapter[]>;

  /**
   * Sync chapters for @exerciseSet and return the result.
   * CAUTION: do not replace the file unless you are sure about
   * data integrity (it has to be fully downloaded first).
   * @param exerciseSet
   * @param syncFrequencyMs update frequency in ms. Store last sync date in db.
   * Compare the file age with current time. if less, skip sync
   * and return value from file. Otherwise do the sync and return new data.
   *
   */
  syncChapters(exerciseSet: string, syncFrequencyMs: number): Observable<Chapter[]>;

  /**
   * see @syncChapters - do the same but with availabilities.
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncAvailabilities(exerciseSet: string, syncFrequencyMs: number): Observable<any>;

  /**
   * Load availabilities from the file.
   * @param exerciseSet
   */
  getAvailabilities(exerciseSet: string): Observable<ItemAvailability[]>;

  /**
   * see @syncChapters - do the same for questions
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncQuestions(exerciseSet: string, syncFrequencyMs: number): Observable<any>;

  /**
   * load question from file
   * @param exerciseSet
   */
  getQuestions(exerciseSet: string): Observable<Question<any, any>[]>;

  /**
   * store the session as a current session
   * @param session
   */
  storeCurrentSession(session: ExerciseSession): Observable<ExerciseSession>;

  /**
   * load session from current session file
   */
  getCurrentSession(): Observable<ExerciseSession>;

  /**
   * push the session to the list of sessions to sync
   * @param session
   */
  pushSession(session: ExerciseSession): Observable<ExerciseSession>;

  /**
   * save availability in the file
   * @param exerciseSet
   * @param availability
   */
  storeAvailability(exerciseSet: string, availability: ItemAvailability[]): Observable<ItemAvailability[]>;

  /**
   * close
   */
  close(): void;
}

export interface MobileNativeAudio {
    initAudio(callback: (state: boolean) => void);
    clear(): Observable<void>;
    record();
    stop(): Observable<void>;
    play2();
    terminate();
}

export interface FullMobileNativeApi extends MobileNativeApi, MobileNativeAudio {}

export class MobileNativeAudioRecorderAdapter implements Recorder {
    state = new BehaviorSubject<RecorderState>(RecorderState.Stopped);
    constructor( private mobileApi: MobileNativeAudio) {}
    recording = false;

    log(text: string) {
        if (environment.debug) {
            console.log(text);
        }
    }

    play() {
        this.log('native recorder - play called');
        this.stop().subscribe( _ => {
            this.mobileApi.play2();
        });
    }

    destroy(): void {
        this.log('native recorder - destroy called');
        this.stop().subscribe();
        this.mobileApi.terminate();
    }

    clear(): Observable<void> {
        this.log('native recorder - clear called');
        return this.stop().pipe(
            flatMap( _ => this.mobileApi.clear())
        );
    }

    record(): void {
        this.log('native recorder - record called');
        this.clear().subscribe( _ => {
            this.mobileApi.record();
            this.recording = true;
            this.state.next(RecorderState.Recording);
        });
    }

    stop(): Observable<Blob> {
        this.log('native recorder - stop called');
        if (this.recording) {
            this.recording = false;
            this.state.next(RecorderState.Stopped);
            this.log('native recorder - on stop - calling stop to native api');
            return this.mobileApi.stop().pipe(
                map( () => null)
            );
        }
        return of(null);
    }

    getRecorderState(): Observable<RecorderState> {
        return this.state;
    }

    public init(callback: (state: boolean) => void) {
        this.mobileApi.initAudio(callback);
    }
}
