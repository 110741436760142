import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthService } from 'src/app/services/auth.service';
import { CasaRestServiceMain } from 'src/app/services/casa-rest.service';
import { Person, AuthorizationProfile } from 'src/app/model/casa';
import { tap } from 'rxjs/operators';
import { MenuControlsService } from 'src/app/services/menu-controls.service';
import {ApiServiceProvider} from "../../services/rest-provider.service";
import {CspaRestService} from "../../services/rest/cspa-rest.service";

@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.component.html',
  styleUrls: ['./menu-layout.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({
        right: '0px',
      })),
      state('hide', style({
        right: '-260px',
        display: 'none'
      })),
      transition('show => hide', [
        animate('0.2s ease-in' )
      ]),
      transition('hide => show', [
        style({display: 'block'}),
        animate('0.2s ease-out'),
      ])
    ]),
    trigger('showDim', [
      state('show', style({
        opacity: 1,
      })),
      state('hide', style({
        opacity: 0,
        display: 'none'
      })),
      transition('show => hide', [
        animate('0.2s ease-in')
      ]),
      transition('hide => show', [
        style({display: 'block'}),
        animate('0.2s ease-out')
      ])
    ])
  ]
})
export class MenuLayoutComponent implements OnInit {

  menuOpened = false;
  person: Person;
  CALLAN_ONLINE_LU_ID = 1;
  colProfile: AuthorizationProfile;
  PAY_LABEL = 'buyeBooks';
  enableMenu = false;
  private readonly _isMenuSupported: Boolean;

  constructor(private authService: AuthService,
              private casaRest: CasaRestServiceMain,
              private menuControls: MenuControlsService,
              apiProvider: ApiServiceProvider) {
    this._isMenuSupported = !apiProvider.getRestServiceImplementation().isNativeImplementation();
    if (this._isMenuSupported) {
      casaRest.loadSelfPerson().subscribe( person => this.person = person);
      casaRest.listProfiles()
        .pipe(
          tap( profiles => this.searchForColProfile(profiles))
        )
        .subscribe( );
      menuControls.showMenu.subscribe(v => this.enableMenu = v);
    }
  }

  isMenuEnabled() {
    return this._isMenuSupported && this.enableMenu;
  }

  hasCol() {
    return this.colProfile != null;
  }

  goToCol() {
    this.casaRest.authInCol(this.colProfile).subscribe(code => {
      const target = code.redirectUrl + '?code=' + code.codeValue + '&state=Lw%3D%3D';
      window.open(target, '_blank');
    });
  }


  private searchForColProfile(profiles: AuthorizationProfile[]) {
    this.colProfile = profiles.find( p =>
        p.learningUnitId === this.CALLAN_ONLINE_LU_ID
        && p.profileRole === 'STUDENT'
      );
  }

  ngOnInit() {
  }

  getName() {
    if (!this.person) {
      return '';
    }
    const res = [];
    if (this.person.name) {
      res.push(this.person.name);
    }
    if (this.person.surname) {
      res.push(this.person.surname);
    }
    return res.join(' ');
  }

  getEmail() {
    if (!this.person) {
      return '';
    }
    return this.person.registrationEmail;
  }

  logout() {
    this.authService.logout();
  }

  showMenu() {
    this.menuOpened = true;
  }

  hideMenu() {
    this.menuOpened = false;
  }

  isShowMenu() {
    return this.menuOpened;
  }

}
