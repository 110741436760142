/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/kids-rotation.directive";
import * as i3 from "../../services/theme.service";
import * as i4 from "./top-bar.component";
var styles_TopBarComponent = [i0.styles];
var RenderType_TopBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopBarComponent, data: {} });
export { RenderType_TopBarComponent as RenderType_TopBarComponent };
export function View_TopBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-auto mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["appKidsRotation", "10"], ["class", "btn btn-light text-dark"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 147456, null, 0, i2.KidsRotationDirective, [i1.ElementRef, i3.ThemeService], { range: [0, "range"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col align-self-center pt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "h3", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "10"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getTitle(); _ck(_v, 6, 0, currVal_1); }); }
export function View_TopBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-bar", [], null, null, null, View_TopBarComponent_0, RenderType_TopBarComponent)), i1.ɵdid(1, 114688, null, 0, i4.TopBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopBarComponentNgFactory = i1.ɵccf("app-top-bar", i4.TopBarComponent, View_TopBarComponent_Host_0, { title: "title" }, { closeClick: "closeClick" }, []);
export { TopBarComponentNgFactory as TopBarComponentNgFactory };
