import { Injectable } from '@angular/core';
import { ExerciseSession } from '../model/personal';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionDataHolderService {
  session = new BehaviorSubject<ExerciseSession>(null);

  log(text: any) {
    if (environment.debug) {
      console.log(text);
    }
  }
  updateSession(session: ExerciseSession): void {
    this.log('Session holder - updating session data');
    this.log(session);
    this.session.next(session);
  }

  constructor() { }
}
