<app-top-bar [title]="getTopBarTitle()" (closeClick)="closeExerciseSet()"></app-top-bar>

<div class="progress mt-1 mb-2 lesson-progress" >
    <div class="progress-bar" role="progressbar" [ngStyle]='getProgressStyle()' aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
<h5 *ngIf="hasInstructions()" class="mt-2" [innerHtml]="getInstructions()"></h5>

<ng-template appDynamicHost></ng-template>

<app-footer-bar
            [showNext]="isShowNext()"
            [showPrev]="isShowPrev()"
            (interact)="onFooterInteract($event)"
            [thirdDescription]="getMiddleDescription()"
        >
</app-footer-bar>
