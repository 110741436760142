import {CspaRestService} from "../../services/rest/cspa-rest.service";
import {combineLatest, EMPTY, forkJoin, iif, Observable, of, pipe, throwError} from "rxjs";
import {ExerciseSession, ExerciseSessionQuestion, ItemAvailability} from "../../model/personal";
import {Chapter, Exercise, ExerciseSet, Section} from "../../model/struct";
import {AnswerDefinitionBase, Dictation, Question} from "../../model/questions";
import {NewMobileNativeApi} from "./MobileNativeApi";
import {catchError, defaultIfEmpty, map, switchMap, tap} from "rxjs/operators";
import {OldCasaQuestionsScoringService} from "./OldCspaQuestionScoring";
import {ScoreAvailabilityApplyService} from "./score-availability-apply.service";
import {LoggerService} from "../../services/logger.service";


export interface MobileBridgeServices {
  syncSessions(): Observable<void>;
  syncStructure(exerciseSet: string): Observable<void>;
  close(): void;

  isDataValid(exerciseSet: string): Observable<boolean>;
}

export class NewMobileBridgeService implements CspaRestService, MobileBridgeServices {
  isDataValid(exerciseSet: string): Observable<boolean> {
    return forkJoin(
      this.newMobileNativeApi.getQuestions(exerciseSet),
      this.newMobileNativeApi.getChapters(exerciseSet),
      this.newMobileNativeApi.getAvailabilities(exerciseSet)
    ).pipe(
      map( ([questions, chapters, availabilities]) =>
        questions.length > 0 && chapters.length > 0  && availabilities.length > 0
      ),
      catchError( _ => of(false))
    )
  }

  /*
  values passed to the native part during data synchronization
   */
  private CHAPTERS_SYNC_FREQUENCY_MS = 1000 * 60 * 15;
  private AVAILABILITY_SYNC_FREQUENCY = 1000 * 60 * 15;
  private QUESTIONS_SYNC_FREQUENCY_MS = 1000 * 60 * 15;
  private scoringService = new OldCasaQuestionsScoringService();
  private availabilityUpdate = new ScoreAvailabilityApplyService();
  private DICT_PASS_SCORE = 0.6;
  private DEFAULT_PASS_SCORE = 0.9;

  constructor(private newMobileNativeApi: NewMobileNativeApi, private logger: LoggerService) {
  }

  isNativeImplementation(): Boolean {
    return true;
  }

  /**
   * ask the app to send stored sessions to the server
   */
  syncSessions(): Observable<void> {
    return this.newMobileNativeApi.sendStoredSessions();
  }

  /**
   * complex synchronization flow:
   * - questions
   * - chapters
   * - availability
   * @param exerciseSet
   */
  syncStructure(exerciseSet: string): Observable<void> {
    return this.syncQuestions(exerciseSet).pipe(
      defaultIfEmpty(_ => 'question synced'),
      switchMap( _ => this.syncChapters(exerciseSet)),
      defaultIfEmpty( _ => 'chapters synced'),
      switchMap( _ => this.syncAvailabilities(exerciseSet))
    );
  }

  /**
   * load chapters by path, simply passed to the mobile
   * @param path exercise set path ending with '_'
   * @param updatedAfter
   */
  listChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
    return this.newMobileNativeApi.getChapters(path);
  }

  /**
   * Synchronize chapters structure definition from server for specific exercise set
   * @param exerciseSet
   */
  private syncChapters(exerciseSet: string): Observable<any> {
    return this.newMobileNativeApi.syncChapters(`${exerciseSet}_`, this.CHAPTERS_SYNC_FREQUENCY_MS);
  }

  /**
   * synchronize availabilities from server for specific exercise set
   * @param exerciseSet
   */
  private syncAvailabilities(exerciseSet: string): Observable<any> {
    return this.newMobileNativeApi.syncAvailabilities(`${exerciseSet}_`, this.AVAILABILITY_SYNC_FREQUENCY);
  }

  /**
   * read availabilities for exericse set
   * @param path
   * @param depth
   */
  listAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
    return this.newMobileNativeApi.getAvailabilities(path);
  }

  /**
   * sync questions structure for exercise set
   * @param exerciseSet
   */
  private syncQuestions(exerciseSet: string): Observable<any> {
    return this.newMobileNativeApi.syncQuestions(`${exerciseSet}_`, this.QUESTIONS_SYNC_FREQUENCY_MS);
  }

  /**
   * load list of exercise sets
   */
  listExerciseSets(): Observable<ExerciseSet[]> {
    return this.newMobileNativeApi.listExerciseSets();
  }

  startExerciseSession(exercisePath: string, prevSession?: ExerciseSession): Observable<ExerciseSession> {
    const exercise = this.findExerciseByPath(exercisePath);
    const sessionQuestions = this.readSessionQuestions(exercisePath);
    return forkJoin(exercise, sessionQuestions).pipe(
      map( ([[chapter, section, exercise], questions]) =>
        this.createSession(chapter, section, exercise, questions, prevSession)),
      switchMap( session =>
        this.newMobileNativeApi.storeCurrentSession(session)
      )
    )
  }

  private filterQuestionsByPrevSession(baseQuestions: Question<any, any>[],
                                       prevSession: ExerciseSession) {
    if (prevSession != null) {
      this.logger.log(`prev session was provided during new session create with ${prevSession.questions.length} answered questions`);
      this.logger.log('prev session scores:' + prevSession.questions.map(q => q.score).join(","));
    }
    else this.logger.log("no prev session");
    if (!prevSession) return baseQuestions;
    const incorrectQuestionIds = new Set(prevSession.questions
      .filter( q => !this.isQuestionCorrect(q))
      .map( q => q.question.id))
    this.logger.log(`found ${incorrectQuestionIds.size} incorrect questions in prev session`);
    return baseQuestions.filter( q => incorrectQuestionIds.has(q.id))
  }

  private createSession(
    chapter: Chapter,
    section: Section,
    exercise: Exercise,
    baseQuestions: Question<any, any>[],
    prevSession?: ExerciseSession): ExerciseSession {
    const questions = this.filterQuestionsByPrevSession(baseQuestions, prevSession)
    const session = new ExerciseSession();
    session.baseQuestionNumber = baseQuestions.length;
    session.deviceUUID = this.uuidv4();
    session.chapterName = chapter.shortName; //check
    session.exerciseName = exercise.name; //check
    session.lastUpdateDate = new Date().getTime();
    session.path = `${chapter.path}_${section.path}_${exercise.path}`;
    session.score = 0;
    session.sectionName = section.name; //check;
    session.questions = questions.map( q => {
      const sq = new ExerciseSessionQuestion();
      sq.answered = false;
      sq.correct = false;
      sq.question = q;
      sq.score = 0;
      sq.updateDate = new Date().getTime();
      return sq;
    });
    session.startDate = new Date().toUTCString();
    session.prevSession = prevSession;
    return session;
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private findExerciseByPath(targetPath: string): Observable<[Chapter, Section, Exercise]> {
    const [exerciseSet, chapterPath, sectionPath, exercisePath] = targetPath.split("_");
    const chapterFullPath = `${exerciseSet}_${chapterPath}`;
    return this.newMobileNativeApi.getChapters(`${exerciseSet}_`).pipe(
      map( chapters => {
        const chapter = chapters.find( ch => ch.path === chapterFullPath);
        const section = chapter.sections.find( sec => sec.path === sectionPath);
        const exercise = section.exercises.find( ex => ex.path === exercisePath);
        return [chapter, section, exercise]
      }),
    )
  }

  private readSessionQuestions(exercisePath: string): Observable<Question<any, any>[]> {
    const exerciseSet = exercisePath.split('_')[0];
    const exercisePathForSearch = `${exercisePath}_`;
    return this.newMobileNativeApi.getQuestions(`${exerciseSet}_`).pipe(
      map(allQuestions =>
        allQuestions
          .filter(question => question.path.startsWith(exercisePathForSearch))
          .sort((lEl ,rEl) => {
              const [l,r] = [lEl.orderNumber, rEl.orderNumber];
              if (l != null && r != null) return l - r;
              if (l == null && r == null) return 0;
              if (l == null) return -1;
              return 1;
            }
          )
      ),
    )
  }

  postSessionQuestionAnswer<A extends AnswerDefinitionBase>(uuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExerciseSession> {
    return this.getExerciseSession(uuid).pipe(
      tap( session => this.saveSessionQuestionAnswer(session, questionNb, sessionQuestion)),
      switchMap( session => this.newMobileNativeApi.storeCurrentSession(session))
    )
  }

  private saveSessionQuestionAnswer<A extends AnswerDefinitionBase>(session: ExerciseSession, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>) {
    const answeredQuestion = session.questions[questionNb];
    answeredQuestion.answer = sessionQuestion.answer;
    answeredQuestion.answered = true;
    answeredQuestion.updateDate = new Date().getTime();
    answeredQuestion.submitDate = new Date().getTime();
    session.lastUpdateDate = new Date().getTime();
  }

  finishSession(uuid: string): Observable<ExerciseSession> {
    this.log(`finishing session ${uuid}`);
    return this.getExerciseSession(uuid).pipe(
      switchMap( session =>
        this.scoreSession(session)
      ),
      switchMap( (session:ExerciseSession) => this.newMobileNativeApi.storeCurrentSession(session)),
      switchMap( (session: ExerciseSession) => this.newMobileNativeApi.pushSession(session)),
      switchMap( session => this.recalculateAvailability(session)),
      tap( session => {
        this.syncAfterSessionFinish(session);
      })
    )
  }

  scoreSession(session: ExerciseSession): Observable<ExerciseSession> {
    for (const question of session.questions) {
      if (!question.answered) {
        question.score = 0;
        continue;
      }
      const scoreResult = this.scoringService.score(question);
      question.score = scoreResult.score;
      question.correct = this.isQuestionCorrect(question);
    }
    session.score = session.questions
      .reduce((acc, item) => acc + item.score, 0)
     / session.questions.length;
    session.finishDate = new Date().getTime();
    return of(session);
  }

  private recalculateAvailability(session: ExerciseSession): Observable<ExerciseSession> {
    const exerciseSet = `${session.path.split('_')[0]}_`;
    return combineLatest(
      this.newMobileNativeApi.getAvailabilities(exerciseSet),
      this.newMobileNativeApi.getChapters(exerciseSet),
      this.newMobileNativeApi.getQuestions(exerciseSet)
    ).pipe(
      map(
        ([availability,chapters, questions]) =>
          this.availabilityUpdate.updateAvailabilityWithScoredSession(chapters, questions, availability, session)),
      switchMap( availability => this.newMobileNativeApi.storeAvailability(exerciseSet, availability)),
      map( _ => session)
    );
  }

  private isQuestionCorrect(question: ExerciseSessionQuestion<any, any>) {
    if (question.question.definition instanceof Dictation) {
      return question.score > this.DICT_PASS_SCORE;
    }
    return question.score > this.DEFAULT_PASS_SCORE;
  }

  private syncAfterSessionFinish(session: ExerciseSession) {
    const exerciseSet = session.path.split('_')[0];
    this.syncSessions().pipe(
      switchMap( _ => this.syncStructure(exerciseSet))
    ).subscribe();
  }

  recreateExerciseSession(uuid: string): Observable<ExerciseSession> {
    return this.getExerciseSession(uuid).pipe(
      switchMap( prevSession => this.startExerciseSession(prevSession.path, prevSession))
    )
  }

  getExerciseSession(uuid: string): Observable<ExerciseSession> {
    return this.newMobileNativeApi.getCurrentSession().pipe(
      switchMap( session =>
        iif(() => session && session.deviceUUID === uuid,
          of(session),
          throwError(new Error('invalid session'))))
    );
  }

  close(): void {
    this.log('calling native api for close the app');
    this.newMobileNativeApi.close();
  }


  listQuestions(pathPreffixes: string[], updatedAfter?: number): Observable<Question<any, any>> {
    this.log(`listing questions for ${pathPreffixes} updated after ${updatedAfter}`);
    return throwError(new Error('Operation not supported'));
  }

  questionSessionFinished() {
    this.log('question session finished');
    return throwError(new Error('Operation not supported'));
  }

  findSessionById(sessionId: number | string): Observable<ExerciseSession> {
    this.log(`querying for session by id ${sessionId}`);
    return throwError(new Error('Operation not supported'));
  }

  startExerciseSessionById(exerciseId: number): Observable<ExerciseSession> {
    this.log(`starting session by exercise id ${exerciseId}`);
    return throwError(new Error('Operation not supported'));
  }

  submitSessions(sessions: ExerciseSession[]): Observable<void> {
    this.log(`submitting ${sessions.length} sessions`)
    return throwError(new Error('Operation not supported'));
  }

  private log(s: string) {
    this.logger.log(`[MOB]: ${s}`);
  }

  closeSession(sessionUuid: string): Observable<void> {
    return EMPTY;
  }

}
