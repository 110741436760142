import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  visible = false;
  cancellable = true;

  cancelSubject = new Subject<any>();

  constructor() { }

  dimClick() {
    if (this.cancellable) {
      this.cancel();
    }
  }

  public show(cancellable?: boolean) {
    this.visible = true;
    if (cancellable !== undefined && cancellable != null) {
      this.cancellable = cancellable;
    }
  }
  preventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
  public hide() {
    this.visible = false;
  }

  public cancel() {
    this.hide();
    this.cancelSubject.next();
  }

  ngOnInit() {
  }

}
