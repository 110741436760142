import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ExerciseSet, Chapter } from 'src/app/model/struct';
import { Question, AnswerDefinitionBase } from 'src/app/model/questions';
import { ExerciseSession, ItemAvailability, ExerciseSessionQuestion } from 'src/app/model/personal';
import {fromArray} from "rxjs/internal/observable/fromArray";

export interface CspaRestService {
  listExerciseSets(): Observable<ExerciseSet[]>;
  listChapters(path: string, updatedAfter?: number): Observable<Chapter[]>;
  listQuestions(pathPrefixes: string[], updatedAfter?: number): Observable<Question<any, any>>;
  startExerciseSession(exercisePath: string): Observable<ExerciseSession>;
  startExerciseSessionById(exerciseId: number): Observable<ExerciseSession>;
  getExerciseSession(uuid: string): Observable<ExerciseSession>;
  recreateExerciseSession(uuid: string): Observable<ExerciseSession>;
  postSessionQuestionAnswer<A extends AnswerDefinitionBase>(
    uuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExerciseSession>;
  submitSessions(sessions: ExerciseSession[]): Observable<void>;
  finishSession(uuid: string): Observable<ExerciseSession>;
  listAvailabilities(path: string, depth: number): Observable<ItemAvailability[]>;
  findSessionById(sessionId: number | string): Observable<ExerciseSession>;
  // this is not a rest call. It is used only for compatibility with
  // the mobile native api. Used to notify mobiles about finish
  questionSessionFinished();
  isNativeImplementation(): Boolean;
  closeSession(sessionUuid: string): Observable<void>;
}

@Injectable({
  providedIn: 'root'
})
export class CspaRestServiceNew implements CspaRestService {

  private apiEndpoint = environment.apiEndpoint;
  constructor(private http: HttpClient) {
  }

  private buildPath(path: string) {
    return this.apiEndpoint + '/api' + path;
  }

  public listExerciseSets(): Observable<ExerciseSet[]> {
    return this.http.get<ExerciseSet[]>(this.buildPath('/sets'));
  }

  questionSessionFinished() {
    // do nothing - does not have any impact
  }

  /** not used in standard context */
  startExerciseSessionById(exerciseId: number): Observable<ExerciseSession> {
    throw new Error('Method not supported');
  }

  public listChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
    let params = new HttpParams();
    if (updatedAfter != null) {
      params = params.append('updatedAfter', updatedAfter.toString());
    }
    return this.http.get<Chapter[]>(this.buildPath('/sets/' + path) , {params});
  }

  public listQuestions(pathPrefixes: string[], updatedAfter?: number): Observable<Question<any, any>> {
    let params = new HttpParams();
    if (updatedAfter != null) {
      params = params.append('updatedAfter', updatedAfter.toString());
    }
    for (const pathPrefix of pathPrefixes) {
      params = params.append('pathPreffix', pathPrefix);
    }

    return this.http.get<Question<any, any>>(this.buildPath('/questions'), {params});
  }

  public startExerciseSession(exercisePath: string): Observable<ExerciseSession> {
    return this.http.post<ExerciseSession>(this.buildPath('/sessions/create'), exercisePath);
  }

  public getExerciseSession(uuid: string): Observable<ExerciseSession> {
    return this.http.get<ExerciseSession>(this.buildPath('/sessions/' + uuid));
  }

  public recreateExerciseSession(uuid: string): Observable<ExerciseSession> {
    return this.http.post<ExerciseSession>(this.buildPath('/sessions/' + uuid + '/recreate'), {});
  }

  public postSessionQuestionAnswer<A extends AnswerDefinitionBase>(
    uuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExerciseSession> {
    return this.http.post<ExerciseSession>(this.buildPath('/sessions/' + uuid + '/questions/' + questionNb), sessionQuestion);
  }

  public submitSessions(sessions: ExerciseSession[]): Observable<void> {
    return this.http.post<void>(this.buildPath('/sessions'), sessions);
  }

  public finishSession(uuid: string): Observable<ExerciseSession> {
    return this.http.post<ExerciseSession>(this.buildPath(`/sessions/${uuid}/finish`), {});
  }

  public listAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
    const params = new HttpParams().append('path', path).append('depth', depth.toString());
    return this.http.get<ItemAvailability[]>(this.buildPath('/availability'), {params});
  }

  public closeSession(sessionUuid: string): Observable<void>{
    return this.http.post<void>(this.buildPath(`/sessions/${sessionUuid}/close`), {});
  }

  findSessionById(sessionId: number): Observable<ExerciseSession> {
    throw new Error('Method not supported');
  }

  isNativeImplementation(): Boolean {
    return false;
  }

}
