import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  public log(toLog: any) {
    if (!environment.debug) return;
    console.log(toLog);
  }
}
