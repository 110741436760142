import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class MenuControlsService {
    constructor() {
        this.showMenu = new BehaviorSubject(true);
        this.closeWindowOnFinish = new BehaviorSubject(false);
    }
    updateMenuState(hasToShow) {
        this.showMenu.next(hasToShow);
    }
    updateCloseOnFinish(closeOnFinish) {
        this.closeWindowOnFinish.next(closeOnFinish);
    }
}
MenuControlsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuControlsService_Factory() { return new MenuControlsService(); }, token: MenuControlsService, providedIn: "root" });
