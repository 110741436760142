import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { zip } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { ExerciseSession } from 'src/app/model/personal';

@Component({
  selector: 'app-mobile-start-exercise',
  templateUrl: './mobile-start-exercise.component.html',
  styleUrls: ['./mobile-start-exercise.component.scss']
})
export class MobileStartExerciseComponent implements OnInit {

  exerciseId: number;
  sessionId: number | string;
  questionId: number;
  backUrl: string;
  rest: CspaRestService;

  constructor(activatedRoute: ActivatedRoute,
              private restServiceProvider: ApiServiceProvider,
              private router: Router) {
    this.rest = restServiceProvider.getRestServiceImplementation();
    zip(
      activatedRoute.paramMap.pipe(
        tap( params => this.exerciseId = Number(params.get('exerciseId')))
      ),
      activatedRoute.queryParamMap.pipe(
        tap ( params => {
          const sessionIdStr = params.get('sessionId');
          // this.sessionId = isNaN(Number(sessionIdStr)) ? sessionIdStr : Number(sessionIdStr);
          this.sessionId = Number(sessionIdStr);
          this.questionId = Number(params.get('questionId'));
          this.backUrl = params.get('backUrl');
        })
      )
    ).subscribe( _ => this.executeStartExercise());
  }

  executeStartExercise(): void {
    if (this.sessionId && this.questionId) {
      this.rest.findSessionById(this.sessionId)
      .subscribe(
        session => this.router.navigate(
          ['cspa', 'session', session.deviceUUID , 'question', session.questions.findIndex(sq => sq.question.id === this.questionId)]
        )
      );
    } else {
      this.rest.startExerciseSessionById(this.exerciseId).subscribe(
        session => this.router.navigate(['cspa', 'session', session.deviceUUID, 'question', 0])
      );
    }
  }

  ngOnInit() {
  }

}
