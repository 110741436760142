import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { SessionQuestionHostBase } from 'src/app/components/session-question-host/session-question-host.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionDataHolderService } from 'src/app/services/session-data-holder.service';
import { QuestionComponentProviderService } from 'src/app/services/question-component-provider.service';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';

@Component({
  selector: 'app-mobile-session-question-host',
  templateUrl: './mobile-session-question-host.component.html',
  styleUrls: ['./mobile-session-question-host.component.scss']
})
export class MobileSessionQuestionHostComponent extends SessionQuestionHostBase {

  constructor(activatedRoute: ActivatedRoute,
              sessionDataHolder: SessionDataHolderService,
              questionComponentProvider: QuestionComponentProviderService,
              componentFactoryResolver: ComponentFactoryResolver,
              router: Router,
              restProvider: ApiServiceProvider) {
    super(activatedRoute, sessionDataHolder, questionComponentProvider, componentFactoryResolver, router, restProvider);
  }

  finishSession() {
    this.rest.finishSession(this.session.deviceUUID).subscribe( finishedSession => {
      this.sessionDataHolder.session.next(finishedSession);
      this.router.navigate(
        ['cspa',
        'session', this.sessionId, 'summary' ]);
    });
  }

  navigateToQuestion(newQuestionNumber: number) {
    if (newQuestionNumber === this.session.questions.length) {
      this.finishSession();
    } else {
      this.router.navigate(
        ['cspa',
        'session', this.sessionId, 'question', newQuestionNumber]);
    }
  }
}

