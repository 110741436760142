import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceProvider } from 'src/app/services/rest-provider.service';
import { SessionDataHolderService } from 'src/app/services/session-data-holder.service';
import { ThemeService } from 'src/app/services/theme.service';
import { tap, switchMap } from 'rxjs/operators';
import { ExerciseSession } from 'src/app/model/personal';
import { CspaRestService } from 'src/app/services/rest/cspa-rest.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-session-host',
  templateUrl: './mobile-session-host.component.html',
  styleUrls: ['./mobile-session-host.component.scss']
})
export class MobileSessionHostComponent implements OnInit {

  sessionId: string;
  session: ExerciseSession;
  private rest: CspaRestService;

  constructor(private activatedRoute: ActivatedRoute,
              restProvider: ApiServiceProvider,
              private router: Router,
              private sessionDataHolder: SessionDataHolderService,
              private theme: ThemeService) {
    this.rest = restProvider.getRestServiceImplementation();
    activatedRoute.paramMap.pipe(
      tap(
        params => {
          this.sessionId = params.get('sessionId');
          this.log(`session host - got session id = ${this.sessionId} from url` );
          // theme.chapter.next(this.chapter);
          // theme.exerciseSet.next(this.exerciseSet);
        }
      ),
      switchMap( _ => this.loadSession()),
      tap( session => this.sessionDataHolder.updateSession(session)),
      tap( session => this.session = session),
      tap( session => this.updateTheme(session))
    ).subscribe(
      session => this.startQuestionIfRequired()
    );
  }

  log(text: string) {
    if (environment.debug) {
      console.log(text);
    }
  }

  updateTheme(session: ExerciseSession): void {
    if (session && session.questions.find(q => q.question.id >= 8000 && q.question.id < 10000)) {
      this.theme.exerciseSet.next('encfk');
    } else {
      this.theme.clear();
    }
  }

  startQuestionIfRequired(): void {
    if (this.activatedRoute.children.length === 0) {
      this.log('Session host - session host was invoked without a question reference. Forcing a first question');
      this.router.navigate(['cspa', 'session', this.sessionId, 'question', 0]);
    }
  }

  loadSession(): Observable<ExerciseSession> {
    this.log(`session host - ivoking exercise sssion load with id ${this.sessionId}`);
    return this.rest.getExerciseSession(this.sessionId);
  }

  ngOnInit() {
  }
}
