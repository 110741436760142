import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { QuestionComponentBase, AnswerWrapper } from '../question-component-base';
import { JumbleAnswer, Jumble, PlaceholderAnswer } from 'src/app/model/questions';
import { Observable, of, Subscription } from 'rxjs';
import { PatternParser } from 'src/app/utils/placeholder-parser';
import { environment } from 'src/environments/environment';
import { ArrayUtils } from 'src/app/utils/arrays';


class QuestionToken {
  isPlaceholder(): boolean {
    return this instanceof PlaceholderToken;
  }
}

class StaticTextToken extends QuestionToken {
  constructor(public content: string) {
    super();
  }
}

class TokenAnswer {
  constructor(public content: string) {}
}

class PlaceholderToken extends QuestionToken {
  public answer: TokenAnswer;
  constructor(public placeholderName: string) {
    super();
  }
}

@Component({
  selector: 'app-jumble',
  templateUrl: './jumble.component.html',
  styleUrls: ['./jumble.component.scss']
})
export class JumbleComponent extends QuestionComponentBase<JumbleAnswer, Jumble> implements OnInit, OnDestroy {
  questionTokens: (PlaceholderToken | StaticTextToken)[];
  questionAnswers: TokenAnswer[];
  answer: JumbleAnswer;
  questionSubscription: Subscription;

  constructor() {
    super();
  }

  getAnswerForSubmit(): Observable<AnswerWrapper<JumbleAnswer>> {
    this.mapTokensOnAnswers();
    return of(new AnswerWrapper(this.answer));
  }

  setupAnswer(answer: JumbleAnswer) {
    if (answer) {
      this.answer = answer;
    } else {
      this.answer = new JumbleAnswer();
      this.answer.answer = [];
    }
    this.mapAnswerOnTokens();
  }

  mapAnswerOnTokens() {
    if (!this.questionTokens || !this.questionAnswers || !this.answer) {
      return;
    }

    this.questionTokens
      .filter( qt => qt instanceof PlaceholderToken)
      .forEach( qt => this.unassignAnswer(qt as PlaceholderToken));

    this.answer.answer
      .filter(ae => ae.name && ae.val && ae.val.length === 1)
      .forEach( ae => {
        const targetToken = this.questionTokens
          .find( t => (t instanceof PlaceholderToken) && t.placeholderName === ae.name ) as PlaceholderToken;
        const targetAnswer = this.questionAnswers.find( qa => qa.content === ae.val[0]);
        if (!targetToken || !targetAnswer) {
          return;
        }
        this.assignAnswer(targetAnswer, targetToken);
      });
  }

  mapTokensOnAnswers() {
    if (!this.questionTokens || !this.questionAnswers) {
      return;
    }

    this.answer.answer = [];
    this.answer.answer = this.questionTokens
      .filter( qt => qt instanceof PlaceholderToken)
      .map ( qt => qt as PlaceholderToken)
      .filter( qt => qt.answer)
      .map( qt => {
        const res = new PlaceholderAnswer();
        res.name = qt.placeholderName;
        res.val = [qt.answer.content];
        return res;
      });
  }

  // find possible target if empty, remove from assigned, clear target, assign to the target
  assignAnswer(tokenAnswer: TokenAnswer, targetQuestionToken?: PlaceholderToken) {
    if (targetQuestionToken && !targetQuestionToken.isPlaceholder()) {
      return;
    }

    const target: PlaceholderToken = targetQuestionToken ? targetQuestionToken :
      this.questionTokens
        .filter( qt => qt instanceof PlaceholderToken)
        .map (qt => qt as PlaceholderToken)
        .find( qt => !qt.answer);

    if (!target) {
      return;
    }

    const source = this.questionTokens
      .find( qt => (qt instanceof PlaceholderToken) && qt.answer === tokenAnswer);
    if (source) {
      this.unassignAnswer(source as PlaceholderToken);
    }

    if (target.answer) {
      this.unassignAnswer(target);
    }

    const answerIndex = this.questionAnswers.indexOf(tokenAnswer);
    if (answerIndex < 0) {
      return;
    }

    this.questionAnswers.splice(answerIndex, 1);
    target.answer = tokenAnswer;
  }

  unassignAnswer(target: PlaceholderToken) {
    if (!target.answer) {
      return;
    }
    this.questionAnswers.push(target.answer);
    target.answer = null;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.type === 'keydown' && event.key === 'Enter') {
      this.doFinish();
    }
  }

  ngOnInit() {
    this.questionSubscription = this.questionSubject.subscribe( _ => this.prepareQuestionTokens());
  }

  hasImage() {
    return this.question && this.question.question.definition.img;
  }

  getImageSrc() {
    return environment.awsBase + this.question.question.definition.img;
  }

  prepareQuestionTokens(): void {
    this.questionTokens = PatternParser.parse(this.question.question.definition.question)
      .map( item => item.isPlaceholder ?
          new PlaceholderToken(item.content) : new StaticTextToken(item.content)
    );
    this.questionAnswers = this.question.question.definition.answers
        .map( answer => new TokenAnswer(answer));

    ArrayUtils.shuffleArray(this.questionAnswers);

    this.mapAnswerOnTokens();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.questionSubscription) {
      this.questionSubscription.unsubscribe();
      this.questionSubscription = null;
    }
  }

}
