import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Chapter } from 'src/app/model/struct';
import { ItemAvailability } from 'src/app/model/personal';

@Component({
  selector: 'app-chapter-progress-circle',
  templateUrl: './chapter-progress-circle.component.html',
  styleUrls: ['./chapter-progress-circle.component.scss']
})
export class ChapterProgressCircleComponent implements OnInit {

  @Input()
  chapter: Chapter;
  @Input()
  availability: ItemAvailability;
  @Output()
  chapterClick = new EventEmitter<Chapter>();

  constructor() { }

  getProgress() {
    if (!this.availability) {
      return 0;
    }
    return (this.availability.progress * 100).toFixed();
  }

  onClick() {
    if (!this.chapter) {
      return;
    }
    this.chapterClick.emit(this.chapter);
  }

  getShortName() {
    if (!this.chapter) {
      return '';
    }
    const res = this.chapter.shortName;
    return res.substr(res.lastIndexOf(' ') + 1);
  }

  getColorClass() {
    return 'circle-bg-' + this.getShortName();
  }

  ngOnInit() {
  }

}
