import { Injectable } from '@angular/core';
import { CanActivate,  RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { AuthService } from './auth.service';
import { map, tap } from 'rxjs/operators';
import {ApiServiceProvider} from "./rest-provider.service";
import {CspaRestService} from "./rest/cspa-rest.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private static timeMargin = 1000 * 60 * 60;
  private api: CspaRestService;
  constructor(
    private auth: AuthService,
    apiProvider: ApiServiceProvider) {
    this.api = apiProvider.getRestServiceImplementation();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.api.isNativeImplementation()) {
      return of(true);
    }
    return this.auth.readCurrentAuthData().pipe(
      map( authData =>
        authData != null && authData.expiryDate &&
        authData.expiryDate > new Date().getTime() + AuthGuardService.timeMargin),
      tap( isAuthorised => {
        if (!isAuthorised) {
          this.auth.login(state.url);
        }
      })
    );
  }
}
