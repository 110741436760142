import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  constructor() { }

  @Output()
  public closeClick = new EventEmitter<void>();
  @Input()
  title: string;

  public onCloseClick() {
    this.closeClick.emit();
  }
  public getTitle() {
    if (this.title) {
      return this.title;
    }
    return '';
  }

  ngOnInit() {
  }

}
