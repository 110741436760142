/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chapter-progress-circle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chapter-progress-circle.component";
var styles_ChapterProgressCircleComponent = [i0.styles];
var RenderType_ChapterProgressCircleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChapterProgressCircleComponent, data: {} });
export { RenderType_ChapterProgressCircleComponent as RenderType_ChapterProgressCircleComponent };
export function View_ChapterProgressCircleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "single-chart"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, ":svg:svg", [["class", "circular-chart"], ["viewBox", "0 0 36 36"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["class", "circle-bg"], ["d", "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["class", "circle"], ["d", "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"], ["stroke-dasharray", "0,100"]], [[1, "stroke-dasharray", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:text", [["class", "percentage"], ["x", "18"], ["y", "20"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:text", [["class", "second-line"], ["x", "19"], ["y", "26"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", "%"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "circular-chart"; var currVal_1 = _co.getColorClass(); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.getProgress() + ",100"); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.getShortName(); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.getProgress(); _ck(_v, 9, 0, currVal_4); }); }
export function View_ChapterProgressCircleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chapter-progress-circle", [], null, null, null, View_ChapterProgressCircleComponent_0, RenderType_ChapterProgressCircleComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChapterProgressCircleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChapterProgressCircleComponentNgFactory = i1.ɵccf("app-chapter-progress-circle", i3.ChapterProgressCircleComponent, View_ChapterProgressCircleComponent_Host_0, { chapter: "chapter", availability: "availability" }, { chapterClick: "chapterClick" }, []);
export { ChapterProgressCircleComponentNgFactory as ChapterProgressCircleComponentNgFactory };
