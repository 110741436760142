<div *ngIf="isReady()" class="card shadow-lg h-100 ">
    <img [src]="getImageUrl()" class="card-img-top" alt="english exercises">
    <div class="card-body text-dark">
        <h5 class="card-title">{{getName()}}</h5>
    </div>
    <ul class="list-group list-group-flush text-dark">
        <li class="list-group-item"><strong [translate]="'component.exercise-set.progress'">Progress:</strong> {{getProgress()}}%</li>
    </ul>
    <div class="card-footer text-right">
        <a class="btn btn-primary" routerLink="/exercises/{{getPath()}}" [translate]="'component.exercise-set.action.go'">Go!</a>
    </div>
</div>
