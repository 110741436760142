export class StringCompare {
    public static compare(x: string, y: string) {
        const dp: number[][] = new Array<number[]>(x.length + 1);
        for (let i = 0; i <= x.length; i++) {
            dp[i] = [];
            for (let j = 0 ; j <= y.length; j++) {
                if (i === 0) {
                    dp[i][j] = j;
                } else if (j === 0) {
                    dp[i][j] = i;
                } else {
                    dp[i][j] = StringCompare.min(
                        dp[i - 1][j - 1] +
                        StringCompare.costOfSubstitution(x.charAt(i - 1), y.charAt(j - 1)),
                        dp[i - 1][j] + 1,
                        dp[i][j - 1] + 1);
                }
            }
        }
        return dp[x.length][y.length];
    }

    public static costOfSubstitution(a: string, b: string) {
        return a === b ? 0 : 1;
    }

    public static min( a: number, b: number, c: number) {
        return Math.min(a, Math.min(b, c ));
    }
}

export class CompareToken {
    stream: CompareStream;
    space = false;
    special: SpecialCharacter;
    constructor(public content: string) {}
    static fromSpecialCharacter(special: SpecialCharacter) {
        const res = new CompareToken(special.symbol);
        res.special = special;
        return res;
    }
}

export class CompareStream {
    constructor(public content: CompareToken[]) {
        content.forEach ( item => item.stream = this);
    }
}

export class CompareNode {
    constructor(public prevNode: CompareNode,
                public removed: CompareToken,
                public added: CompareToken,
                public score: number) {}
}

export class TextBlock {
    /**
     * correct, incorrect, partial, missing
     */
    public static CORRECT = 'correct';
    public static INCORRECT = 'incorrect';
    public static PARTIAL = 'partial';
    public static MISSING = 'missng';
    constructor(public kind: string, public content: string, public space: boolean) {}
}

export class BlockComparator {
    public static tokenize(text: string, applyReplacement = true, skipSpecials: SpecialCharacter[] = null): CompareStream {
        return StringTokenizer.tokenize(text, applyReplacement, skipSpecials);
    }

    /**
     * Compare how many changes are required to reach target stream from source stram.
     * Method retuns a seqence of changes which has to be done to reach the target
     * @param target correct stream - which want to achive from the srouce
     * @param source compared stream - may be user input which has to be equal to target
     */
    public static compare(target: CompareStream, source: CompareStream) {
        return TextCompare.compare(target, source);
    }

    public static divideToBlocks(compareResult: CompareNode[], source: CompareStream) {
        let prevScore = 0;
        const res: TextBlock[] = [];
        for (const node of compareResult) {
          if (node.prevNode === null) {
            continue;
          }
          if ( node.added != null && node.removed != null ) {
            const userText = node.added.stream === source ? node.added : node.removed;
            if (node.score === prevScore) {
              res.push(new TextBlock('correct', userText.content, userText.space));
            } else if (node.score - prevScore < 1) {
              res.push(new TextBlock('partial', userText.content, userText.space));
            } else {
              res.push(new TextBlock('incorrect', userText.content, userText.space));
            }
          } else if (node.added) {
            if (node.added.stream === source) {
              res.push(new TextBlock('incorrect', node.added.content, node.added.space));
            } else {
              res.push(new TextBlock('missing', '', false));
            }
          }
          prevScore = node.score;
        }
        return res;
    }

    /**
     * Compress blocks by the type. Reapeating blocks are
     * concated to prepending if are the same type
     * @param blocks input blocks
     */
    public static compressBlocks(blocks: TextBlock[]): TextBlock[] {
        const result: TextBlock[] = [];
        let currenBlock: TextBlock;
        for (const block of blocks) {
          if (!currenBlock) {
            currenBlock = block;
            continue;
          }
          if (currenBlock.kind === block.kind) {
            currenBlock.content += ( currenBlock.space ? ' ' : '' ) + block.content;
            currenBlock.space = block.space;
          } else {
            if (currenBlock.kind === 'missing') {
              currenBlock.content = '';
            }
            result.push(currenBlock);
            currenBlock = block;
          }
        }
        if (currenBlock && (result.length === 0 || result[result.length - 1] !== currenBlock)) {
          result.push(currenBlock);
        }
        return result;
    }
}

export class SpecialCharacter {
    constructor(public preSpace: boolean, public postSpace: boolean, public symbol: string) {}
}

/**
 * Split string into a tokens, which are better for any comparations.
 * 1) Split by whitespace removing unecessary spaces.
 * 2) Add single space at the end of every separated item to keep whitespaces in correct place.
 * 3) separate out special characters from every item
 */
export class StringTokenizer {
    private static textReplacements: string[][] =
    [['\"', ' ' ],
    ['„', ' '],
    ['“', ' '],
    ['‟', ' '],
    ['”', ' '],
    ['❝', ' '],
    ['❞', ' '],
    ['⹂', ' '],
    ['〝', ' '],
    ['〞', ' '],
    ['〟', ' '],
    ['`', '\''],
    ['´', '\''],
    ['ʹ', '\''],
    ['ʻ', 'ʻ'],
    ['ʽ', '\''],
    ['ʾ', '\''],
    ['ʿ', '\''],
    ['ˈ', '\''],
    ['ˊ', '\''],
    ['ʹ', '\''],
    ['΄', '\''],
    ['՚', '\''],
    ['᾽', '\''],
    ['᾿', '\''],
    ['′', '\''],
    ['Ꞌ', '\''],
    ['ꞌ', '\''],
    ['＇', '\''],
    ['’', '\'']];

    public static specialCharacters: SpecialCharacter[]  = [
        new SpecialCharacter(false, true, '!'),
        new SpecialCharacter(true, true, '&'),
        new SpecialCharacter(true, false, '('),
        new SpecialCharacter(false, true, ')'),
        new SpecialCharacter(true, true, '-'),
        new SpecialCharacter(false, true, ';'),
        new SpecialCharacter(false, true, ':'),
        new SpecialCharacter(false, false, '\''),
        new SpecialCharacter(true, false, '"'),
        new SpecialCharacter(false, true, ','),
        new SpecialCharacter(false, true, '.'),
        new SpecialCharacter(false, true, '?')];

    public static tokenize( text: string, applyReplacement = true, skipSpecials: SpecialCharacter[] = null) {
        const src = (applyReplacement) ? StringTokenizer.applyReplacements(text) : text;
        const splitted = src.split(/[\s]+/);
        const result: string[] = [];
        for (const el of splitted) {
            for (const inner of StringTokenizer.splitWithSpecialCharacters(el, skipSpecials)) {
                result.push(inner);
            }
        }

        const resultStream = new CompareStream(
            result
            .filter( token => token.length > 0)
            .map( token => this.tokenMapper(token)));

        let prevNode: CompareToken = null;
        let hasOpeningQuotation = false;
        for (const node of resultStream.content) {
            if (!prevNode) {
                prevNode = node;
                continue;
            }
            if (node.special) {
                if (node.special.symbol === '"') {
                    if (!hasOpeningQuotation) {
                        prevNode.space = true;
                        hasOpeningQuotation = true;
                    } else {
                        prevNode.space = false;
                        node.space = true;
                        hasOpeningQuotation = false;
                    }
                } else {
                    if (node.special.preSpace) {
                        prevNode.space = true;
                    }
                    if (node.special.postSpace) {
                        node.space = true;
                    }
                }
            } else {
                if (!prevNode.special) {
                    prevNode.space = true;
                }
            }
            prevNode = node;
        }
        return resultStream;
    }

    private static tokenMapper(tokenValue: string): CompareToken {
        if (tokenValue.length !== 1) {
            return new CompareToken(tokenValue);
        }
        const specialMapped: CompareToken[] = this.specialCharacters
            .filter( sc => sc.symbol === tokenValue)
            .map( sc => CompareToken.fromSpecialCharacter(sc));
        if (specialMapped.length !== 0) {
            return specialMapped[0];
        }
        return new CompareToken(tokenValue);
    }

    private static applyReplacements(text: string) {
       if (!text) {
           return text;
       }
       let res = text;
       for (const replacementPair of this.textReplacements) {
           res = StringTokenizer.applyReplacement(res, replacementPair[0], replacementPair[1]);
       }
       return res;
    }

    static applyReplacement(text: string, from: string, to: string): string {
        return text.replace(new RegExp(from.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), to);
    }

    static splitWithSpecialCharacters(el: string, skipSpecials: SpecialCharacter[] = null) {
        let res = [el];
        for (const separator of this.specialCharacters) {
            if (skipSpecials) {
                if (skipSpecials.findIndex(special => special.symbol === separator.symbol) >= 0) {
                    continue;
                }
            }
            res = this.separateArrayBy(res, separator.symbol);
        }
        return res;
    }

    static separateArrayBy(input: string[], separator: string): string[] {
        let res: string[] = [];
        for (const toSeparate of input) {
            res = res.concat(this.separateStringBy(toSeparate, separator));
        }
        return res;
    }

    static separateStringBy(toSeparate: string, separator: string): string[] {
        const splitted = toSeparate.split(separator);
        const res: string[] = [];
        for (let i = 0; i < splitted.length - 1; i++) {
            res.push(splitted[i]);
            res.push(separator);
        }
        if (splitted.length > 0) {
            res.push(splitted[splitted.length - 1]);
        }
        return res;
    }
}

export class TextCompare {

    public static tokenize(text: string, applyReplacement = true) {
        return StringTokenizer.tokenize(text, applyReplacement);
    }

    public static compare(xStream: CompareStream, yStream: CompareStream): CompareNode[] {
        const x = xStream.content;
        const y = yStream.content;

        const dp: CompareNode[][] = new Array<CompareNode[]>(x.length + 1);
        for (let i = 0; i <= x.length ; i++) {
            dp[i] = [];
            for (let j = 0; j <= y.length ; j++ ) {
                if (i === 0 && j === 0) {
                    dp[i][j] = new CompareNode(null, null, null, 0.0);
                } else if (i === 0) {
                    dp[i][j] = new CompareNode(dp[i][j - 1], null, y[j - 1], j);
                } else if (j === 0) {
                    dp[i][j] = new CompareNode(dp[i - 1][j], null, x[i - 1], i);
                } else {
                    dp[i][j] = TextCompare.calculateStepDistance(dp, i, j, x, y);
                }
            }
        }

        const result: CompareNode[] = [];
        let currentNode = dp[x.length][y.length];
        do {
            result.push(currentNode);
            currentNode = currentNode.prevNode;
        } while (currentNode != null);
        result.reverse();
        return result;
    }

    static calculateStepDistance(dp: CompareNode[][], i: number, j: number, x: CompareToken[], y: CompareToken[]): CompareNode {
        const substNode = new CompareNode(dp[i - 1][j - 1],
            x[i - 1],
            y[j - 1],
            dp[i - 1][j - 1].score + TextCompare.textSubstitutionCost( x[i - 1].content, y[ j - 1].content));

        const xAdded = new CompareNode(dp[i - 1][j],
            null,
            x[i - 1],
            dp[i - 1][j].score + 1.0);

        const yAdded = new CompareNode(dp[i][j - 1],
            null,
            y[j - 1],
            dp[i][j - 1].score + 1.0);

        const scoreOrdererd = [substNode, xAdded, yAdded].sort( (l, r) => {
            if (l.score < r.score) {
                return -1;
            } else if (l.score > r.score) {
                return 1;
            }

            if (!l.removed && !r.removed) {
                return 0;
            }
            if (!l.removed) {
                return -1;
            } else if (!r.removed) {
                return 1;
            } else {
                return 0;
            }
        });

        return scoreOrdererd[0];
    }

    static textSubstitutionCost(left: string, right: string) {
        if (left.length < 4 || right.length < 4 || left.length > 50 || right.length > 50) {
            return left === right ? 0.0 : 1.0;
        }
        const subsLength = StringCompare.compare(left, right);
        if (subsLength === 0) {
            return 0.0;
        } else if (subsLength === 1) {
            return 0.5;
        } else {
            return 1.0;
        }
    }
}

